import { useEffect, useState, useContext } from 'react';

import { useGetManagersQuery } from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { formatDate } from '~/utils/dates/formatDate';

import { ApplicationContext } from '../../contexts/ApplicationContext';

import {
  StyledAutocomplete,
  StyledFormRow,
  StyledCommentInput,
} from './Managers.styled';

export const Managers = () => {
  const { application, isLoadingApplication } = useContext(ApplicationContext);

  const { data: assignedList, isLoading: isLoadingAssigned } =
    useGetManagersQuery({ param: '' });
  const { data: сoManagersList, isLoading: isLoadingCoManager } =
    useGetManagersQuery({ param: 'co_managers_list/' });
  const { data: supportedList, isLoading: isLoadingSupported } =
    useGetManagersQuery({ param: 'supported_by_list/' });

  const [assignedValue, setAssignedValue] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [сoManagerValue, setCoManagerValue] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [supportedValue, setSupportedValue] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [activeConfirm, setActiveConfirm] = useState('');

  const assignedOptions =
    assignedList?.map(
      ({ id: assignedId, name }: { id: number; name: string }) => ({
        value: assignedId,
        label: name,
      }),
    ) || [];
  const сoManagersOptions =
    сoManagersList?.map(
      ({ id: сoManagerId, name }: { id: number; name: string }) => ({
        value: сoManagerId,
        label: name,
      }),
    ) || [];
  const supportedOptions =
    supportedList?.map(
      ({ id: supportedId, name }: { id: number; name: string }) => ({
        value: supportedId,
        label: name,
      }),
    ) || [];

  const defaultCoManagers =
    сoManagersOptions?.find(
      (item: { value: number }) => item.value === application?.co_manager,
    ) || null;

  const defaultSupported =
    supportedOptions?.find(
      (item: { value: number }) => item.value === application?.supported_by,
    ) || null;

  useEffect(() => {
    if (application?.application?.assigned_to && !assignedValue) {
      setAssignedValue({
        value: application?.application?.assigned_to,
        label: application?.application?.assigned_to__full_name,
      });
    }
    if (application?.co_manager && !сoManagerValue) {
      setCoManagerValue(defaultCoManagers);
    }
    if (application?.supported_by && !supportedValue) {
      setSupportedValue(defaultSupported);
    }
  }, [application, defaultCoManagers, defaultSupported]);

  const isAssignedConfirmOpen = assignedValue && activeConfirm === 'assigned';
  const isCoManagerConfirmOpen =
    сoManagerValue && activeConfirm === 'coManager';
  const isSupportedConfirmOpen =
    supportedValue && activeConfirm === 'supported';

  if (!application) {
    return null;
  }

  return (
    <StyledFormRow className="FormRow">
      <ActionWithConfirmation
        externalOpened={!!isAssignedConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        onConfirm={() => setActiveConfirm('')}
        title="Assigned reason"
        closeHandler={() => {
          setActiveConfirm('');
          setAssignedValue({
            value: application?.application?.assigned_to,
            label: application?.application?.assigned_to__full_name,
          });
        }}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={() => {}}
            value=""
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          disableClearable
          options={assignedOptions}
          placeholder="Assigned to"
          disabled={isLoadingAssigned || isLoadingApplication}
          value={assignedValue}
          onChange={(_, value) => {
            setAssignedValue(value);
            if (assignedValue) {
              setActiveConfirm('assigned');
            }
          }}
          controlProps={{
            message:
              application?.application?.assigned_date &&
              `Assigned date ${formatDate(
                application?.application?.assigned_date,
              )}`,
          }}
        />
      </ActionWithConfirmation>
      <ActionWithConfirmation
        externalOpened={!!isCoManagerConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        onConfirm={() => setActiveConfirm('')}
        title="Co-Manager reason"
        closeHandler={() => {
          setActiveConfirm('');

          if (сoManagerValue) {
            setCoManagerValue(defaultCoManagers);
          }
        }}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={() => {}}
            value=""
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          disableClearable
          options={сoManagersOptions}
          placeholder="Co-manager"
          disabled={isLoadingCoManager || isLoadingApplication}
          value={сoManagerValue}
          onChange={(_, value) => {
            setCoManagerValue(value);
            if (сoManagerValue) {
              setActiveConfirm('coManager');
            }
          }}
          controlProps={{
            message:
              application?.co_manager_date &&
              `Assigned date ${formatDate(application?.co_manager_date)}`,
          }}
        />
      </ActionWithConfirmation>
      <ActionWithConfirmation
        externalOpened={!!isSupportedConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        onConfirm={() => setActiveConfirm('')}
        title="Supported reason"
        closeHandler={() => {
          setActiveConfirm('');

          if (supportedValue) {
            setSupportedValue(defaultSupported);
          }
        }}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={() => {}}
            value=""
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          disableClearable
          options={supportedOptions}
          placeholder="Supported by"
          disabled={isLoadingSupported || isLoadingApplication}
          value={supportedValue}
          onChange={(_, value) => {
            setSupportedValue(value);
            if (supportedValue) {
              setActiveConfirm('supported');
            }
          }}
          controlProps={{
            message:
              application?.supported_date &&
              `Assigned date ${formatDate(application?.supported_date)}`,
          }}
        />
      </ActionWithConfirmation>
    </StyledFormRow>
  );
};
