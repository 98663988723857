import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { createContext, FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import { TDefaultFormValues } from '~/types/form';
import { checkKeyDown } from '~/utils/checkKeyDown';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import { getDefaultFormValues, getFormValidationScheme } from './helpers';

type TApplicationFormContext = {
  onSave: VoidFunction;
  formNotEdited: boolean;
};

type TApplicationFormProps = {
  application?: any;
};

const initialState: TApplicationFormContext = {
  onSave: () => {},
  formNotEdited: true,
};

export const ApplicationFormContext =
  createContext<TApplicationFormContext>(initialState);

export const ApplicationFormProvider: FC<TApplicationFormProps & TChildren> = ({
  children,
  // application,
}) => {
  const defaultValues = getDefaultFormValues();
  const validationScheme = getFormValidationScheme();

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const { handleSubmit, getValues, formState } = methods;

  const formData = getDirtyValues(formState.dirtyFields, getValues());

  const onSave = async () => {};

  const value = useMemo(
    (): TApplicationFormContext => ({
      onSave,
      formNotEdited: isEmpty(formData),
    }),
    [onSave, formData],
  );

  return (
    <ApplicationFormContext.Provider value={value}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSave)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {children}
        </form>
      </FormProvider>
    </ApplicationFormContext.Provider>
  );
};
