import { ICellValue, Checkbox } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';

export const formatCellToSomeProp = <
  Data extends Partial<Record<keyof Data, unknown>>,
>(
  field: keyof Data,
) => {
  return ({ value }: ICellValue<Data>) => {
    // if param is Array
    if (Array.isArray(value)) {
      return value?.length
        ? value
            ?.map((item) => {
              return item?.[field];
            })
            .join(', ')
        : DEFAULT_EMPTY_PLACEHOLDER;
    }
    return value?.[field] || DEFAULT_EMPTY_PLACEHOLDER;
  };
};

export const formatCellToName = <
  Data extends Partial<Record<keyof Data, unknown>>,
>() => {
  // eslint-disable-next-line react/display-name
  return ({ value }: ICellValue<Data>) => {
    // if param is Array
    if (Array.isArray(value)) {
      return value?.length
        ? value
            ?.map((item) => {
              return `${item.first_name} ${item.last_name}`;
            })
            .join(', ')
        : DEFAULT_EMPTY_PLACEHOLDER;
    }
    return value
      ? `${value.first_name} ${value.last_name}`
      : DEFAULT_EMPTY_PLACEHOLDER;
  };
};

export const formatCellToCheckbox = <
  Data extends Partial<Record<keyof Data, unknown>>,
>(
  field: keyof Data,
) => {
  // eslint-disable-next-line react/display-name
  return ({ value }: ICellValue<Data>) => {
    return <Checkbox checked={value?.[field]} disabled />;
  };
};
