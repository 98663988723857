import { FC, useEffect, useCallback } from 'react';

import {
  useGetGlobalContextQuery,
  useLazyGetTagsChoicesQuery,
  useLazyGetTagsQuery,
} from '~/api';
import { FormMultiSelectAsyncContainer } from '~/components/Form';

import { TSwiftFieldProps } from './SwiftField.types';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  options: [],
  canceled: true,
};

export const SwiftField: FC<TSwiftFieldProps> = ({ objectId, name }) => {
  const [getTags, tagsState] = useLazyGetTagsQuery();
  const [getTagsChoices] = useLazyGetTagsChoicesQuery();

  const { data: globalContext } = useGetGlobalContextQuery();

  const swiftTagId = tagsState?.data?.[0].tags.find(
    (tag) => tag.name === 'deposit swifts',
  )?.id;

  const contentType = globalContext?.content_type?.application;

  useEffect(() => {
    getTags({ objectId, nameIn: 'deposit_verification' });
  }, []);

  const fetchOptionsData = useCallback(
    (skip = 0) => {
      return async (search: string) => {
        if (swiftTagId && contentType) {
          const response = await getTagsChoices({
            query: search,
            contentType,
            objectId,
            tagId: swiftTagId,
          });

          return {
            ...defaultAutocompleteState,
            total: response?.data?.length || 0,
            options: response?.data || [],
            fetchData: fetchOptionsData(skip),
          };
        }

        return {
          ...defaultAutocompleteState,
          fetchData: fetchOptionsData(0),
        };
      };
    },
    [swiftTagId, contentType],
  );

  return (
    <FormMultiSelectAsyncContainer
      name={name}
      fetchData={fetchOptionsData()}
      size="small"
      sx={{ width: 270 }}
      disableCloseOnSelect={false}
      freeSolo
    />
  );
};
