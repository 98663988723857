// fix bug with edit field and returning previous value
export const getDirtyValues = (
  dirtyFields: Record<string, unknown>,
  values: Record<string, unknown>,
  excludedFields?: string[],
): Partial<typeof values> => {
  return Object.keys(dirtyFields)
    .filter((field) => !excludedFields?.includes(field))
    .reduce((prev, key) => {
      if (!dirtyFields[key]) {
        return prev;
      }

      return {
        ...prev,
        [key]:
          typeof dirtyFields[key] === 'object'
            ? getDirtyValues(
                dirtyFields[key] as typeof dirtyFields,
                values[key] as typeof values,
                excludedFields,
              )
            : values[key],
      };
    }, {});
};
