import { styled } from 'react-ui-kit-exante';

export const StyledEditableWithdrawalField = styled('div')`
  min-width: 270px;

  // hack for checkboxes
  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;

export const StyledEditableWithdrawalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
