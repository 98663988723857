import { Button } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledRowContainer = styled('div')`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  max-width: 156px;
  text-transform: none;
`;
