import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, IconButton } from 'react-ui-kit-exante';

import { useAddUserManagementMutation, useGetAdminMetaInfoQuery } from '~/api';
import { TAddUserManagementBody } from '~/api/userManagement/userManagementApi.types';
import { FormInputContainer } from '~/components/Form/FormInputContainer/FormInputContainer';
import { FormSelectContainer } from '~/components/Form/FormSelectContainer/FormSelectContainer';

import {
  ADD_USER_MANAGEMENT_VALUES,
  ADD_USER_MANAGEMENT_VALIDATION_SCHEMA,
} from './AddUserManagementForm.constants';
import {
  StyledAddUserManagementForm,
  StyledAddUserManagementFormPopUp,
  StyledAddUserManagementFormActions,
} from './AddUserManagementForm.styled';
import { TAddUserManagementForm } from './AddUserManagementForm.types';

export const AddUserManagementForm = ({
  typeOfRole,
  setIsLoadingAdd,
  refetchTableData,
}: TAddUserManagementForm) => {
  const [addUserManagement, stateAddUserManagement] =
    useAddUserManagementMutation();
  const { data } = useGetAdminMetaInfoQuery();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [addFormAnchorEl, setAddFormAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const isAddLoading = stateAddUserManagement.isLoading;

  useEffect(() => {
    setIsLoadingAdd(isAddLoading);
  }, [isAddLoading]);

  const brandingOptions =
    data?.brandings?.map((item) => ({ value: item, label: item })) || [];

  const defaultValues = ADD_USER_MANAGEMENT_VALUES;

  const methods = useForm<TAddUserManagementBody>({
    defaultValues,
    resolver: yupResolver(ADD_USER_MANAGEMENT_VALIDATION_SCHEMA),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setError,
    formState: { isSubmitting },
  } = methods;

  const handleAddFormOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAddFormAnchorEl(event.currentTarget);
    setIsAddFormOpen(true);
  };

  const handleAddFormClose = () => {
    reset();
    setAddFormAnchorEl(null);
    setIsAddFormOpen(false);
  };

  const saveFormData = async () => {
    const formData = getValues();

    try {
      await addUserManagement({ ...formData, role: typeOfRole }).unwrap();

      refetchTableData();
      handleAddFormClose();
    } catch (error: any) {
      const errorObj = JSON.parse(error);

      if (errorObj) {
        Object.entries(errorObj).forEach(([key, messages]) => {
          const formKey = key as keyof TAddUserManagementBody;

          const messageArray = messages as string[];

          setError(
            formKey,
            {
              type: 'customError',
              message: messageArray.join(', '),
            },
            { shouldFocus: true },
          );
        });
      }
    }
  };

  const addForm = (
    <FormProvider {...methods}>
      <StyledAddUserManagementForm className="AddUserManagementForm">
        <FormInputContainer fullWidth name="email" label="Email" />
        <FormInputContainer fullWidth name="first_name" label="First name" />
        <FormInputContainer fullWidth name="last_name" label="Last name" />
        <FormSelectContainer
          fullWidth
          name="branding"
          label="Branding"
          options={brandingOptions}
        />
        <StyledAddUserManagementFormActions className="AddUserManagementFormActions">
          <Button
            color="transparent"
            textColor="action"
            disabled={isSubmitting}
            onClick={handleSubmit(saveFormData)}
          >
            Add
          </Button>
          <Button
            color="transparent"
            textColor="secondary"
            onClick={handleAddFormClose}
          >
            Cancel
          </Button>
        </StyledAddUserManagementFormActions>
      </StyledAddUserManagementForm>
    </FormProvider>
  );

  return (
    <>
      <IconButton
        label="Add"
        iconSize={24}
        title="Add"
        iconName="AddIcon"
        iconColor="action"
        className="AddUserManagement"
        onClick={handleAddFormOpen}
        data-test-id="user-management-table__button--add"
      />
      <StyledAddUserManagementFormPopUp
        open={isAddFormOpen}
        anchorEl={addFormAnchorEl}
        onClose={handleAddFormClose}
        className="AddUserManagementFormPopUp"
      >
        {addForm}
      </StyledAddUserManagementFormPopUp>
    </>
  );
};
