import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TBookmarkAPI } from '../../bookmarks/bookmarks.types';
import { NODE_BACK_URL } from '../endpoints';

import {
  TBookmarkBOAPI,
  TBookmarkLinkBOAPI,
  TMappedBookmarkLink,
  TCreateBookmarkBOPayload,
  TUpdateBookmarkBOPayload,
} from './bookmarksBO.types';

export const bookmarksBOApi = createApi({
  reducerPath: 'bookmarksBOApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['BookmarksBOMenu', 'BookmarksBO', 'NewBookmarksBO'],
  endpoints: (builder) => ({
    getBookmarksForMenu: builder.query<TMappedBookmarkLink[], void>({
      query: () => ({
        url: `${NODE_BACK_URL}/api/bookmarks?isMenuItems=true`, // isMenuItems is required param for that response type
      }),
      transformResponse(response: TBookmarkLinkBOAPI[]) {
        return response.map((item) => ({
          ...item,
          innerId: Number(item.id.replace(/\D/g, '')),
        }));
      },
      providesTags: ['BookmarksBOMenu'],
    }),

    getBookmarks: builder.query<TBookmarkAPI[], void>({
      query: () => ({
        url: `${NODE_BACK_URL}/api/bookmarks`, // isMenuItems is required param for that response type
      }),
      transformResponse(response: TBookmarkBOAPI[]) {
        return response.map((item) => ({
          filters: item.filters,
          columns: item.columns.map((col) => ({
            id: col,
            title: col,
            checked: true,
          })),
          text: item.name,
          name: item.name,
          id: `bookmark=${item.id}`,
          innerId: item.id,
          linkTo: '',
          bookmarkTab: item.bookmarkTab,
        }));
      },
      providesTags: ['BookmarksBO'],
    }),

    createBookmark: builder.mutation<
      TBookmarkAPI,
      { data: TCreateBookmarkBOPayload }
    >({
      query: ({ data }) => ({
        url: `${NODE_BACK_URL}/api/bookmarks`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully created',
          });
        } catch (e) {
          console.error('e', e);
        }
      },
      invalidatesTags: ['BookmarksBOMenu'],
    }),

    updateBookmark: builder.mutation<
      TBookmarkAPI,
      { id: number; data: TUpdateBookmarkBOPayload }
    >({
      query: ({ id, data }) => ({
        url: `${NODE_BACK_URL}/api/bookmarks/${id}`,
        method: 'PATCH',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully updated',
          });
        } catch (e) {
          console.error('e', e);
        }
      },
      invalidatesTags: ['BookmarksBOMenu'],
    }),

    deleteBookmark: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `${NODE_BACK_URL}/api/bookmarks/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully deleted',
          });
        } catch (e) {
          console.error('e', e);
        }
      },
      invalidatesTags: ['BookmarksBOMenu'],
    }),
  }),
});

export const {
  useGetBookmarksForMenuQuery: useGetBookmarksForMenuBOQuery,
  useDeleteBookmarkMutation: useDeleteBookmarkBOMutation,
  useLazyGetBookmarksQuery: useLazyGetBookmarksBOQuery,
  useCreateBookmarkMutation: useCreateBookmarkBOMutation,
  useUpdateBookmarkMutation: useUpdateBookmarkBOMutation,
} = bookmarksBOApi;
