import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TGlobalContextResponse } from './globalContext.types';

// todo this is temporary api, delete this usage after separate logic on backend https://jira.exante.eu/browse/CRM-3237
export const globalContextApi = createApi({
  reducerPath: 'globalContextApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['GlobalContext'],
  endpoints: (builder) => ({
    getGlobalContext: builder.query<TGlobalContextResponse, void>({
      query: () => ({
        url: '/rest/global-context/',
      }),
      providesTags: ['GlobalContext'],
    }),
  }),
});

export const { useGetGlobalContextQuery } = globalContextApi;
