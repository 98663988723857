export const MAX_FILE_SIZE_MB = 10;
export const ACCEPT_FILE_FORMATS = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.csv',
  '.png',
  '.jpeg',
  '.jpg',
  '.tif',
  '.tiff',
  '.bmp',
  '.gif',
];

export const ACCEPT_FILE_LIST = ACCEPT_FILE_FORMATS.join(', ');
