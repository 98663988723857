import { FC, useEffect, useState } from 'react';
import {
  FileUpload,
  TFile,
  TSetFileSuccessFn,
  UploadStatus,
} from 'react-ui-kit-exante';

import {
  useDeleteDocumentMutation,
  useGetPermissionsQuery,
  useUpdateDepositInfoMutation,
  useUploadDocumentsMutation,
  useLazyGetDepositDownloadUploadedFileQuery,
} from '~/api';
import { ACCEPT_FILE_LIST } from '~/constants/files';

import { TDocumentsProps } from './Documents.types';

export const Documents: FC<TDocumentsProps> = ({
  deposit,
  isLoading: isLoadingFiles,
}) => {
  const [onUploadDocuments, stateUpload] = useUploadDocumentsMutation();
  const [onDeleteDocument, stateDelete] = useDeleteDocumentMutation();
  const [, stateDeposit] = useUpdateDepositInfoMutation();
  const { data: permissions } = useGetPermissionsQuery();
  const [downloadUploadedFile] = useLazyGetDepositDownloadUploadedFileQuery();

  const [isUpdateUploader, setIsUpdateUploader] = useState(false);

  const isUploadLoading = stateUpload.isLoading;
  const isDeleteLoading = stateDelete.isLoading;
  const isShowLoading =
    (isLoadingFiles || stateDeposit.isLoading) && isUpdateUploader;

  const filesInitial: TFile[] = deposit.uploads.map((file) => ({
    ...file,
    id: String(file.id),
    removable: true,
    size: 0,
    uploadStatus: UploadStatus.SUCCESS,
  }));

  useEffect(() => {
    if (isUploadLoading || isDeleteLoading) {
      setIsUpdateUploader(true);
    }
  }, [isUploadLoading, isDeleteLoading]);

  useEffect(() => {
    if (!isLoadingFiles) {
      setIsUpdateUploader(false);
    }
  }, [isLoadingFiles]);

  const handleFileAppend = async (
    payload: File[],
    onSuccess: TSetFileSuccessFn,
  ) => {
    payload.map(async (file) => {
      const uploaded = await onUploadDocuments({ id: deposit.id, file });
      if (!('error' in uploaded)) {
        const { name, size } = file;

        onSuccess({ name, id: String(size), removable: true });

        return uploaded.data.id;
      }
      return null;
    });
  };

  const handleDelete = async (id: string) => {
    await onDeleteDocument({ depositId: deposit.id, fileId: id });
  };

  const getFile = async (id: string) => {
    const { data: dataUrl } = await downloadUploadedFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  const hasEditPerms = Boolean(permissions?.deposit_info?.change_depositinfo);

  return (
    <>
      {isShowLoading && <FileUpload loading />}
      {!isShowLoading && (
        <FileUpload
          accept={ACCEPT_FILE_LIST}
          onUpload={handleFileAppend}
          filesInitial={filesInitial}
          showFileSize={false}
          onDelete={handleDelete}
          withDeleteConfirmation
          disabled={!hasEditPerms}
          onDownload={getFile}
        />
      )}
    </>
  );
};
