import { FC, useCallback } from 'react';

import { useLazySearchTagsQuery } from '~/api';
import { FormMultiSelectAsyncContainer } from '~/components/Form';

import { TTagsSelectProps } from './TagsSelect.types';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  options: [],
  canceled: true,
};

export const TagsSelect: FC<TTagsSelectProps> = ({ name, disabled }) => {
  const [searchByTags] = useLazySearchTagsQuery();

  const fetchOptionsData = useCallback((skip = 0) => {
    return async (query: string) => {
      const response = await searchByTags(query);

      return {
        ...defaultAutocompleteState,
        total: response?.data?.length || 0,
        options: response?.data || [],
        fetchData: fetchOptionsData(skip),
      };
    };
  }, []);

  return (
    <FormMultiSelectAsyncContainer
      label="Tag name"
      size="medium"
      name={name}
      fetchData={fetchOptionsData()}
      noOptionsText="Search tag"
      clearable
      disableCloseOnSelect={false}
      disabled={disabled}
    />
  );
};
