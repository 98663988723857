import { ReportsContainer } from './ReportsContainer';
import { PendingReportsProvider } from './contexts/PendingReportsContext';

export const Reports = () => {
  return (
    <PendingReportsProvider>
      <ReportsContainer />
    </PendingReportsProvider>
  );
};
