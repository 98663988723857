import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { colorsMap } from '~/constants/statusesInfoMap';

import { TStyledStatus } from './StatusInfo.types';

export const StyledStatus = styled('div', {
  shouldForwardProp: blockNonNativeProps(['status', 'entityKey']),
})<TStyledStatus>`
  margin-right: 8px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ status, entityKey, theme }) =>
    status
      ? colorsMap(theme)?.[entityKey]?.[status]
      : theme?.color?.typo?.primary};
`;

export const StyledResponsible = styled('div')`
  margin-right: 8px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme?.color?.typo?.primary};
`;

export const StyledWrapperStatus = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const StyledHead = styled('div')`
  font-size: 13px;
  // todo move colors to theme
  color: #49575b;
`;

export const StyledWrapper = styled('div')`
  display: flex;
`;
