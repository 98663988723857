import { IconButton, styled } from 'react-ui-kit-exante';

import { EntryPage } from '~/components/EntryScreen';

export const StyledEntryPage = styled(EntryPage)`
  width: 100%;
`;

export const StyledSaveIcon = styled(IconButton)`
  margin-bottom: 24px;
`;

export const StyledOnboardingSettingsWrapper = styled('div')`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;
