import { FC } from 'react';

import { StyledToCell } from './ToCell.styled';
import { TToCellProps } from './ToCell.types';

export const ToCell: FC<TToCellProps> = ({ to, isNew }) => {
  return (
    <StyledToCell className="ToCell" isNew={isNew}>
      {to}
    </StyledToCell>
  );
};
