import { TKey } from './types';

export function isKey(value: unknown): value is TKey {
  return ['string', 'number', 'symbol'].includes(typeof value);
}

export function createHashMapFromArray<Data>(array: Data[], field: keyof Data) {
  return array.reduce((acc, curr) => {
    const key = curr[field];

    if (isKey(key)) {
      return { ...acc, [key]: curr };
    }

    return acc;
  }, {} as Record<TKey, Data>);
}
