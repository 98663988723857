import { Popover } from '@mui/material';
import { styled } from 'react-ui-kit-exante';

export const StyledActionListWrapper = styled('div')`
  display: flex;
`;

export const StyledActionListPopover = styled(Popover)`
  ${({ theme }) => `
    .MuiPopover-paper {
      color: ${theme?.color?.typo?.primary};
      background-color: ${theme?.color?.modal.bg};
      box-shadow: ${theme?.effect?.controls?.drop};
    }
  `}

  .IconButton {
    display: flex;
    padding: 8px 12px;
    width: 100%;
    justify-content: start;
  }
`;
