import { ISelectOption } from 'react-ui-kit-exante';

import { TSearchTagResponse } from '~/api/contacts/contacts.types';

import { TImportTagForm } from './ImportTag.types';

export const convertTagsToSelectOptions = (
  inputArray: TSearchTagResponse,
): ISelectOption[] => {
  return inputArray.map(({ name, title }) => ({
    label: title,
    value: name,
  }));
};

export const updateFileToLastItem = (obj: TImportTagForm) => ({
  ...obj,
  file: obj.file[obj.file.length - 1],
});
