import cn from 'classnames';
import { FC } from 'react';

import { TChildren } from '~/types/TChildren';

import { StyledEntryScreenWrapper } from './EntryScreenWrapper.styled';
import { TEntryScreenWrapperProps } from './EntryScreenWrapper.types';

export const EntryScreenWrapper: FC<TEntryScreenWrapperProps & TChildren> = ({
  children,
  className,
}) => {
  return (
    <StyledEntryScreenWrapper className={cn(['EntryScreenWrapper', className])}>
      {children}
    </StyledEntryScreenWrapper>
  );
};
