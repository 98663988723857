import { ISortBy } from 'react-ui-kit-exante';

import { FROM_TO_DATE_FIELDS } from '~/constants/fields';
import { formatDateRange } from '~/utils/dates/formatDateRange';
import { prepareDateRangeStringForAPI } from '~/utils/dates/prepareDateRangeStringForAPI';
import { prepareSortingForApi } from '~/utils/table/prepareSortingForApi';

export const TRADES_PARAMS_MAPPER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  fromTo: {
    parser: (values: (string | null)[]) => {
      const formattedDateRange = formatDateRange(values);
      return prepareDateRangeStringForAPI(
        FROM_TO_DATE_FIELDS,
        formattedDateRange,
      );
    },
  },
};
