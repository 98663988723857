import { styled } from 'react-ui-kit-exante';

export const StyledValidationRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledValidationRowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledValidationName = styled('div')`
  font-size: 13px;
`;

export const StyledValidationLegalEntity = styled('div')`
  font-size: 16px;
  margin: 24px 0;
`;

export const StyledValidationSubTitle = styled('h2')`
  font-size: 18px;
  font-weight: 400;
`;

export const StyledValidationPasswordSubTitle = styled(
  StyledValidationSubTitle,
)`
  margin-bottom: 24px;
`;

export const StyledValidationCategorizationSubTitle = styled(
  StyledValidationSubTitle,
)`
  margin: 24px 0;
`;

export const StyledValidationBlock = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
`;

export const StyledValidationGhost = styled('div')`
  font-size: 13px;
  color: ${({ theme }) => theme?.color?.typo?.ghost};
`;

export const StyledValidationAccordionOptions = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
