import { createApi } from '@reduxjs/toolkit/query/react';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { IOption } from '~/types/form/options';
import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TMonitoringTags,
  TTagsChoicesParams,
  TTagsChoicesResponse,
  TTagsParams,
  TTagsResponse,
} from './tags.types';

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Tags', 'TagsChoices'],
  endpoints: (builder) => ({
    getTags: builder.query<TTagsResponse, TTagsParams>({
      query: ({ objectId, nameIn, le }) => ({
        url: '/rest/tag-group/app_related_tagvalues/',
        params: {
          object_id: objectId,
          name__in: nameIn,
          le: le || ALL_LEGAL_ENTITY,
        },
      }),
      providesTags: ['Tags'],
    }),
    getTagsChoices: builder.query<IOption[], TTagsChoicesParams>({
      query: ({ tagId, objectId, contentType, query = '' }) => ({
        url: `/rest/tag-choices/${tagId}/`,
        params: {
          object_id: objectId,
          content_type: contentType,
          query,
        },
      }),
      transformResponse(response: TTagsChoicesResponse) {
        return response.choices?.map((item) => ({
          label: item.text,
          value: item.id,
        }));
      },
      providesTags: ['TagsChoices'],
    }),
    getMonitoringTags: builder.query<TMonitoringTags[], void>({
      query: () => ({
        url: '/rest/tag-group/monitoring_related_tagvalues/',
      }),
    }),
  }),
});

export const {
  useLazyGetTagsQuery,
  useLazyGetTagsChoicesQuery,
  useLazyGetMonitoringTagsQuery,
} = tagsApi;
