import { TColumnFromLs } from '~/types/table';

export const getTableColumnsFromLs = (tableId: string): TColumnFromLs[] => {
  const lsKeyColumns = `${tableId}-columns`;
  const paramsColumns = localStorage.getItem(lsKeyColumns) || '';

  try {
    if (paramsColumns) {
      const paramsColumnsParsed = JSON.parse(paramsColumns);

      return (
        paramsColumnsParsed?.map((i: string) => {
          return {
            checked: true,
            id: i,
            name: i,
          };
        }) || []
      );
    }

    const lsKey = `${tableId}-view-params`;
    const params = localStorage.getItem(lsKey) || '';

    const paramsParsed = JSON.parse(params);

    return (
      paramsParsed.defaultColumnOrder.map((i: string) => {
        return {
          checked: true,
          id: i,
          name: i,
        };
      }) || []
    );
  } catch (error) {
    console.error(error);

    return [];
  }
};
