import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TDepositInfo } from '~/api/deposits/deposits.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { formatDateTime } from '~/utils/dates/formatDate';
import {
  formatCellToCheckbox,
  formatCellToSomeProp,
} from '~/utils/table/cellFormatters';

import { StatusCell } from './components/StatusCell';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'id',
  'datetime',
  'username',
  'client_id',
  'responsible_title',
  'status',
  'amount',
  'ccy',
  'legal_entity',
];

export const getColumns = (): IColumn<TDepositInfo>[] =>
  [
    {
      id: 'username',
      Header: 'User',
      minWidth: 410,
    },
    {
      id: 'datetime',
      Header: 'Date',
      minWidth: 200,
      Cell: ({ value }: ICellValue<TDepositInfo>) =>
        value ? formatDateTime(value) : DEFAULT_EMPTY_PLACEHOLDER,
    },
    {
      id: 'name',
      Header: 'Senders name',
      minWidth: 300,
    },
    {
      id: 'swift',
      Header: 'SWIFT',
    },
    {
      id: 'iban',
      Header: 'Senders IBAN',
      minWidth: 300,
    },
    {
      id: 'ac_no',
      Header: 'Senders Account number',
      minWidth: 300,
    },
    {
      id: 'country_a',
      Header: 'Country (A)',
      Cell: formatCellToSomeProp('text'),
    },
    {
      id: 'amount',
      Header: 'Amount',
    },
    {
      id: 'ccy',
      Header: 'Currency',
      Cell: formatCellToSomeProp('text'),
    },
    {
      id: 'eur_amount_decimal',
      Header: 'Euro Equivalent',
      minWidth: 160,
      disableSortBy: true,
    },
    {
      id: 'legal_entity',
      Header: 'Legal Entity',
    },
    {
      id: 'bank_name',
      Header: 'Client custody',
      minWidth: 300,
    },
    {
      id: 'detail',
      Header: 'Details (clients notes)',
      minWidth: 300,
    },
    {
      id: 'bank_ref_no',
      Header: 'Bank ref No (B)',
    },
    {
      id: 'account_id',
      Header: 'Account ID',
    },
    {
      id: 'custody',
      Header: 'Custody',
    },
    {
      id: 'custody_account',
      Header: 'Custody account',
      minWidth: 300,
    },
    {
      id: 'client_custody_account',
      Header: 'Client custody account',
      minWidth: 300,
    },
    {
      id: 'bank_deposit_id',
      Header: 'Bank Deposit ID',
      minWidth: 300,
    },
    {
      id: 'client_name',
      Header: 'Client name',
      minWidth: 200,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: StatusCell,
      minWidth: 160,
    },
    {
      id: 'deposit_type',
      Header: 'Deposit type',
      Cell: formatCellToSomeProp('text'),
    },
    {
      id: 'responsible_title',
      Header: 'Responsible',
      Cell: ({ value }: ICellValue<TDepositInfo>) =>
        value?.length ? value : DEFAULT_EMPTY_PLACEHOLDER,
    },
    {
      id: 'confirms_required',
      Header: 'Required checks',
      Cell: formatCellToSomeProp('text'),
      minWidth: 200,
    },
    {
      id: 'bank_details_confirm',
      Header: 'Bank details mismatch',
      Cell: formatCellToCheckbox('value'),
      minWidth: 200,
    },
    {
      id: 'third_party_confirm',
      Header: 'Third party check',
      Cell: formatCellToCheckbox('value'),
      minWidth: 200,
    },
    {
      id: 'amount_exceeding_onboarding_confirm',
      Header: 'Amount exceeding threshold (Onboarding)',
      Cell: formatCellToCheckbox('value'),
      minWidth: 200,
    },
    {
      id: 'amount_exceeding_risk_confirm',
      Header: 'Amount exceeding threshold (Trade Desk)',
      Cell: formatCellToCheckbox('value'),
      minWidth: 200,
    },
    {
      id: 'first_deposit_confirm',
      Header: 'First deposit check',
      Cell: formatCellToCheckbox('value'),
      minWidth: 200,
    },
    {
      id: 'bank_details_confirm_changed',
      Header: 'Bank details mismatch (checked at)',
      formatting: 'dateUTC',
      minWidth: 200,
    },
    {
      id: 'third_party_confirm_changed',
      Header: 'Third party check (checked at)',
      formatting: 'dateUTC',
      minWidth: 200,
    },
    {
      id: 'amount_exceeding_onboarding_confirm_changed',
      Header: 'Amount exceeding threshold (Onboarding) (checked at)',
      formatting: 'dateUTC',
      minWidth: 200,
    },
    {
      id: 'amount_exceeding_risk_confirm_changed',
      Header: 'Amount exceeding threshold (Trade Desk) (checked at)',
      formatting: 'dateUTC',
      minWidth: 200,
    },
    {
      id: 'first_deposit_confirm_changed',
      Header: 'First deposit check (checked at)',
      formatting: 'dateUTC',
      minWidth: 200,
    },
    {
      id: 'comply_radar_id',
      Header: 'ComplyRadar TransactionID',
      minWidth: 300,
    },
    {
      id: 'comply_radar_customer_id',
      Header: 'ComplyRadar CustomerID',
      minWidth: 300,
      disableSortBy: true,
    },
    {
      id: 'deposit_group',
      Header: 'Deposit group',
    },
    {
      id: 'id',
      Header: 'Id',
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));

export const DEPOSIT_DATE_FIELDS = ['field_datetime'];

export const defaultSortBy = [{ id: 'datetime', desc: true }];
