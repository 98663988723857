import { FC } from 'react';

import { TChildren } from '~/types/TChildren';

import {
  StyledEntrySubSectionContainer,
  StyledSubSectionContent,
  StyledSubSectionTitle,
} from './EntrySubSection.styled';
import { TEntrySectionProps } from './EntrySubSection.types';

export const EntrySubSection: FC<TEntrySectionProps & TChildren> = ({
  title = '',
  children,
  gap = '8px',
}) => {
  return (
    <StyledEntrySubSectionContainer className="EntrySubSectionContainer">
      {title && (
        <StyledSubSectionTitle className="SubSectionTitle">
          {title}
        </StyledSubSectionTitle>
      )}
      <StyledSubSectionContent className="SubSectionContent" gap={gap}>
        {children}
      </StyledSubSectionContent>
    </StyledEntrySubSectionContainer>
  );
};
