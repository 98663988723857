import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  GlobalStyles,
  Notification,
  NotificationProvider,
} from 'react-ui-kit-exante';

import { createStore } from 'store';
import { ComposedThemeProvider } from 'theme/ComposedThemeProvider';

import { ApplicationContainer } from './containers/ApplicationContainer';
import { APP_INSTANCE_WINDOW_CONFIG_NAME } from './utils/getCrmInstanceName';

export const rtkQueryErrorHandler: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    Notification.error(
      'Error',
      typeof action.payload === 'string'
        ? action.payload
        : action.payload.error || 'Unexpected error',
    );
  }

  return next(action);
};

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);
  const store = createStore([rtkQueryErrorHandler]);

  window[APP_INSTANCE_WINDOW_CONFIG_NAME] = {
    ...(window[APP_INSTANCE_WINDOW_CONFIG_NAME] || {}),
    root,
  };

  root.render(
    <Provider store={store}>
      <ComposedThemeProvider>
        <GlobalStyles />
        <NotificationProvider />
        <ApplicationContainer />
      </ComposedThemeProvider>
    </Provider>,
  );
};
