export const formatNumber = (value: string, max_digits = 20) => {
  function isNumeric(n: string) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));
  }

  if (value && isNumeric(value)) {
    const numberFormat = new Intl.NumberFormat('en-GB', {
      style: 'decimal',
      maximumFractionDigits: max_digits,
      minimumFractionDigits: 0,
    });
    return numberFormat.format(Number(value));
  }

  return value;
};
