import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Select } from 'react-ui-kit-exante';

import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { getConfirmMessages } from '~/pages/WithdrawalEntry/components/WithdrawalForm/WithdrawalForm.helpers';
import { PATHS } from '~/router';
import { TLocationState } from '~/types/location';
import { paramsSerializer } from '~/utils/apiRequest/helpers';

import { WITHDRAWAL_TYPE_SECURITY } from '../../WithdrawalEntry.constants';

import { WithdrawalTypesOfActions } from './WithdrawalHeader.constants';
import { TWithdrawalHeader } from './WithdrawalHeader.types';
import { useWithdrawalActions } from './hooks/useWithdrawalActions';

export const WithdrawalHeader = ({ withdrawal }: TWithdrawalHeader) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const { status, username, is_locked: isLocked } = withdrawal;
  const {
    reason,
    isLoading,
    reasonOptions,
    onChangeSelect,
    isReasonsLoading,
    onHoldMethods,
    rejectMethods,
    withdrawMethods,
    makeActiveMethods,
    sendFundsToClientMethods,
    exportToXMLBtnMethods,
    convertMethods,
    activeConfirm,
    rollbackMethods,
  } = useWithdrawalActions(withdrawal?.type || '');

  const confirmMessages = getConfirmMessages(
    withdrawal?.type || '',
    withdrawal?.currency || '',
  );

  const renderSelect = (
    <Select
      sx={{ width: 300 }}
      options={reasonOptions}
      onChange={onChangeSelect}
      value={reason}
      placeholder="Pick a reason"
      label="Pick a reason"
      disabled={isReasonsLoading}
    />
  );

  const ohHoldBtn = (
    <ActionWithConfirmationWithSelect
      key="ohHoldBtn"
      open={activeConfirm === WithdrawalTypesOfActions.Hold}
      anchorEl={onHoldMethods.holdBtnAnchorEl}
      onClose={onHoldMethods.handleHoldClose}
      title="Add comment"
      content={renderSelect}
      confirmText="Hold"
      handleConfirm={onHoldMethods.handleOnHold}
      handleClose={onHoldMethods.handleHoldClose}
      disabledConfirm={!reason}
    >
      <IconButton
        iconName="SuspendIcon"
        iconColor="warning"
        label="On Hold"
        iconSize={32}
        disabled={isLoading}
        onClick={onHoldMethods.openHoldConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const onRejectBtn = (
    <ActionWithConfirmationWithSelect
      key="onRejectBtn"
      open={activeConfirm === WithdrawalTypesOfActions.Reject}
      anchorEl={rejectMethods.rejectBtnAnchorEl}
      onClose={rejectMethods.handleRejectClose}
      title="Add comment"
      content={renderSelect}
      confirmText="Reject"
      handleConfirm={rejectMethods.handleReject}
      handleClose={rejectMethods.handleRejectClose}
      disabledConfirm={!reason}
    >
      <IconButton
        iconName="CircleCloseIcon"
        iconColor="radical"
        label="Reject"
        iconSize={32}
        disabled={isLoading}
        onClick={rejectMethods.openRejectConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const onWithdrawBtn = (
    <ActionWithConfirmationWithSelect
      key="onWithdrawBtn"
      open={activeConfirm === WithdrawalTypesOfActions.Withdraw}
      anchorEl={withdrawMethods.withdrawFromAccountBtnAnchorEl}
      onClose={withdrawMethods.handleWithdrawFromAccountClose}
      confirmText="Confirm Withdrawal"
      description={confirmMessages.sendFunds}
      handleConfirm={withdrawMethods.handleWithdraw}
      handleClose={withdrawMethods.handleWithdrawFromAccountClose}
    >
      <IconButton
        iconName="WithdrawFromAccountIcon"
        iconColor="action"
        label="Withdraw From Account"
        iconSize={32}
        disabled={isLoading}
        onClick={withdrawMethods.openWithdrawFromAccountConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const onMakeActiveBtn = (
    <IconButton
      key="onMakeActiveBtn"
      iconName="ReadyForActivationIcon"
      iconColor="action"
      label="Make Active"
      iconSize={32}
      disabled={isLoading}
      onClick={makeActiveMethods.handleMakeActive}
    />
  );

  const onSendFundsToClientBtn = (
    <ActionWithConfirmationWithSelect
      key="onSendFundsToClientBtn"
      open={activeConfirm === WithdrawalTypesOfActions.WithdrawWire}
      anchorEl={sendFundsToClientMethods.sendFundsToClientBtnAnchorEl}
      onClose={sendFundsToClientMethods.handleSendFundsToClientClose}
      confirmText="Send funds to client"
      description={confirmMessages.sendFunds}
      handleConfirm={sendFundsToClientMethods.handleSendFundsToClient}
      handleClose={sendFundsToClientMethods.handleSendFundsToClientClose}
    >
      <IconButton
        iconName="CircleSendIcon"
        iconColor="action"
        label="Send funds to client"
        iconSize={32}
        disabled={isLoading}
        onClick={sendFundsToClientMethods.openSendFundsToClientConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const onExportToXMLBtn = (
    <IconButton
      key="onExportToXMLBtn"
      iconName="ExportIcon"
      iconColor="action"
      label="Export To XML"
      iconSize={32}
      disabled={isLoading}
      onClick={exportToXMLBtnMethods.handleExportToXML}
    />
  );

  const onConvertBtn = (
    <ActionWithConfirmationWithSelect
      key="onConvertBtn"
      open={activeConfirm === WithdrawalTypesOfActions.Convert}
      anchorEl={convertMethods.convertBtnAnchorEl}
      onClose={convertMethods.handleConvertClose}
      confirmText="Convert"
      description={confirmMessages.convert}
      handleConfirm={convertMethods.handleConvert}
      handleClose={convertMethods.handleConvertClose}
    >
      <IconButton
        iconName="ArrowsClockwiseIcon"
        iconColor="action"
        label="Convert"
        iconSize={32}
        disabled={isLoading}
        onClick={convertMethods.openConvertConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const onRollbackBtn = (
    <ActionWithConfirmationWithSelect
      key="onRollbackBtn"
      open={activeConfirm === WithdrawalTypesOfActions.Rollback}
      anchorEl={rollbackMethods.rollbackBtnAnchorEl}
      onClose={rollbackMethods.handleRollbackClose}
      confirmText="Rollback Withdrawal Confirm"
      description={confirmMessages.rollback}
      handleConfirm={rollbackMethods.handleRollback}
      handleClose={rollbackMethods.handleRollbackClose}
    >
      <IconButton
        iconName="RollbackIcon"
        iconColor="radical"
        label="Rollback Withdrawal Confirm"
        iconSize={32}
        disabled={isLoading}
        onClick={rollbackMethods.openRollbackConfirm}
      />
    </ActionWithConfirmationWithSelect>
  );

  const showActions = withdrawal?.actions;

  const actions = [
    showActions?.withdraw ? onWithdrawBtn : null,
    showActions?.on_hold ? ohHoldBtn : null,
    showActions?.active ? onMakeActiveBtn : null,
    showActions?.reject ? onRejectBtn : null,
    showActions?.send_funds ? onSendFundsToClientBtn : null,
    showActions?.export_to_xml ? onExportToXMLBtn : null,
    showActions?.convert ? onConvertBtn : null,
    showActions?.rollback ? onRollbackBtn : null,
  ].filter(Boolean);

  const onCloseEntry = () => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${PATHS.WITHDRAWALS}?${paramsSerializer(rest)}`);
  };

  const lockedText =
    withdrawal.type === WITHDRAWAL_TYPE_SECURITY
      ? 'Positions are locked'
      : 'Funds are locked';

  return (
    <EntryScreenHeader
      status={status}
      title={username}
      actions={actions}
      isLocked={isLocked}
      entityKey="withdrawal"
      onClose={onCloseEntry}
      lockedText={lockedText}
    />
  );
};
