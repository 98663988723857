import { IOnFetchArguments } from 'react-ui-kit-exante';

export const calculateCountOfPages = (total: number, limit: number) => {
  return Math.ceil(total / limit);
};

export const getPaginationParams = (
  params: IOnFetchArguments,
  withPage?: boolean,
) => {
  return {
    iDisplayLength: params?.paginationParams?.limit,
    iDisplayStart: params?.paginationParams?.skip,
    ...(withPage ? { iDisplayPage: params?.paginationParams?.page } : {}),
  };
};

export const getDefaultPagination = () => ({
  limit: 20,
  skip: 0,
});
