import { styled } from 'react-ui-kit-exante';

export const StyledHeader = styled('header')`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 0 16px;
  height: 56px;
`;
