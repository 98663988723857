import { TDepositInfo, TDepositType } from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { OLD_CRM_ROOT_PATH } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';

import {
  StyledButtonClientCard,
  StyledManagerLink,
  StyledManagerRow,
} from './DepositForm.styled';
import { TFieldConfig } from './DepositForm.types';

export const DEPOSIT_TYPES: Record<TDepositType, string> = {
  wire: 'Wire',
  bank_card: 'Bank card',
  crypto: 'Crypto',
};

export const getComplyRadarFields = (deposit: TDepositInfo) =>
  [
    {
      key: 'comply_radar_id',
      label: 'Transaction ID',
    },
    {
      key: 'comply_radar_customer_id',
      label: 'Customer ID',
    },
    {
      key: 'comply_radar_account_id',
      label: 'Account ID',
    },
  ].filter((item) => !deposit.hidden_edit_fields_keys.includes(item.key));

export const getClientDetailsFields = (
  deposit: TDepositInfo,
  form?: Record<string, unknown>,
): TFieldConfig[] => {
  const onOpenClientCard = () => {
    const clientUrl = `${OLD_CRM_ROOT_PATH}/client-${deposit.username}`;
    linkAndClick(clientUrl, true);
  };

  return [
    {
      name: 'username',
      title: 'Client',
      node: (
        <StyledButtonClientCard
          className="ButtonClientCard"
          color="transparent"
          textColor="action"
          onClick={onOpenClientCard}
        >
          {deposit?.username}
        </StyledButtonClientCard>
      ),
      withCopyButton: true,
      valueForCopy: deposit?.username || null,
    },
    {
      name: 'client_name',
      title: 'Client name',
      withCopyButton: true,
    },
    {
      name: 'client_type',
      title: 'Client type',
    },
    {
      name: 'country_of_residence',
      title: 'Country of Residence',
    },
    {
      name: 'country_of_citizenship',
      title: 'Country of Citizenship',
    },
    {
      name: 'account_manager',
      title: 'Account Manager',
      node: deposit?.account_manager && (
        <StyledManagerRow className="ManagerRow">
          <div>
            {deposit?.account_manager?.full_name}
            {deposit?.account_manager?.full_name &&
              deposit?.account_manager?.username &&
              ','}
          </div>
          <StyledManagerLink className="ManagerLink">
            {deposit.account_manager.username}
          </StyledManagerLink>
        </StyledManagerRow>
      ),
      withCopyButton: true,
      valueForCopy: deposit?.account_manager?.username || null,
    },
    {
      name: 'deposit_group',
      title: 'Deposit group',
    },
    {
      name: 'legal_entity',
      title: 'Legal entity',
    },
    {
      name: 'account_id',
      title: 'Account ID',
      optionsConfig: {
        options:
          deposit?.account_choices?.map((i) => ({ value: i, label: i })) || [],
      },
      type: form?.is_draft ? FieldTypes.Input : FieldTypes.Select,
      required: true,
      withCopyButton: true,
    },
  ].filter((item) => !deposit.hidden_edit_fields_keys.includes(item.name));
};

export const cannotEditDepositText = 'Not enough permissions for deposit edit';
export const formValuesNotChanged = 'For save deposit please edit some field';
export const cannotEditDepositBookedText =
  "Editing deposit in current status isn't permitted";
