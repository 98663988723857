import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TReports } from '~/api/reports/reports.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';

import { makeStatus } from './Reports.helpers';
import { StyledReportsTag } from './Reports.styled';
import { TReportsGetColumns } from './Reports.types';
import { ReportsActionsCell } from './components/ReportsActionCell';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'id',
  'created',
  'author',
  'type',
  'count',
  'status',
  'action',
  'date_range',
  'is_own',
];

export const getColumns: ({
  refetchData,
  pendingReports,
}: TReportsGetColumns) => IColumn<TReports>[] = ({
  refetchData,
  pendingReports,
}) => {
  return [
    {
      Header: 'ID',
      id: 'id',
      width: 100,
    },
    {
      Header: 'Created',
      id: 'created',
      formatting: 'dateTimeUTC',
      minWidth: 180,
    },
    {
      Header: 'Author',
      id: 'author',
      minWidth: 240,
    },
    {
      Header: 'Type',
      id: 'type',
    },
    {
      Header: 'Tags',
      id: 'is_own',
      width: 100,
      Cell: ({ value }: ICellValue<TReports>) => {
        return value ? (
          <StyledReportsTag className="ReportsTag">Own</StyledReportsTag>
        ) : (
          DEFAULT_EMPTY_PLACEHOLDER
        );
      },
    },
    {
      Header: 'Date Range',
      id: 'date_range',
      minWidth: 200,
    },
    {
      Header: 'Objects',
      id: 'count',
    },
    {
      Header: 'Task',
      id: 'task',
    },
    {
      Header: 'Status',
      id: 'status',
      Cell: ({ row: { values } }: ICellValue<TReports>) => {
        const { task, status, id } = values;

        if (pendingReports?.has(id)) {
          return 'Pending';
        }

        return makeStatus(task, status);
      },
    },
    {
      Header: 'Link',
      id: 'link',
    },
    {
      id: 'action',
      Header: 'Actions',
      disableSortBy: true,
      width: 120,
      align: 'right',
      Cell: ({ row: { values } }: ICellValue<TReports>) => {
        const { status, id } = values;

        return (
          <ReportsActionsCell
            status={status}
            id={id}
            refetchData={refetchData}
          />
        );
      },
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
};

export const REFETCH_DATA_INTERVAL = 5000;
export const PAGE_SIZES = [20, 50, 100];
