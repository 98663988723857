import { styled, Tabs, Tab, ToggleButtonGroup } from 'react-ui-kit-exante';

export const StyledTabs = styled(Tabs)`
  margin: 24px 0 24px 24px;
` as typeof Tabs;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin: 0 24px 24px 24px;
`;

export const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
` as typeof Tab;
