import { useSearchParams } from 'react-router-dom';

import { FakeTableLoader } from '~/components/FakeTableLoader';

import { useBookmark } from '../../hooks';

import { TWithBookmarksProps } from './WithBookmarks.types';

export const WithBookmarks = ({
  component: WrappedComponent,
  pageName,
  tableId,
  ...wrappedComponentProps
}: TWithBookmarksProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isLoading, ...bookmarkProps } = useBookmark({
    pageName,
    setSearchParams,
    tableId,
  });

  if (isLoading || !bookmarkProps?.selectedBookmark) {
    return <FakeTableLoader title={pageName} />;
  }

  return (
    <WrappedComponent
      {...bookmarkProps}
      {...wrappedComponentProps}
      tableId={tableId}
      pageName={pageName}
    />
  );
};
