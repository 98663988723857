import { object, string } from 'yup';

export const ADD_USER_MANAGEMENT_VALUES = {
  email: '',
  first_name: '',
  last_name: '',
  branding: '',
};

export const ADD_USER_MANAGEMENT_VALIDATION_SCHEMA = object().shape({
  email: string()
    .email('Invalid email format')
    .required('Email a required field'),
  first_name: string().required('First name is a required field'),
  last_name: string().required('Last name is a required field'),
  branding: string().nullable().required('Banding a required field'),
});
