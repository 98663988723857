import { isPlainObject } from 'lodash';

export function convertToFormattedString<T>(value: T, tryToParse = false) {
  if (isPlainObject(value)) {
    return JSON.stringify(value, null, 2);
  }

  if (tryToParse && typeof value === 'string') {
    try {
      const parsed = JSON.parse(value);
      return JSON.stringify(parsed, null, 2);
    } catch (_) {
      // no action
    }
  }

  return `${value}`;
}
