import { createApi } from '@reduxjs/toolkit/query/react';

import { NODE_BACK_URL } from '~/api/nodeBackApi/endpoints';
import { TFetchTradesResponse } from '~/api/nodeBackApi/trades/trades.api.types';
import { baseQueryHandler } from '~/utils/apiRequest';
import { paramsTransformer } from '~/utils/params';

import {
  DEFAULT_TRADES_RESPONSE,
  REQUESTED_FIELDS,
} from './trades.api.constants';
import { TRADES_PARAMS_MAPPER } from './trades.api.helpers';

export const tradesApi = createApi({
  reducerPath: 'tradesApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Trades'],
  endpoints: (builder) => ({
    getTrades: builder.query<TFetchTradesResponse, Record<string, unknown>>({
      queryFn: async function getTradesHandler(
        params,
        _,
        __,
        fetchWithBaseQuery,
      ) {
        const preparedParams = paramsTransformer({
          params,
          mapper: TRADES_PARAMS_MAPPER,
        });

        const { data, error } = await fetchWithBaseQuery({
          url: `${NODE_BACK_URL}/api/trades`,
          params: { ...preparedParams, fields: REQUESTED_FIELDS },
        });

        if (error || !data) {
          return {
            data: DEFAULT_TRADES_RESPONSE,
          };
        }

        return {
          data: {
            trades: data.data,
            pagination: data.pagination,
          },
        };
      },
      providesTags: ['Trades'],
    }),

    updateTrade: builder.mutation({
      query: ({ orderId, orderPos, ...data }) => {
        return {
          url: `${NODE_BACK_URL}/api/proxy/core/v2.0/trades/${orderId}/${orderPos}`,
          method: 'POST',
          data,
        };
      },
    }),

    rollbackTrade: builder.mutation<
      unknown,
      { orderId: string; orderPosition: number }
    >({
      query: ({ orderId, orderPosition }) => ({
        url: `${NODE_BACK_URL}/api/proxy/core/v2.1/trades/rollback/${orderId}/${orderPosition}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useLazyGetTradesQuery,
  useUpdateTradeMutation,
  useRollbackTradeMutation,
} = tradesApi;
