import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  getDefaultHeaders,
  paramsSerializer,
  getAccessTokenFromCookies,
  getAPIHost,
} from './helpers';
import { TOptionsArguments, TRequestArguments } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function apiRequest<T = any>(
  args: TRequestArguments,
  options?: Partial<TOptionsArguments>,
): Promise<AxiosResponse<T>> {
  const { url: originalUrl, method = 'GET', data, headers, params } = args;

  const baseUrl = getAPIHost();
  const fullApiUrl = /^(https?:\/\/)/.test(originalUrl)
    ? originalUrl
    : `${baseUrl}${originalUrl}`;

  const config: AxiosRequestConfig = {
    url: fullApiUrl,
    method,
    data: method !== 'GET' ? data : null,
    headers: {
      ...getDefaultHeaders(getAccessTokenFromCookies()),
      ...(headers || {}),
    },
    params,
    paramsSerializer,
    ...options,
  };

  return axios(config);
}
