import { convertDateToISO } from './convertDateFieldsToISOFormat';

type TConvertedToISO<T extends string> = Record<
  T,
  ReturnType<typeof convertDateToISO>
>;

export function prepareDateRangeStringForAPI<T extends string>(
  fields: T[],
  values: (string | null)[],
) {
  return fields.reduce((acc, curr, index) => {
    const currentValue = values[index];

    if (!currentValue) {
      return acc;
    }

    return {
      ...acc,
      [curr]: convertDateToISO(currentValue),
    };
  }, {} as TConvertedToISO<T>);
}
