import { IOnFetchArguments, ISortBy } from 'react-ui-kit-exante';

export const getSortingParams = (params?: IOnFetchArguments) => {
  const { sortingParams } = params || {};

  if (Array.isArray(sortingParams) && Boolean(sortingParams.length)) {
    const [sorting] = sortingParams;
    const { desc, id } = (sorting as ISortBy) || {};

    if (id) {
      return {
        order: desc ? 'desc' : 'asc',
        orderBy: id,
      };
    }
  }
  return {};
};
