import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { useGetDepositQuery, usePostDepositConfirmMutation } from '~/api';
import {
  EntryColumn,
  EntrySection,
  EntryPage,
  EntryScreenWrapper,
} from '~/components/EntryScreen';
import { StatusInfo } from '~/components/StatusInfo';
import { Comments } from '~/modules/comments/components/Comments';
import { Confirms } from '~/modules/confirms/components/Confirms';
import { TParams } from '~/router/router.types';

import { POLLING_ENABLE_STATUSES } from './DepositEntry.constants';
import { DepositForm } from './components/DepositForm';
import { DepositFormProvider } from './components/DepositForm/contexts/DepositFormContext';
import { DepositHeader } from './components/DepositHeader';
import { Documents } from './components/Documents';

export const DepositEntry = () => {
  const { id } = useParams<TParams>();
  const [currentStatus, setCurrentStatus] = useState<number>();

  const {
    data: deposit,
    isLoading,
    isFetching,
  } = useGetDepositQuery(
    { id: String(id) },
    {
      pollingInterval:
        currentStatus && POLLING_ENABLE_STATUSES.includes(currentStatus)
          ? 10_000
          : 0,
    },
  );

  const [postConfirm, state] = usePostDepositConfirmMutation();

  const onConfirm = (key: string, entityId: string) => {
    postConfirm({ id: entityId, confirm: key });
  };

  useEffect(() => {
    const statusId = deposit?.status?.id;
    if (statusId) {
      setCurrentStatus(statusId);
    }
  }, [deposit]);

  if (isLoading || !deposit) {
    return (
      <EntryScreenWrapper>
        <Loader isCentered size={32} />
      </EntryScreenWrapper>
    );
  }

  return (
    <DepositFormProvider deposit={deposit}>
      <EntryScreenWrapper>
        <DepositHeader deposit={deposit} />

        <EntryPage>
          <EntryColumn>
            <EntrySection>
              <StatusInfo
                entityKey="deposit"
                status={deposit.status?.text}
                responsibleTitle={deposit?.responsible_title}
              />
            </EntrySection>
            <Confirms
              data={deposit.stages}
              type="deposit"
              onConfirm={onConfirm}
              isLoading={state.isLoading}
            />
            <EntrySection title="Uploaded documents">
              <Documents
                deposit={deposit}
                isLoading={isLoading || isFetching}
              />
            </EntrySection>
            <EntrySection title="Comments">
              <Comments
                entityId={deposit.id}
                entityType="deposits"
                needRefetch={isFetching}
              />
            </EntrySection>
          </EntryColumn>
          <DepositForm deposit={deposit} />
        </EntryPage>
      </EntryScreenWrapper>
    </DepositFormProvider>
  );
};
