import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TNotifications } from '~/api/notifications/notifications.types';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = ['pk', 'name', 'state'];

export function getColumns(): IColumn<TNotifications>[] {
  return [
    {
      Header: 'ID',
      id: 'pk',
    },
    {
      Header: 'Name',
      id: 'name',
    },
    {
      Header: 'State',
      id: 'state',
      Cell: ({ value }: ICellValue<TNotifications>) => value.text,
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
}
