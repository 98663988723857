export const DEFAULT_INSTANCE = 'crm';

export const getCrmInstanceName = () => {
  const splittedPath = window.location.pathname.split('/');

  let crmInstanceName;

  const firstUrlPart = splittedPath[1];

  if (
    firstUrlPart.startsWith('ERU-') ||
    firstUrlPart.startsWith('release') ||
    firstUrlPart.startsWith('CRM-')
  ) {
    crmInstanceName = splittedPath[2];
  } else if (['crm', 'cprm'].includes(firstUrlPart)) {
    // base url is /crm or /cprm (in RUN-UI)
    crmInstanceName = firstUrlPart;
  } else {
    // run locally
    crmInstanceName = DEFAULT_INSTANCE;
  }

  return crmInstanceName;
};

export const APP_INSTANCE =
  process.env.REACT_APP_INSTANCE || getCrmInstanceName();

export const APP_INSTANCE_WINDOW_CONFIG_NAME =
  APP_INSTANCE === 'crm' ? 'CRM_UI_REACT' : 'CPRM_UI_REACT';

/* eslint-disable */
console.log('APP_INSTANCE', APP_INSTANCE);
console.log('process.env.REACT_APP_INSTANCE', process?.env?.REACT_APP_INSTANCE);
/* eslint-enable */
