import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { TStyledToCellProps } from './ToCell.types';

export const StyledToCell = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isNew']),
})<TStyledToCellProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isNew, theme }) =>
    isNew ? theme?.color?.typo?.warning : theme?.color?.typo?.primary};
`;
