import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useLazyGetBookmarksBOQuery, useLazyGetBookmarksQuery } from '~/api';
import { useSubscribeOnEvent } from '~/hooks/useSubscribeOnEvent';

import { TBookmarks } from '../../types';

import { getDefaultBookmark, bookmarkMapper } from './helpers';
import { TBookmarkLoadedProps, TBookmarkLoadedResponseProps } from './types';

export const useBookmarkData = ({
  tableId,
  pageName,
  isBOBookmarks,
}: TBookmarkLoadedProps): TBookmarkLoadedResponseProps => {
  const { search } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [fetchBookmarks] = useLazyGetBookmarksQuery();
  const [fetchBookmarksBO] = useLazyGetBookmarksBOQuery();
  const [activeBookmark, setActiveBookmark] = useState<TBookmarks | null>(null);
  const [loading, setLoading] = useState(false);

  const clearSavedFilters = useCallback(() => {
    const lsKey = `${tableId}-view-params`;
    localStorage.removeItem(lsKey);
  }, [tableId]);

  const handleChangeBookmark = useCallback(
    (event: unknown) => {
      const detail = get(event, 'detail', {});
      const onBookmarkChange = get(detail, 'onBookmarkChange', () => {});
      const linkTo = get(detail, 'selectedBookmark.linkTo', '');

      const searchParams = new URLSearchParams(linkTo);
      const bookmark = searchParams.get('bookmark');

      if (!bookmark) {
        setLoading(true);
        setActiveBookmark(null);
      }

      clearSavedFilters();
      setSearchParams('');

      onBookmarkChange();

      setLoading(false);
    },
    [clearSavedFilters, setSearchParams],
  );

  useSubscribeOnEvent('onChangeBookmark', handleChangeBookmark);

  const getInitialBookmark = useCallback(async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(search);
      const bookmark = searchParams.get('bookmark');

      if (!bookmark) {
        return;
      }

      const freshBookmarks = isBOBookmarks
        ? await fetchBookmarksBO()
        : await fetchBookmarks();

      const bookmarkData = freshBookmarks?.data?.find(
        (b) => b.innerId === Number(bookmark) && tableId === b.bookmarkTab,
      );

      if (bookmarkData) {
        setActiveBookmark(bookmarkMapper(bookmarkData));
      }
    } finally {
      setLoading(false);
    }
  }, [fetchBookmarks, search]);

  useEffect(() => {
    getInitialBookmark();
  }, [getInitialBookmark]);

  const getSelectedBookmark = useCallback(() => {
    if (activeBookmark) {
      return activeBookmark;
    }
    return getDefaultBookmark(pageName);
  }, [activeBookmark, pageName]);

  return {
    selectedBookmark: getSelectedBookmark(),
    isLoading: loading,
  };
};
