export const REQUESTED_TRANSACTIONS_FIELDS = [
  'id',
  'accountId',
  'timestamp',
  'operationType',
  'sum',
  'convertedSum',
  'asset',
  'symbolId',
  'symbolType',
  'who',
  'comment',
  'internalComment',
  'clientType',
  'internalCounterparty',
  'valueDate',
  'orderId',
  'orderPos',
  'price',
  'exchangeCommission',
  'category',
  'settlementCurrencyMovement',
  'settlementCounterparty',
  'clientCounterparty',
  'baseCurrency',
  'transferId',
  'uuid',
  'legalEntity',
  'executionCounterparty',
  'extraData',
  'settlementCurrency',
  'parentUuid',
  'chainId',
  'blockedQtty',
];

export const DEFAULT_TRANSACTIONS_RESPONSE = {
  transactions: [],
  pagination: { total: 0 },
};
