import { getValueFromOption } from '~/utils/getValueFromOption';

import {
  TAccountPermissionByUsername,
  TAtpPermissionsResponse,
} from './accountPermissions.types';

export function atpPermissionsMapper({
  superuser,
  ...rest
}: TAtpPermissionsResponse) {
  return {
    defaultAccountAccess: superuser || null,
    atpPermissions: rest,
  };
}

export function getBackInjectedPermissions(
  permissions: TAccountPermissionByUsername[],
  createResponse: TAccountPermissionByUsername[] | null,
) {
  if (!createResponse) {
    return permissions;
  }

  return permissions.reduce<TAccountPermissionByUsername[]>((acc, curr) => {
    const repeatedItem = createResponse.find(
      (item) => item.accountId === curr.accountId,
    );

    if (repeatedItem) {
      return [...acc, repeatedItem];
    }

    return [...acc, curr];
  }, []);
}

export function buildDataWithExtractedAutocompleteValue<
  T extends Record<keyof T, unknown>,
>(arr: T[], fields: string[]) {
  return arr.map((item) => {
    const itemKeys = Object.keys(item).filter((key) => fields.includes(key));

    if (!itemKeys.length) {
      return item;
    }

    return itemKeys.reduce<T>((acc, curr) => {
      return {
        ...acc,
        [curr]: getValueFromOption(item[curr as keyof T]),
      };
    }, item);
  });
}
