export const linkAndClick = (url: string, isOpenInNewWindow?: boolean) => {
  const link = document.createElement('a');
  link.href = url;
  link.style.display = 'none';

  if (isOpenInNewWindow) {
    link.target = '_blank';
  }

  document.body.appendChild(link);
  link.click();
  link.remove();
};
