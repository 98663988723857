import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { TDefaultParams } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TDataChangeRequests,
  TDataChangeRequestsPaginationResponse,
  TExportCsvResponse,
  TExportCsvParams,
  TDataChangeRequest,
} from './dataChangeRequests.types';

export const dataChangeRequestsApi = createApi({
  reducerPath: 'dataChangeRequestsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['DataChangeRequests', 'ExportCsv', 'DataChangeRequest'],
  endpoints: (builder) => ({
    getDataChangeRequests: builder.query<
      TDataChangeRequestsPaginationResponse<TDataChangeRequests>,
      TDefaultParams
    >({
      query: (params) => ({
        url: '/clientsarea/crm/data_change_requests/',
        params,
      }),
      providesTags: ['DataChangeRequests'],
    }),
    exportCsv: builder.query<TExportCsvResponse, TExportCsvParams>({
      query: (params) => ({
        url: '/clientsarea/crm/data_change_requests/report/',
        params,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          Notification.success({
            title: `Data Change Requests were exported to CSV. ${data.message}`,
          });
        } catch (e) {
          Notification.error({
            title: `Data Change Requests were not exported to CSV.`,
          });
        }
      },
      providesTags: ['ExportCsv'],
    }),
    getDataChangeRequest: builder.query<TDataChangeRequest, { id: string }>({
      query: ({ id }) => ({
        url: `/rest/data_change_request/${id}/`,
      }),
      providesTags: ['DataChangeRequest'],
    }),
    approveDataChangeRequest: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/clientsarea/crm/data_change_requests/${id}/approve/`,
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: `Data Change Request approved`,
          });
        } catch (e) {
          Notification.error({
            title: `Approve Data Change Request error`,
          });
        }
      },
      invalidatesTags: ['DataChangeRequests'],
    }),
    rejectDataChangeRequest: builder.mutation<
      void,
      { id: string; rejectReason: string }
    >({
      query: ({ id, rejectReason }) => ({
        url: `/rest/data_change_request/${id}/reject/`,
        method: 'PUT',
        data: {
          comment: rejectReason,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: `Data Change Request rejected`,
          });
        } catch (e) {
          Notification.error({
            title: `Reject Data Change Request error`,
          });
        }
      },
      invalidatesTags: ['DataChangeRequests'],
    }),
  }),
});
export const {
  useLazyGetDataChangeRequestsQuery,
  useLazyExportCsvQuery,
  useGetDataChangeRequestQuery,
  useApproveDataChangeRequestMutation,
  useRejectDataChangeRequestMutation,
} = dataChangeRequestsApi;
