export const isEmptyValues = (value: unknown, withoutZero?: boolean) => {
  if ((value === 0 || value === '0') && withoutZero) {
    return true;
  }

  if (value === undefined || value === null) {
    return true;
  }

  return (
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
};
