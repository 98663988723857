import { FilterValue } from 'react-ui-kit-exante';

import { transformVariantsToSelectOptions } from '~/utils/transformVariantsToSelectOptions';

import { EReportType } from './Reports.types';

export const typeFilterVariants = [
  EReportType.Applications,
  EReportType.Clients,
  EReportType.ClientsCompliance,
  EReportType.Recipients,
  EReportType.Monitoring,
  EReportType.Withdrawals,
  EReportType.Comments,
  EReportType.Datachange,
];

type GetAdditionalFilters = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
};

export enum FilterList {
  Type = 'type',
}

export function getAdditionalFilters({
  onFilter,
  onRemove,
}: GetAdditionalFilters) {
  return [
    {
      Header: 'Type',
      accessor: FilterList.Type,
      filterOptions: transformVariantsToSelectOptions(typeFilterVariants),
      type: 'select',
      onFilter,
      onRemove,
    },
  ];
}

export const prepareReportsFilters = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      key.includes('type') && typeof value === 'string'
        ? value
            .replace(' Requests', '')
            .replace('Comments', 'commentsreport')
            .replace(/\s+/g, '')
            .toLowerCase()
        : value,
    ]),
  );
};
