import { AuditLogsTable } from '~/pages/ApplicationEntry/components/AuditLogsTable';

import { NotificationsTable } from '../../components/NotificationsTable';
import { OnboardingSettingsContainer } from '../../components/OnboardingSettings';
import { TradeTable } from '../../components/TradeTable';
import { TransactionsTable } from '../../components/TransactionsTable';
import { ApplicationInfoTab } from '../ApplicationInfoTab';
import { QualifiedLeadTab } from '../QualifiedLeadTab';

export const mapTabsComponents = {
  info: <ApplicationInfoTab />,

  onboarding_settings_subtab: <OnboardingSettingsContainer />,
  'monitoring-subtab': <OnboardingSettingsContainer />, // todo change
  'risk_scoring-subtab': <OnboardingSettingsContainer />, // todo change

  'qualified-lead': <QualifiedLeadTab />,

  'trades-subtab': <TradeTable />,
  'transactions-subtab': <TransactionsTable />,
  'notifications-activity-subtab': <NotificationsTable />,
  'audit-logs-subtab': <AuditLogsTable />,
};
