import {
  CircleCheckIcon,
  CircleCloseIcon,
  CircleCopyIcon,
} from 'react-ui-kit-exante';

import {
  EDataChangeRequestStatuses,
  TDataChangeRequest,
} from '~/api/dataChangeRequests/dataChangeRequests.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';

import { TDataChangeRequestLog } from './DataChangeRequestsEntry.types';

export const mapIcons: Record<EDataChangeRequestStatuses, any> = {
  [EDataChangeRequestStatuses.Approved]: CircleCheckIcon,
  [EDataChangeRequestStatuses.Rejected]: CircleCloseIcon,
  [EDataChangeRequestStatuses.New]: CircleCopyIcon,
};

export const mergePhoneObjects = (
  data: TDataChangeRequestLog[],
): TDataChangeRequestLog[] => {
  const countryObj = data.find((item) => item.field === 'Country code');
  const phoneObj = data.find((item) => item.field === 'Phone');

  if (countryObj && phoneObj) {
    phoneObj.from = countryObj.from + phoneObj.from;
    phoneObj.to = countryObj.to + phoneObj.to;

    return data.filter((item) => item.field !== 'Country code');
  }

  return data;
};

export const transformDataChangeRequestData = (
  data: TDataChangeRequest['data_list'] | undefined,
): TDataChangeRequestLog[] => {
  if (!data) {
    return [];
  }
  const transformedData = data.map((row) => ({
    field: String(row[0] ?? DEFAULT_EMPTY_PLACEHOLDER),
    from: String(row[1] ?? DEFAULT_EMPTY_PLACEHOLDER),
    to: String(row[2] ?? DEFAULT_EMPTY_PLACEHOLDER),
  }));

  return mergePhoneObjects(transformedData);
};
