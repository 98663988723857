import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { IOption } from '~/types/form/options';
import { TTableFilters } from '~/types/table';
import { baseQueryHandler } from '~/utils/apiRequest';
import { getFormData } from '~/utils/formData';

import {
  TAddContactForm,
  TAddContactResponse,
  TContacts,
  TExportCsvParams,
  TExportCsvResponse,
  TImportContactsResponse,
  TImportTagsParams,
  TImportTagsResponse,
  TImportedContactsResponse,
  TSearchTagResponse,
} from './contacts.types';

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'Contacts',
    'ContactsTableFilters',
    'ContactsExportCsv',
    'AddContact',
    'SearchTags',
  ],
  endpoints: (builder) => ({
    getContacts: builder.query<
      TDefaultPaginationResponse<TContacts>,
      {
        selectedColumns: string;
        params: TDefaultParams;
      }
    >({
      query: ({ selectedColumns, params }) => ({
        url: '/clientsarea/crm/application/rest/table/contact/',
        method: 'POST',
        data: {
          ...params,
          // from old crm logic
          search_type: 'clients',
          columns: selectedColumns,
        },
      }),
      providesTags: ['Contacts'],
    }),

    getContactsTableFilters: builder.query<TTableFilters, void>({
      query: () => ({
        url: '/rest/filter-tag/?table_name=contact',
      }),
      providesTags: ['ContactsTableFilters'],
    }),

    contactsExportCsv: builder.mutation<TExportCsvResponse, TExportCsvParams>({
      query: ({ preparedFilters, selectedColumns, reportType }) => ({
        url: '/clientsarea/crm/application/rest/table/contact/',
        method: 'POST',
        data: {
          ...preparedFilters,
          columns: selectedColumns,
          report_format: 'csv',
          report_type: reportType,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          Notification.success({
            title: data.message,
          });
        } catch (e) {
          Notification.error({
            title: 'CSV export error',
          });
        }
      },
    }),

    addContact: builder.mutation<TAddContactResponse, TAddContactForm>({
      query: (formData) => {
        return {
          url: '/rest/contact/create/',
          method: 'POST',
          data: {
            ...formData,
          },
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        Notification.success({
          title: 'Contact was created successfully',
        });
      },
      invalidatesTags: ['Contacts'],
    }),

    importContacts: builder.mutation<TImportContactsResponse, { file: File }>({
      query: ({ file }) => {
        const formData = getFormData();
        formData.data.append('file', file);

        return {
          url: `/rest/contact/import/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully uploaded. Starting import...',
          });
        } catch (e) {
          Notification.error({
            title: 'File uploading error',
          });
        }
      },
    }),

    getImportedContacts: builder.query<TImportedContactsResponse, string>({
      query: (id) => ({
        url: `/rest/task/result/${id}/`,
      }),
    }),

    searchTags: builder.query<IOption[], string>({
      query: (query) => {
        return {
          url: `/rest/tag/search/?search=${query}`,
        };
      },
      transformResponse(response: TSearchTagResponse) {
        return response.map((item) => ({
          label: item.title,
          value: item.name,
        }));
      },
      providesTags: ['SearchTags'],
    }),

    importTag: builder.mutation<TImportTagsResponse, TImportTagsParams>({
      query: (formData) => {
        const { tagName, filterField, importMode, file } = formData;

        const newForm = getFormData([
          ['tag_name', String(tagName)],
          ['filter_field', String(filterField)],
          ['import_mode', String(importMode)],
        ]);

        newForm.data.append('file', file);

        return {
          url: `/rest/tag/import/`,
          method: 'POST',
          ...newForm,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Starting import...',
          });
        } catch (e) {
          Notification.error({
            title: 'Import error',
          });
        }
      },
    }),
  }),
});
export const {
  useGetContactsTableFiltersQuery,
  useContactsExportCsvMutation,
  useAddContactMutation,
  useLazyGetContactsQuery,
  useImportContactsMutation,
  useGetImportedContactsQuery,
  useLazySearchTagsQuery,
  useSearchTagsQuery,
  useImportTagMutation,
} = contactsApi;
