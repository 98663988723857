import cn from 'classnames';
import { FC } from 'react';

import { TChildren } from '~/types/TChildren';

import { StyledEntryColumnContainer } from './EntryColumn.styled';
import { TEntryColumnProps } from './EntryColumn.types';

export const EntryColumn: FC<TEntryColumnProps & TChildren> = ({
  children,
  className,
}) => {
  return (
    <StyledEntryColumnContainer
      className={cn(['EntryColumnContainer', className])}
    >
      {children}
    </StyledEntryColumnContainer>
  );
};
