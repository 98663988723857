import { IColumn } from 'react-ui-kit-exante';

import { TDataChangeRequests } from '~/api/dataChangeRequests/dataChangeRequests.types';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'user',
  'type',
  'ip',
  'legal_entity',
  'added',
  'changed_datetime',
  'changed_by',
  'status',
];

export function getColumns(): IColumn<TDataChangeRequests>[] {
  return [
    {
      Header: 'ID',
      id: 'id',
    },
    {
      Header: 'User',
      id: 'user',
    },
    {
      Header: 'Request Type',
      id: 'type',
    },
    {
      Header: 'Legal Entity',
      id: 'legal_entity',
    },
    {
      Header: 'IP',
      id: 'ip',
    },
    {
      Header: 'Added At',
      id: 'added',
    },
    {
      Header: 'Changed At',
      id: 'changed_datetime',
    },
    {
      Header: 'Changed By',
      id: 'changed_by',
    },
    {
      Header: 'Status',
      id: 'status',
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
}
