import { pick } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  calculateCountOfPages,
  useTableData,
  IconButton,
  Notification,
  OnSaveEditableRow,
} from 'react-ui-kit-exante';

import {
  useGetAccountTypesQuery,
  useUpdateTransactionMutation,
  useLazyGetTransactionsQuery,
  useRollbackTransactionMutation,
  DEFAULT_TRANSACTIONS_RESPONSE,
  TTransaction,
  TTransactionsState,
  TRANSACTIONS_PARAMS_MAPPER,
  REQUESTED_TRANSACTIONS_FIELDS,
} from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { DownloadButton } from '~/components/DownloadButton';
import { RefreshButton } from '~/components/RefreshButton';
import { useAccountsByUser } from '~/pages/ApplicationEntry/components/AuditLogsTable/useAccountsByUser';
import { TParams } from '~/router/router.types';
import { transformVariantsToSelectOptions } from '~/utils/forms/transformVariantsToSelectOptions';
import { paramsTransformer } from '~/utils/params';
import { getDefaultPagination, getTableId } from '~/utils/table';
import { createLinkToDownloadCSV } from '~/utils/table/createLinkToDownloadCSV';

import { getAdditionalFilters } from '../TradeTable/TradeTable.helpers';

import {
  DEFAULT_SORTING_TS,
  DISPLAYED_COLUMN_KEYS,
  getDefaultSorting,
} from './TransactionsTable.constants';
import { getDefaultFilters, useColumns } from './TransactionsTable.helpers';

export const TransactionsTable: FC = () => {
  const { id } = useParams<TParams>();

  const [rollbackId, setRollbackId] = useState(0);

  const { accountsList, accountListLoading } = useAccountsByUser(String(id));

  const [fetchTransactions] = useLazyGetTransactionsQuery();
  const { data: accountTypes } = useGetAccountTypesQuery();
  const [updateTransaction, { isLoading: isUpdateTransactionLoading }] =
    useUpdateTransactionMutation();
  const [rollbackTransactions, stateRollback] =
    useRollbackTransactionMutation();

  const tableId = getTableId('transactions');
  const isLoadingRollback = stateRollback.isLoading;

  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const getTransactions = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      if (accountListLoading) {
        return DEFAULT_TRANSACTIONS_RESPONSE;
      }

      delete params?.page;

      const { data } = await fetchTransactions({
        ...params,
        hideLockTransactions: false,
        accountId: params?.accountId || accountsList?.join('|'),
      });

      return data;
    },

    [fetchTransactions, accountsList, accountListLoading],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getTransactions },
      filters: { getDefaultFilters, required: ['fromTo'] },
      tableId,
      saveViewParamsAfterLeave: true,
      pagination: {
        getDefaultPagination,
      },
      sorting: { getDefaultSorting },
    }),
    [getTransactions, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<TTransactionsState | undefined>(tableDataArgs);

  const total = data?.pagination?.total || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  const handleRollbackTrade = useCallback(async () => {
    if (!rollbackId) {
      return;
    }

    const res = await rollbackTransactions(Number(rollbackId));

    if (!('error' in res)) {
      refetch();
      Notification.success({
        title: 'Transaction has been rolled back',
      });
    }
  }, [rollbackId, rollbackTransactions]);

  const onSaveRowHandler: OnSaveEditableRow<TTransaction> = useCallback(
    async (_, newRow) => {
      const result = await updateTransaction(
        pick(newRow, ['uuid', 'comment', 'internalComment']),
      );
      if (!('error' in result)) {
        Notification.success({
          title: 'Transaction has been updated',
        });

        refetch();
      }
    },
    [refetch, updateTransaction],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [removeFilter, setFilter],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const resultParams = paramsTransformer({
    params: {
      ...filters,
      hideLockTransactions: false,
      accountId: filters?.accountId || accountsList?.join('|'),
      limit: Infinity,
      fields: REQUESTED_TRANSACTIONS_FIELDS,
    },
    mapper: TRANSACTIONS_PARAMS_MAPPER,
  });

  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onSaveRowHandler,
      order: 1,
      additionalActions: (rowData?: TTransaction) => {
        const getOpenConfirm = () => rowData?.id === rollbackId;
        const isOpenConfirm = getOpenConfirm();

        return [
          {
            label: (
              <ActionWithConfirmation
                placement="bottom"
                cancelButtonNameKey="Cancel"
                confirmButtonNameKey="Rollback"
                onConfirm={handleRollbackTrade}
                title={`Rollback transaction id: ${rollbackId}?`}
                disabled={isLoading}
                closeHandler={() => setRollbackId(0)}
                externalOpened={isOpenConfirm}
              >
                <IconButton
                  iconSize={16}
                  title="Rollback"
                  iconName="RollbackIcon"
                  iconColor="secondary"
                />
              </ActionWithConfirmation>
            ),
            title: 'Rollback',
            order: 0,
            onClick: ({ id: rowId }: TTransaction) =>
              setRollbackId(rowId as number),
            show: (_: any, values: TTransaction) => {
              const setIsShow = () => {
                if (values?.extraData?.isRollback) {
                  return false;
                }
                return !(values.operationType === 'TRADE');
              };

              return setIsShow();
            },
          },
        ];
      },
    }),
    [rollbackId],
  );

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
    {
      key: 'download',
      component: (
        <DownloadButton
          title="Export CSV"
          fileName="transactions.csv"
          link={createLinkToDownloadCSV('/api/transactions/csv', resultParams)}
        />
      ),
    },
  ];

  useEffect(() => {
    setRollbackId(0);
  }, [isLoadingRollback]);

  return (
    <Table
      title="Transactions"
      className="TransactionsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      isLoading={isLoading || isUpdateTransactionLoading || isLoadingRollback}
      filtersExpanded
      isFlexLayout
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.transactions || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      saveColumnOrder
      defaultSortBy={DEFAULT_SORTING_TS}
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      onSort={setSorting}
      rowActions={rowActions}
      additionalActions={additionalActions}
    />
  );
};
