import { Input, styled } from 'react-ui-kit-exante';

export const StyledDepositEntryActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-inline-end: 16px;
`;

export const StyledCommentInput = styled(Input)`
  .MuiInputBase-multiline {
    textarea {
      padding: 0;
    }
  }
`;
