import { FC, useContext, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useLazyGetReportLinkQuery, useLazyGetReportQuery } from '~/api';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { linkAndClick } from '~/utils/linkAndClick';

import { EReportStatus } from '../../Reports.types';
import { PendingReportsContext } from '../../contexts/PendingReportsContext';

import {
  StyledEmptyAction,
  StyledReportsActionsCell,
  StyledReportsActionsLoader,
} from './ReportsActionsCell.styled';
import { TReportsActionsProps } from './ReportsActionsCell.types';

export const ReportsActionsCell: FC<TReportsActionsProps> = ({
  status,
  id,
  refetchData,
}) => {
  const { pendingReports, updatePendingReports } = useContext(
    PendingReportsContext,
  );

  const [isRestarting, setIsRestarting] = useState<boolean>(false);

  const [fetchReport] = useLazyGetReportQuery();
  const [getDownloadLink] = useLazyGetReportLinkQuery();

  const restartReport = async () => {
    updatePendingReports(id);
    setIsRestarting(true);
    await fetchReport(String(id));

    refetchData(id);
  };

  const downloadReport = async () => {
    const { data } = await getDownloadLink(String(id));

    if (data) {
      linkAndClick(data.url);
    }
  };

  const isShowLoader = pendingReports?.has(id) || isRestarting;

  return (
    <StyledReportsActionsCell className="ReportsActionsCell">
      {isShowLoader && <StyledReportsActionsLoader isInner size={24} />}
      {!isShowLoader &&
        (status === EReportStatus.Done || status === EReportStatus.Failure) && (
          <IconButton
            iconName="RotateCcwIcon"
            iconColor="secondary"
            iconSize={24}
            title="Restart"
            onClick={restartReport}
          />
        )}
      {!isShowLoader && status === EReportStatus.Done && (
        <IconButton
          iconName="DownloadIcon"
          className="ReportsActionsLink"
          iconColor="secondary"
          title="Download"
          onClick={downloadReport}
        />
      )}
      {!isShowLoader && status === EReportStatus.Failure ? (
        <StyledEmptyAction className="EmptyAction">
          {DEFAULT_EMPTY_PLACEHOLDER}
        </StyledEmptyAction>
      ) : (
        ''
      )}
    </StyledReportsActionsCell>
  );
};
