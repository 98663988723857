import { Table } from 'react-ui-kit-exante';

import { TFakeTableLoaderProps } from './FakeTableLoader.types';

// use this component when need to show table skeleton without initial data
export const FakeTableLoader = ({
  title = '',
  columnsCount = 4,
  rowsCount = 20,
}: TFakeTableLoaderProps) => {
  return (
    <Table
      showTableInfo
      isLoading
      columns={[...Array(columnsCount)].map((_, index) => ({
        id: String(index),
        accessor: String(index),
      }))}
      tableId=""
      data={[]}
      skeletonsCount={rowsCount}
      isFlexLayout
      title={title}
    />
  );
};
