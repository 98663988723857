import { OnboardingSettings } from './OnboardingSettings';
import { OnboardingSettingsProvider } from './context';

export const OnboardingSettingsContainer = () => {
  return (
    <OnboardingSettingsProvider>
      <OnboardingSettings />
    </OnboardingSettingsProvider>
  );
};
