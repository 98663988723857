import { UserManagementRoles } from '../UserManagementPages.constants';
import { UserManagement } from '../components/UserManagement';

export const Marketing = () => {
  return (
    <UserManagement
      title="Marketing"
      typeOfRole={UserManagementRoles.Marketing}
    />
  );
};
