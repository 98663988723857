import { IconButton } from 'react-ui-kit-exante';

import { TRefreshButtonProps } from './RefreshButton.types';

export const RefreshButton = ({
  onRefresh,
  disabled,
  iconColor,
  iconSize,
  type = 'button',
  title = 'Refresh',
}: TRefreshButtonProps) => {
  return (
    <IconButton
      iconName="CircleArrowForwardIcon"
      iconColor={iconColor}
      iconSize={iconSize}
      disabled={disabled}
      title={title}
      onClick={onRefresh}
      type={type}
    />
  );
};
