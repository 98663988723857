import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IconButton } from 'react-ui-kit-exante';

import { useAddContactMutation } from '~/api';
import { TAddContactForm } from '~/api/contacts/contacts.types';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { FormInputContainer } from '~/components/Form/FormInputContainer/FormInputContainer';
import { FormPhoneInputContainer } from '~/components/Form/FormPhoneInputContainer/FormPhoneInputContainer';
import { FormSelectContainer } from '~/components/Form/FormSelectContainer/FormSelectContainer';
import { OLD_CRM_ROOT_PATH } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';
import { stopPropagationOnKeyDown } from '~/utils/stopPropagationOnKeyDown';

import {
  ADD_CONTACT_FORM_DEFAULT_VALUES,
  ADD_CONTACT_FORM_VALIDATION_SCHEMA,
  contactTypeOptions,
} from './AddContact.constants';
import {
  StyledAddContactForm,
  StyledAddContactFormError,
} from './AddContact.styled';

export const AddContact: FC = () => {
  const [triggerAddContact] = useAddContactMutation();
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [addContactAnchorEl, setAddContactAnchorEl] =
    useState<null | HTMLElement>(null);

  const defaultValues = ADD_CONTACT_FORM_DEFAULT_VALUES;

  const methods = useForm<TAddContactForm>({
    defaultValues,
    resolver: yupResolver(ADD_CONTACT_FORM_VALIDATION_SCHEMA),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = methods;

  const emailOrPhoneError = errors?.email_or_phone?.message;

  const handleAddContactOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAddContactAnchorEl(event.currentTarget);
    setAddContactOpen(true);
  };

  const handleAddContactClose = () => {
    reset();
    setAddContactAnchorEl(null);
    setAddContactOpen(false);
  };

  const saveFormData = async () => {
    const formData = getValues();

    try {
      const response = await triggerAddContact(formData).unwrap();
      const clientUrl = `${OLD_CRM_ROOT_PATH}/client-${response.id}`;

      linkAndClick(clientUrl);
    } catch (error: any) {
      const errorObj = JSON.parse(error);

      if (errorObj) {
        Object.entries(errorObj).forEach(([key, messages]) => {
          const formKey = key as keyof TAddContactForm;

          const messageArray = messages as string[];

          setError(
            formKey,
            {
              type: 'customError',
              message: messageArray.join(', '),
            },
            { shouldFocus: true },
          );
        });
      }
    }
  };

  const addContactForm = (
    <FormProvider {...methods}>
      <StyledAddContactForm className="AddContactForm">
        <FormInputContainer
          name="first_name"
          label="First name"
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
        <FormInputContainer
          name="last_name"
          label="Last name"
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
        <FormInputContainer
          name="email"
          label="Email address"
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
        <FormPhoneInputContainer
          name="phone"
          label="Phone"
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
        {emailOrPhoneError && (
          <StyledAddContactFormError className="AddContactFormError">
            {emailOrPhoneError}
          </StyledAddContactFormError>
        )}
        <FormSelectContainer
          label="Type"
          name="type"
          options={contactTypeOptions}
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
        <FormInputContainer
          name="company_name"
          label="Company name"
          fullWidth
          onKeyDown={stopPropagationOnKeyDown}
        />
      </StyledAddContactForm>
    </FormProvider>
  );

  return (
    <ActionWithConfirmationWithSelect
      open={addContactOpen}
      anchorEl={addContactAnchorEl}
      onClose={handleAddContactClose}
      confirmText="Add"
      handleConfirm={handleSubmit(saveFormData)}
      handleClose={handleAddContactClose}
      content={addContactForm}
      disabledConfirm={isSubmitting}
    >
      <IconButton
        label="Add"
        iconName="AddIcon"
        iconColor="action"
        onClick={handleAddContactOpen}
      />
    </ActionWithConfirmationWithSelect>
  );
};
