import { createTheme, ThemeOptions } from '@mui/material/styles';
import { merge } from 'lodash';
import {
  defaultTheme as kitTheme,
  KitTheme,
  useTheme as useKitTheme,
} from 'react-ui-kit-exante';

export const caThemeValues = {};

export const theme = createTheme(
  merge(kitTheme, caThemeValues) as ThemeOptions,
) as KitTheme;

export const useTheme = useKitTheme<KitTheme>;
