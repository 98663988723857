import { IColumn, TAdditionalFilter, FilterValue } from 'react-ui-kit-exante';

import {
  useGetAssetTypesQuery,
  useGetExecutionCounterpartyQuery,
  useGetLegalEntityTypesQuery,
  useGetSettlementCounterpartyQuery,
  useGetTradeTypesQuery,
} from '~/api';
import { TTrade } from '~/api/nodeBackApi';
import { createStringDateUTCWithoutTimezone } from '~/utils/dates/createStringDateWithoutTimezone';
import { getPredefinedDateRanges } from '~/utils/dates/getPredefinedDateRanges';
import { threeDaysAgo, today } from '~/utils/dates/variables';
import {
  transformDataToSelectOptions,
  transformVariantsToSelectOptions,
} from '~/utils/forms/transformVariantsToSelectOptions';

import { TGetAdditionalFilters } from './TradeTable.types';

export const getDefaultFilters = () => {
  return {
    fromTo: [
      createStringDateUTCWithoutTimezone(threeDaysAgo),
      createStringDateUTCWithoutTimezone(today),
    ],
  };
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
  defaultFilters,
  accountTypes,
}: TGetAdditionalFilters): TAdditionalFilter<Record<string, unknown>>[] => {
  return [
    {
      Header: 'From/to',
      accessor: 'fromTo',
      type: 'dateTimeRange',
      defaultFilterValue: [defaultFilters.fromDate, defaultFilters.toDate],
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      predefinedDateRanges: getPredefinedDateRanges(onFilter),
    },
    {
      Header: 'Account type',
      accessor: 'accountTypes',
      type: 'multiSelect',
      filterOptions: accountTypes,
      onFilter,
      onRemove,
    },
  ];
};

type TGetColumns = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
};

export const useColumns = ({
  onFilter,
  onRemove,
}: TGetColumns): IColumn<TTrade>[] => {
  const { data: assetTypes } = useGetAssetTypesQuery();
  const { data: executionCounterparty } = useGetExecutionCounterpartyQuery();
  const { data: legalEntity } = useGetLegalEntityTypesQuery();
  const { data: settlementCounterparty } = useGetSettlementCounterpartyQuery();
  const { data: tradeTypes } = useGetTradeTypesQuery();

  return [
    {
      Header: 'User',
      accessor: 'userId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Gw time',
      accessor: 'gwTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Trade time',
      accessor: 'tradeTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Counterparty time',
      accessor: 'counterpartyTime',
      disableFilters: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Counterparty account',
      accessor: 'counterpartyAccountId',
      disableFilters: true,
    },
    {
      Header: 'Counterparty client',
      accessor: 'counterpartyClientId',
      disableFilters: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbolId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol type',
      accessor: 'symbolType',
      type: 'select',
      filterOptions: transformDataToSelectOptions(assetTypes?.values || [], {
        valueKey: 'type',
        labelKey: 'type',
        sort: false,
      }),
      onFilter,
      onRemove,
    },
    {
      Header: 'Account',
      accessor: 'accountId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Side',
      accessor: 'side',
      disableFilters: true,
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Commission',
      accessor: 'commission',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Commission currency',
      accessor: 'commissionCurrency',
      disableFilters: true,
    },
    {
      Header: 'Forced close out',
      accessor: 'isForcedCloseOut',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Order id',
      accessor: 'orderId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Order position',
      accessor: 'orderPos',
      disableFilters: true,
    },
    {
      Header: 'Exchange order id',
      accessor: 'exchangeOrderId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Currency',
      accessor: 'currency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Exec broker',
      accessor: 'execBroker',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Contract multiplier',
      accessor: 'contractMultiplier',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlementCounterparty',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(
        settlementCounterparty?.values || [],
      ),
      onFilter,
      onRemove,
    },
    {
      Header: 'Execution counterparty',
      accessor: 'executionCounterparty',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(
        executionCounterparty?.values || [],
      ),
      onFilter,
      onRemove,
    },
    {
      Header: 'Value date',
      accessor: 'valueDate',
      disableFilters: true,
      formatting: 'dateUTC',
    },
    {
      Header: 'Client comment',
      accessor: 'clientComment',
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Internal comment',
      accessor: 'internalComment',
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Client type',
      accessor: 'clientType',
      disableFilters: true,
    },
    {
      Header: 'Trade type',
      accessor: 'tradeType',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(tradeTypes || []),
      onFilter,
      onRemove,
    },
    {
      Header: 'PNL',
      accessor: 'pnl',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Manual',
      accessor: 'isManual',
      disableFilters: true,
    },
    {
      Header: 'Who',
      accessor: 'who',
      disableFilters: true,
    },
    {
      Header: 'Closed quantity',
      accessor: 'closedQuantity',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Traded volume',
      accessor: 'tradedVolume',
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Legal entity',
      accessor: 'legalEntity',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(legalEntity?.values),
      onFilter,
      onRemove,
    },
    {
      Header: 'Source',
      accessor: 'source',
      onFilter,
      onRemove,
    },
  ];
};
