import { createContext, FC, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TChildren } from 'types/TChildren';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { TParams } from '~/router/router.types';

import { TOnboardingSettingsContext } from './OnboardingSettingsContext.types';

const initialState: TOnboardingSettingsContext = {
  application: null,
};

export const OnboardingSettingsContext =
  createContext<TOnboardingSettingsContext>(initialState);

export const OnboardingSettingsProvider: FC<TChildren> = ({ children }) => {
  const { id } = useParams<TParams>();
  const { application } = useContext(ApplicationContext);

  const methods = useForm({
    defaultValues: {},
  });

  const { reset, watch } = methods;

  watch();

  const setDefaultValues = () => {
    const defaultValues = application?.application?.clients
      ?.map((client, index) => {
        return Object.entries(client).map((item) => {
          if (item[0].includes('date')) {
            const preparedDate = item[1] ? new Date(String(item[1])) : null;

            return { [`${item[0]}${index}`]: preparedDate };
          }

          return { [`${item[0]}${index}`]: item[1] };
        });
      })
      .flat()
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});

    reset(defaultValues);
  };

  useEffect(() => {
    setDefaultValues();
  }, [application]);

  const value = useMemo(
    (): TOnboardingSettingsContext => ({
      application,
    }),
    [id],
  );

  return (
    <OnboardingSettingsContext.Provider value={value}>
      <FormProvider {...methods}>
        <form>{children}</form>
      </FormProvider>
    </OnboardingSettingsContext.Provider>
  );
};
