import { FC, useContext, useMemo } from 'react';
import { IconButton, Loader, Tooltip } from 'react-ui-kit-exante';

import { TSecurity } from '~/api/withdrawals/withdrawals.types';
import { EntrySection } from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { getFormField } from '~/utils/getFormField';

import {
  StyledActionsRow,
  StyledFormField,
} from './WithdrawalSecurityForm.styled';
import { WithdrawalSecurityFormContext } from './context/WithdrawalSecurityFormContext';
import { getWithdrawalSecurityFields } from './context/helpers';

export const WithdrawalSecurityForm: FC = () => {
  const {
    isEdit,
    setIsEdit,
    isCanChange,
    formNotEdited,
    withdrawalSecurity,
    isLoading,
  } = useContext(WithdrawalSecurityFormContext);

  const isEditDisabled = isLoading || !isCanChange;

  const tooltipMessage = useMemo(() => {
    if (!isCanChange) {
      return 'Not enough permissions for withdrawal edit';
    }
    return '';
  }, [isCanChange]);

  const onEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
  };

  return (
    <EntrySection
      title="Security details"
      gap="8px"
      action={
        <StyledActionsRow className="ActionsRow">
          {isEdit ? (
            <>
              <Tooltip
                title={
                  formNotEdited
                    ? 'For save withdrawal please edit some field'
                    : ''
                }
              >
                <IconButton
                  title="Save deposit data"
                  label="Save"
                  type="submit"
                  iconName="SaveIcon"
                  iconColor="action"
                  iconSize={24}
                  disabled={formNotEdited || isLoading}
                />
              </Tooltip>
              <IconButton
                title="close form editing"
                type="button"
                label="Cancel"
                iconName="CloseIcon"
                iconSize={24}
                onClick={onCloseEdit}
              />
            </>
          ) : (
            <Tooltip title={tooltipMessage}>
              <IconButton
                title="Open form editing"
                type="button"
                iconName="EditIcon"
                iconColor="action"
                disabled={isEditDisabled}
                iconSize={24}
                onClick={onEdit}
              />
            </Tooltip>
          )}
        </StyledActionsRow>
      }
    >
      {isLoading && <Loader isInner isCentered size="l" />}
      {!isLoading &&
        getWithdrawalSecurityFields(isCanChange, withdrawalSecurity).map(
          ({
            name,
            title,
            type,
            editable,
            formatter,
            additionalLabel = '',
          }) => {
            const value = withdrawalSecurity?.[name as keyof TSecurity];

            if (!isEdit || !editable) {
              if (!value) {
                return null;
              }
              return (
                <ValueRow
                  key={name}
                  label={title}
                  value={`${
                    formatter ? formatter(value as string) : value
                  } ${additionalLabel}`}
                />
              );
            }

            return (
              <ValueRow
                key={name}
                label={title}
                valueNode={
                  <StyledFormField className="FormField">
                    {getFormField({
                      name,
                      type,
                    })}
                  </StyledFormField>
                }
              />
            );
          },
        )}
    </EntrySection>
  );
};
