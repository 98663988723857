import { blockNonNativeProps, Checkbox } from 'react-ui-kit-exante';

import { styled } from 'theme';

import { TStyledFormCheckboxProps } from './FormCheckboxGroupContainer.types';

export const StyledFormCheckboxGroupContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledFormCheckboxRow = styled('div')`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StyledFormCheckbox = styled(Checkbox, {
  shouldForwardProp: blockNonNativeProps(['noWrap']),
})<TStyledFormCheckboxProps>`
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'inherit')};
`;

export const StyledFormCheckboxMessage = styled('div')`
  color: ${({ theme }) => theme.color.typo.radical};
  font-size: 11px;
`;
