import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TAuditLogsResponse, TGetAuditLogsParams } from './auditLogs.types';
import { AUDIT_LOGS_URL } from './endpoints';

export const auditLogsApi = createApi({
  reducerPath: 'auditLogsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['AuditLogs'],
  endpoints: (builder) => ({
    getAuditLogs: builder.query<TAuditLogsResponse, TGetAuditLogsParams>({
      query: (params) => {
        return {
          url: `${AUDIT_LOGS_URL}/api/v1.2/logs`,
          params,
        };
      },
      providesTags: ['AuditLogs'],
    }),
  }),
});

export const { useLazyGetAuditLogsQuery } = auditLogsApi;
