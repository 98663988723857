export const BOOKMARK_INPUT_DATA_TEST_ID =
  'react-ui-kit__bookmark-input--data-test-id';
export const SAVE_BOOKMARK_BUTTON_DATA_TEST_ID =
  'react-ui-kit__save-bookmark-button--data-test-id';
export const SAVE_AS_NEW_BOOKMARK_BUTTON_DATA_TEST_ID =
  'react-ui-kit__save-as-new-bookmark-button--data-test-id';
export const SHARE_BOOKMARK_BUTTON_DATA_TEST_ID =
  'react-ui-kit__share-bookmark-button--data-test-id';
export const DELETE_BOOKMARK_BUTTON_DATA_TEST_ID =
  'react-ui-kit__delete-bookmark-button--data-test-id';
