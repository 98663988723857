export enum WithdrawalTypesOfActions {
  Hold = 'hold',
  Reject = 'reject',
  Withdraw = 'withdraw',
  MakeActive = 'make-active',
  WithdrawWire = 'withdraw-wire',
  WithdrawCrypto = 'withdraw-crypto',
  WithdrawCard = 'withdraw-card',
  Convert = 'convert',
  Rollback = 'rollback',
}

export enum ConfirmMessages {
  Convert = 'Are you sure want to convert all clients cash positions into EUR?',
  SendFunds = 'By confirming this action you will send funds to the client by wire transfer. This action cannot be cancelled. Do you want to continue?',
  Rollback = 'By confirming this action you will rollback withdrawal operations on client account and cancel "booking-confirm" in withdrawal request. Do you want to continue?',
}
