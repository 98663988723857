import { createTheme, ThemeProvider } from '@mui/material/styles';
import { mergeWith } from 'lodash';
import { FC, useMemo } from 'react';
import {
  defaultTheme,
  ThemeProvider as ReactUiKitThemeProvider,
} from 'react-ui-kit-exante';

import { TChildren } from 'types/TChildren';

export const ComposedThemeProvider: FC<TChildren> = ({ children }) => {
  const brandingTheme = window.CRM_UI_REACT?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return defaultTheme;
    }

    return createTheme(
      mergeWith({}, defaultTheme, brandingTheme, (objValue, srcValue) => {
        if (typeof objValue === 'string' && srcValue === '') {
          return objValue;
        }

        return undefined;
      }),
    );
  }, [brandingTheme]);

  return (
    <ThemeProvider theme={themeValue}>
      <ReactUiKitThemeProvider theme={themeValue}>
        {children}
      </ReactUiKitThemeProvider>
    </ThemeProvider>
  );
};
