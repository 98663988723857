import { createApi } from '@reduxjs/toolkit/query/react';
import { isUndefined, omitBy } from 'lodash';

import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../endpoints';

import {
  TUser,
  TUserClient,
  TUsersParams,
  TUsersResponse,
} from './users.types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryHandler,
  tagTypes: [],
  endpoints: (builder) => ({
    searchByUsers: builder.query<TUsersResponse, TUsersParams>({
      queryFn: async (
        { skip, search, limit = 100, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        if (skip === null) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
            },
          };
        }

        const queryFields = ['username'];
        const response = await fetchWithBaseQuery({
          url: `${NODE_BACK_URL}/api/users/bo/list`,
          params: omitBy(
            {
              fields: queryFields,
              limit,
              skip,
              username: search,
              ...restParams,
            },
            isUndefined,
          ),
          requestOptions: {},
        });

        const users: TUser[] = response.data;

        return {
          data: {
            nextSkip: limit === users.length ? skip + limit : null,
            options: users.map(({ username }) => ({
              label: username,
              value: username,
            })),
          },
        };
      },
    }),

    getCoreUserClients: builder.query<TUserClient[], { userName: string }>({
      query: ({ userName }) => ({
        url: `${NODE_BACK_URL}/api/proxy/core/v2.0/user_client`,
        params: { userId: userName },
        ignoreForbiddenError: true,
      }),
    }),
  }),
});
export const { useLazySearchByUsersQuery, useLazyGetCoreUserClientsQuery } =
  usersApi;
