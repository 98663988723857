import { useContext, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { TParams } from '~/router/router.types';

import { TabsContext } from '../../contexts/TabsContext';

import { mapTabsComponents } from './TabEntry.constants';

export const TabEntry = () => {
  const { page } = useParams<TParams>();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const { currentTab, subTabs } = useContext(TabsContext);

  useEffect(() => {
    if (!page && currentTab) {
      navigate(`${currentTab?.tab?.url}`);
    }

    if (!hash && subTabs?.length) {
      navigate(`${pathname}#${subTabs[0]?.url}`);
    }
  }, [currentTab, pathname, subTabs]);

  const keyEntity = (hash.replace('#', '') ||
    page) as keyof typeof mapTabsComponents;

  const Component = mapTabsComponents?.[keyEntity] || <>Entity not found</>;

  return Component;
};
