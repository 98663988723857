import { isDate } from 'lodash';

import { convertDateToUTC } from './convertDateToUTC';

export function convertDateToISO(value: unknown): string | null {
  if (value && (typeof value === 'string' || isDate(value))) {
    return convertDateToUTC(value).toISOString();
  }

  return null;
}

export function convertDateFieldsToISOFormat<T>(
  params: T,
  dateFields: Partial<keyof T>[],
) {
  const dates = dateFields.reduce((acc, curr) => {
    if (
      params[curr] &&
      (typeof params[curr] === 'string' || isDate(params[curr]))
    ) {
      return {
        ...acc,
        // don't know how say typescript it is string or Date
        [curr]: convertDateToUTC(params[curr] as never).toISOString(),
      };
    }

    return acc;
  }, {});

  return { ...params, ...dates };
}
