import cn from 'classnames';
import { FC } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { ActionList } from '~/components/ActionList';

import {
  StyledEntryScreenHeaderActions,
  StyledEntryScreenHeaderBlockIcon,
  StyledEntryScreenHeaderFunds,
  StyledEntryScreenHeaderTitle,
  StyledEntryScreenHeaderTitleWIthInfo,
  StyledEntryScreenHeaderTitleWrapper,
  StyledEntryScreenHeaderWrapper,
} from './EntryScreenHeader.styled';
import { TEntryScreenHeader } from './EntryScreenHeader.types';

export const EntryScreenHeader: FC<TEntryScreenHeader> = ({
  title,
  actions,
  onClose,
  className,
  isLocked,
  lockedText = 'Locked',
}) => {
  return (
    <StyledEntryScreenHeaderWrapper
      className={cn(['EntryScreenHeaderWrapper', className])}
    >
      <StyledEntryScreenHeaderTitleWIthInfo className="EntryScreenHeaderTitleWIthInfo">
        <StyledEntryScreenHeaderTitleWrapper className="EntryScreenHeaderTitleWrapper">
          <StyledEntryScreenHeaderTitle className="EntryScreenHeaderTitle">
            {title}
          </StyledEntryScreenHeaderTitle>
        </StyledEntryScreenHeaderTitleWrapper>
        {isLocked && (
          <StyledEntryScreenHeaderFunds className="EntryScreenHeaderFunds">
            {lockedText}{' '}
            <StyledEntryScreenHeaderBlockIcon
              iconSize={18}
              iconName="BlockIcon"
              iconColor="inverse"
              className="EntryScreenHeaderBlockIcon"
            />
          </StyledEntryScreenHeaderFunds>
        )}
      </StyledEntryScreenHeaderTitleWIthInfo>
      <StyledEntryScreenHeaderActions className="EntryScreenHeaderActions">
        {actions && actions?.length > 3 ? (
          <>
            {actions?.slice(0, 3).map((action) => action)}
            <ActionList dataTestId="some">
              {actions?.slice(3).map((action) => action)}
            </ActionList>
          </>
        ) : (
          actions?.map((action) => action)
        )}
        {onClose && (
          <IconButton
            type="button"
            iconSize={32}
            iconName="CloseIcon"
            iconColor="promo"
            onClick={onClose}
            className="EntryScreenHeaderClose"
          />
        )}
      </StyledEntryScreenHeaderActions>
    </StyledEntryScreenHeaderWrapper>
  );
};
