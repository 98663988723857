import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { NODE_BACK_URL } from '~/api/nodeBackApi/endpoints';
import { TParams } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';
import { paramsTransformer } from '~/utils/params';

import {
  DEFAULT_TRANSACTIONS_RESPONSE,
  REQUESTED_TRANSACTIONS_FIELDS,
} from './transactions.api.constants';
import { TRANSACTIONS_PARAMS_MAPPER } from './transactions.api.helpers';
import { TTransactionsState } from './transactions.types';

const tagTypes = [
  'Transactions',
  'Transaction',
  'TransactionsForExport',
] as const;

export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery: baseQueryHandler,
  tagTypes,
  endpoints: function tradesApiEndpoints(builder) {
    return {
      getTransactions: builder.query<TTransactionsState, TParams>({
        queryFn: async function getTransactions(
          params,
          _,
          __,
          fetchWithBaseQuery,
        ) {
          const afterPreparing = paramsTransformer({
            params,
            mapper: TRANSACTIONS_PARAMS_MAPPER,
          });
          const { data, error } = await fetchWithBaseQuery({
            params: {
              ...afterPreparing,
              fields: REQUESTED_TRANSACTIONS_FIELDS,
            },
            url: `${NODE_BACK_URL}/api/transactions`,
            isErrorWithDescription: true,
          });
          if (error || !data) {
            Notification.error({
              title: error,
            });

            return {
              data: DEFAULT_TRANSACTIONS_RESPONSE,
            };
          }
          return {
            data: {
              transactions: data.data,
              pagination: data.pagination,
            },
          };
        },
        providesTags: ['Transactions'],
      }),
      updateTransaction: builder.mutation({
        query: ({ uuid, ...data }) => ({
          url: `${NODE_BACK_URL}/api/proxy/core/v2.0/transactions/${uuid}`,
          method: 'POST',
          data,
          isErrorWithDescription: true,
        }),
      }),
      rollbackTransaction: builder.mutation<unknown, number>({
        query: (id) => ({
          url: `${NODE_BACK_URL}/api/proxy/core/v2.1/transactions/rollback/${id}`,
          method: 'POST',
          isErrorWithDescription: true,
        }),
      }),
    };
  },
});

export const {
  useLazyGetTransactionsQuery,
  useUpdateTransactionMutation,
  useRollbackTransactionMutation,
} = transactionsApi;
