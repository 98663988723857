import { DEFAULT_PAGINATION_RESPONSE } from '~/constants/table';

export const REQUESTED_FIELDS = [
  'userId',
  'gwTime',
  'tradeTime',
  'counterpartyTime',
  'counterpartyAccountId',
  'counterpartyClientId',
  'symbolId',
  'symbolType',
  'accountId',
  'side',
  'price',
  'quantity',
  'commission',
  'commissionCurrency',
  'isForcedCloseOut',
  'orderId',
  'orderPos',
  'exchangeOrderId',
  'currency',
  'contractMultiplier',
  'settlementBrokerAccountId',
  'settlementBrokerClientId',
  'settlementCounterparty',
  'executionCounterparty',
  'valueDate',
  'clientComment',
  'internalComment',
  'clientType',
  'tradeType',
  'pnl',
  'isManual',
  'who',
  'closedQuantity',
  'tradedVolume',
  'legalEntity',
  'feePolicy',
  'source',
  'execBroker',
  'chainId',
];

export const DEFAULT_TRADES_RESPONSE = {
  trades: [],
  pagination: DEFAULT_PAGINATION_RESPONSE,
};
