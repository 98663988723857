import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { TTabComponentProps } from '~/types/TTabComponentProps';

import { TabsContext } from '../../contexts/TabsContext/TabsContext';
import { TabEntry } from '../TabEntry';

import { StyledTabs, StyledTab, StyledToggleButtonGroup } from './Tabs.styled';

export const Tabs = () => {
  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  const { tabs, currentTab, subTabs } = useContext(TabsContext);

  const tabsList: ReactNode[] = useMemo<ReactNode[]>(() => {
    return tabs.reduce<ReactNode[]>(
      (tabComponents, { title, url, disabled }) => {
        tabComponents.push(
          <StyledTab<TTabComponentProps<typeof Link>>
            className="Tab"
            label={title}
            key={title}
            component={Link}
            to={url}
            disabled={disabled}
          />,
        );

        return tabComponents;
      },
      [],
    );
  }, [tabs]);

  useEffect(() => {
    const firstTab = tabs[0];
    const firstSubTab = tabs[0]?.subTabs?.[0];
    if (tabs.length && currentTab?.number === -1) {
      if (firstSubTab) {
        navigate(`${firstTab.url}#${firstSubTab.url}`);
      }
      navigate(firstTab.url);
    }
  }, [tabs, currentTab]);

  const tabValue = currentTab?.number || 0;

  const subTabsOptions =
    subTabs?.map((subTab) => {
      return {
        label: subTab.title,
        value: subTab.name,
        disabled: subTab.disabled,
      };
    }) || [];

  const onSubTabChange = (value: string | string[]) => {
    const newTab = subTabs?.find((item) => item.name === value);

    if (newTab?.url) {
      navigate(`${pathname}#${newTab.url}`);
    }
  };

  const subTabValue = hash.replace('#', '');

  return (
    <>
      <StyledTabs
        value={tabValue}
        variant="scrollable"
        scrollButtons={false}
        className="ApplicationTabs"
      >
        {tabsList}
      </StyledTabs>

      {subTabsOptions.length > 0 && (
        <StyledToggleButtonGroup
          fullWidth
          size="small"
          options={subTabsOptions}
          exclusive
          onChange={onSubTabChange}
          value={subTabValue}
        />
      )}

      <TabEntry />
    </>
  );
};
