import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';

export const clearTableFilters = (
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void,
) => {
  setSearchParams('');
};
