import { useContext } from 'react';

import { useGetGlobalContextQuery } from '~/api';
import { EntrySection } from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { getFormField } from '~/utils/getFormField';

import { OnboardingSettingsContext } from '../../context';

import { getClientFormFields } from './ClientForm.helpers';
import { StyledClientFormField } from './ClientForm.styled';

export const ClientForm = () => {
  const { application } = useContext(OnboardingSettingsContext);
  const clients = application?.application?.clients;
  const { data: globalContext } = useGetGlobalContextQuery();

  const allOptions = globalContext?.tags_choices;

  if (clients) {
    return (
      <div>
        {clients?.map((client, index) => {
          const { client_id: clientId } = client;

          return (
            <EntrySection gap="8px" key={clientId} title={clientId}>
              {getClientFormFields(allOptions).map(
                ({ name, title, type, options }) => {
                  return (
                    <ValueRow
                      key={name}
                      label={title}
                      valueNode={
                        <StyledClientFormField className="ClientFormField">
                          {getFormField({
                            type,
                            name: `${name}${index}`,
                            options,
                          })}
                        </StyledClientFormField>
                      }
                    />
                  );
                },
              )}
            </EntrySection>
          );
        })}
      </div>
    );
  }

  return null;
};
