import { FC } from 'react';

import { AppVersion } from '~/components/AppVersion';
import { TChildren } from '~/types/TChildren';

import { Header } from '../Header';

import { StyledContent, StyledViewContainer } from './ViewContainer.styled';

export const ViewContainer: FC<TChildren> = ({ children }) => {
  return (
    <StyledViewContainer className="ViewContainer">
      <Header />
      <StyledContent className="Content">{children}</StyledContent>
      <AppVersion />
    </StyledViewContainer>
  );
};
