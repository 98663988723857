import { ISortBy } from 'react-ui-kit-exante';

import { FROM_TO_DATE_FIELDS } from '~/constants/fields';
import { prepareDateRangeStringForAPI } from '~/utils/dates/prepareDateRangeStringForAPI';
import { prepareSortingForApi } from '~/utils/table/prepareSortingForApi';

export const TRANSACTIONS_PARAMS_MAPPER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  fromTo: {
    parser: (values: (string | null)[]) =>
      prepareDateRangeStringForAPI(FROM_TO_DATE_FIELDS, values),
  },
  who: {
    parser: (value: string | null) => ({
      who: value?.toLowerCase() || null,
    }),
  },
};
