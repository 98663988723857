import { ToggleButtonGroupProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { DefaultThemeProvider } from '../../../theme';
import { Skeleton } from '../../Skeleton';

import {
  FormHelperTextStyled,
  LabelStyled,
  ToggleButtonGroupStyled,
  ToggleButtonSkeletonStyled,
  ToggleButtonStyled,
  ToggleButtonWrapperStyled,
} from './ToggleButtonGroup.styled';
import { IToggleButtonGroupProps } from './ToggleButtonGroup.types';
import { SkeletonHeight } from './constants';

export const ToggleButtonGroup = <Exclusive extends boolean | undefined>({
  disabled = false,
  error = false,
  warning = false,
  exclusive = false,
  label,
  message = '',
  onChange,
  options,
  size = 'medium',
  value: valueProp,
  showSkeleton = false,
  skeletonsCount = 2,
  fullWidth = false,
  children,
  ...props
}: IToggleButtonGroupProps<Exclusive> &
  Omit<ToggleButtonGroupProps, 'onChange' | 'exclusive'>) => {
  const [value, setValue] =
    useState<IToggleButtonGroupProps<Exclusive>['value']>(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = useCallback(
    (
      _: any,
      newValue: NonNullable<IToggleButtonGroupProps<Exclusive>['value']> | null,
    ) => {
      if (newValue === null) {
        return;
      }

      onChange?.(newValue);

      if (valueProp === undefined) {
        setValue(newValue);
      }
    },
    [onChange],
  );

  const skeletonHeight = SkeletonHeight[size];
  const skeletonWidth = fullWidth ? '100%' : 54;
  const computedSkeletonsCount = options?.length || skeletonsCount;

  return (
    <DefaultThemeProvider>
      <ToggleButtonWrapperStyled className="ToggleButtonGroup">
        {label && !showSkeleton && (
          <LabelStyled
            error={error}
            disabled={disabled}
            warning={warning}
            className="ToggleButtonGroupLabel"
          >
            {label}
          </LabelStyled>
        )}

        {showSkeleton ? (
          <ToggleButtonGroupStyled
            disabled={disabled || showSkeleton}
            exclusive={exclusive}
            value={value}
            onChange={handleChange}
            fullWidth={fullWidth}
            {...props}
          >
            {[...Array(computedSkeletonsCount)].map((_, i) => (
              <ToggleButtonSkeletonStyled
                // eslint-disable-next-line react/no-array-index-key
                key={`block_${i}`}
              >
                <Skeleton width={skeletonWidth} height={skeletonHeight} />
              </ToggleButtonSkeletonStyled>
            ))}
          </ToggleButtonGroupStyled>
        ) : (
          <ToggleButtonGroupStyled
            disabled={disabled || showSkeleton}
            exclusive={exclusive}
            value={value}
            onChange={handleChange}
            fullWidth={fullWidth}
            {...props}
          >
            {children ??
              options?.map(
                ({ value: optionValue, label: optionLabel, ...optionRest }) => (
                  <ToggleButtonStyled
                    key={optionValue}
                    error={error}
                    warning={warning}
                    value={optionValue}
                    size={size}
                    disableRipple
                    {...optionRest}
                  >
                    {optionLabel}
                  </ToggleButtonStyled>
                ),
              )}
            <FormHelperTextStyled error={error}>{message}</FormHelperTextStyled>
          </ToggleButtonGroupStyled>
        )}
      </ToggleButtonWrapperStyled>
    </DefaultThemeProvider>
  );
};
