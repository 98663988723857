import { FC, MouseEvent } from 'react';
import { Button } from 'react-ui-kit-exante';

import { useLazyDownloadMonitoringFileQuery } from '~/api';

import { StyledLinksCell } from './LinksCell.styled';
import { TLinksCellProps } from './LinksCell.types';

export const LinksCell: FC<TLinksCellProps> = ({ links }) => {
  const [onDownloadFile] = useLazyDownloadMonitoringFileQuery();

  const getFile = async (id: string) => {
    const { data: dataUrl } = await onDownloadFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleClick = (event: MouseEvent, id: string | number) => {
    event.stopPropagation();
    getFile(id.toString());
  };

  return (
    <StyledLinksCell className="RequestsLinksCell">
      {links.map((link, index) => (
        <li key={index}>
          <Button
            className="RequestsLinksCellLink"
            color="transparent"
            textColor="action"
            onClick={(event) => handleClick(event, link.id)}
          >
            {link.name}
          </Button>
        </li>
      ))}
    </StyledLinksCell>
  );
};
