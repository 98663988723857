import { IRow, KitTheme } from 'react-ui-kit-exante';

import { TRequest } from '~/api/requests/requests.types';
import { colorsMap } from '~/constants/statusesInfoMap';

export function getRowProps(row: IRow<TRequest>, theme: KitTheme) {
  const status = row.original.state;
  return {
    style: {
      backgroundColor: (status && colorsMap(theme).requestsTable[status]) || '',
    },
  };
}
