import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { getTypeEndpoint } from './types.api.endpoints';
import { TTypesResponse, TTypesWithDefaultResponse } from './types.api.types';

type RequestParams = { onError?: (error: Error) => void };

export const typesApi = createApi({
  reducerPath: 'typesApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getTradeTypes: builder.query<string[], RequestParams | void>({
      query: ({ onError } = {}) => ({ url: getTypeEndpoint('trade'), onError }),
      transformResponse: (response) => response.values,
    }),

    getLegalEntityTypes: builder.query<
      TTypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('legalEntity'),
        onError,
      }),
    }),

    getAssetTypes: builder.query<any, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('asset'),
        onError,
      }),
    }),

    getSettlementCounterparty: builder.query<{ values: string[] }, void>({
      query: () => ({
        url: getTypeEndpoint('settlementCounterparty'),
      }),
    }),

    getExecutionCounterparty: builder.query<{ values: string[] }, void>({
      query: () => ({
        url: getTypeEndpoint('executionCounterparty'),
      }),
    }),

    getAccountTypes: builder.query<TTypesResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('account'),
        onError,
      }),
    }),

    getAccountStatusTypes: builder.query<
      TTypesWithDefaultResponse,
      RequestParams | void
    >({
      query: () => ({
        url: getTypeEndpoint('accountStatus'),
      }),
    }),

    getCategoriesTypes: builder.query<TTypesResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('categories'),
        onError,
      }),
    }),

    getOperationTypes: builder.query<
      TTypesResponse,
      | (RequestParams & {
          isAutoConversionAllowed?: boolean;
          withSystemOnly?: boolean;
        })
      | void
    >({
      query: ({
        onError,
        isAutoConversionAllowed,
        withSystemOnly = true,
      } = {}) => ({
        url: getTypeEndpoint('operation'),
        onError,
        params: { withSystemOnly, isAutoConversionAllowed },
      }),
    }),
  }),
});

export const {
  useGetAssetTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetTradeTypesQuery,
  useGetSettlementCounterpartyQuery,
  useGetExecutionCounterpartyQuery,
  useGetAccountTypesQuery,
  useGetAccountStatusTypesQuery,
  useGetCategoriesTypesQuery,
  useGetOperationTypesQuery,
} = typesApi;
