import { Button, styled } from 'react-ui-kit-exante';

export const StyledButtonClientCard = styled(Button)`
  text-transform: none;
  font-weight: bold;
`;

export const StyledActionsRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledWithdrawalFormViewLink = styled('a')`
  color: ${({ theme }) => theme?.color.typo.action};

  &:hover,
  &:visited {
    color: ${({ theme }) => theme?.color.typo.promo};
  }
`;
