import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TManagers } from './managers.types';

export const managersApi = createApi({
  reducerPath: 'managersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['managers'],
  endpoints: (builder) => ({
    getManagers: builder.query<TManagers[], { param: string }>({
      query: ({ param }) => ({
        url: `/rest/manager/${param}`,
      }),
    }),
  }),
});

export const { useGetManagersQuery } = managersApi;
