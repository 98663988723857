import { FC } from 'react';

import { formatPhoneNumberWithFlag } from '~/components/phoneWithFlag/phoneWithFlag';

import {
  StyledContactInfoCell,
  StyledContactInfoCellName,
  StyledContactInfoCellPhone,
} from './ContactInfoCell.styled';
import { TContactInfoCellProps } from './ContactInfoCell.types';

export const ContactInfoCell: FC<TContactInfoCellProps> = ({
  name,
  email,
  phone,
}) => {
  const formattedPhone = formatPhoneNumberWithFlag(String(phone));
  return (
    <StyledContactInfoCell className="ContactInfoCell">
      <StyledContactInfoCellName className="ContactInfoCell">
        {name}
      </StyledContactInfoCellName>
      <p>{email}</p>
      <StyledContactInfoCellPhone className="ContactInfoCellPhone">
        {formattedPhone}
      </StyledContactInfoCellPhone>
    </StyledContactInfoCell>
  );
};
