import { ISortBy } from 'react-ui-kit-exante';

export function prepareSortingForApi(sortingArray: ISortBy[]) {
  return sortingArray.reduce((acc, curr) => {
    if (curr.desc === undefined) {
      return acc;
    }

    const direction = curr?.desc ? 'desc' : 'asc';

    return { order: direction, orderBy: curr.id };
  }, {});
}
