import { yupResolver } from '@hookform/resolvers/yup';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Loader,
  Notification,
  FileUpload,
} from 'react-ui-kit-exante';

import {
  useGetImportedContactsQuery,
  useGetPermissionsQuery,
  useImportTagMutation,
} from '~/api';
import { TImportedContactsResponse } from '~/api/contacts/contacts.types';
import { FormFileUploadContainer } from '~/components/Form/FormFileUploadContainer/FormFileUploadContainer';
import { FormSelectContainer } from '~/components/Form/FormSelectContainer/FormSelectContainer';
import { PATHS } from '~/router';

import { EImportedContactsState } from '../ContactsImportEntry';

import {
  ACCEPTED_FORMATS,
  IMPORT_MODE_OPTIONS,
  IMPORT_TAG_FORM_DEFAULT_VALUES,
  IMPORT_TAG_FORM_VALIDATION_SCHEMA,
  SEARCH_FIELD_OPTIONS,
} from './ImportTag.constants';
import { updateFileToLastItem } from './ImportTag.helpers';
import {
  StyledImportTagActions,
  StyledImportTagErrors,
  StyledImportTagForm,
  StyledImportTagFormLayout,
  StyledImportTagPanel,
  StyledImportTagParagraph,
  StyledImportTagProgress,
  StyledImportTagWarning,
} from './ImportTag.styled';
import { TImportTagForm } from './ImportTag.types';
import { TagsSelect } from './components';

export const ImportTag: FC = () => {
  const navigate = useNavigate();

  const [taskId, setTaskId] = useState('');
  const [progressText, setProgressText] = useState('');
  const [importFinished, setImportFinished] = useState(false);
  const [errors, setErrors] = useState<ReactNode[]>([]);

  const [onImportTag] = useImportTagMutation();

  const { data: importedTagData, refetch } = useGetImportedContactsQuery(
    taskId,
    {
      skip: !taskId,
    },
  );

  const { data: permissions } = useGetPermissionsQuery();

  const hasPermissionToImportTag = permissions?.tags.tags_can_import_tags;

  const defaultValues = IMPORT_TAG_FORM_DEFAULT_VALUES;

  const methods = useForm<TImportTagForm>({
    defaultValues,
    resolver: yupResolver(IMPORT_TAG_FORM_VALIDATION_SCHEMA),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { isSubmitting },
    reset,
  } = methods;

  const saveDisabled = isSubmitting || !hasPermissionToImportTag;

  const formDisabled = Boolean(taskId || saveDisabled);

  const importTag = async () => {
    setErrors([]);
    setImportFinished(false);

    const formData = updateFileToLastItem(getValues());

    const uploaded = await onImportTag(formData);

    if (!('error' in uploaded)) {
      const importTaskId = uploaded.data.task_id;

      if (importTaskId) {
        setTaskId(importTaskId);

        setProgressText('Importing...');
      }
    }
  };

  const validateImportedFile = (data: TImportedContactsResponse) => {
    const { state, meta } = data;

    if (state === EImportedContactsState.progress) {
      setProgressText(`Importing...`);
    } else if (
      state === EImportedContactsState.failure ||
      state === EImportedContactsState.revoked
    ) {
      Notification.error({
        title: `Import failed: ${meta || 'Unknown error'}`,
      });

      setError(
        'file',
        {
          type: 'customError',
          message: `Import failed: ${meta || 'Unknown error'}`,
        },
        { shouldFocus: false },
      );

      setProgressText('');

      setImportFinished(true);
      setTaskId('');
    } else if (state === EImportedContactsState.success) {
      const validationErrors = meta.errors;

      if (validationErrors && Object.keys(validationErrors).length > 0) {
        const errorMessages = Object.entries(validationErrors).map(
          ([row, rowErrors]) => (
            <span key={row}>
              <strong>Row {row}:</strong> {(rowErrors as string[]).join(', ')}
            </span>
          ),
        );

        setErrors(errorMessages);

        setImportFinished(true);
        setTaskId('');

        Notification.error({
          title: 'Import finished with errors',
        });

        setProgressText(
          `Imported ${meta.imported} of ${meta.total}. Import finished with the following errors:`,
        );
      } else {
        reset(defaultValues);

        setValue('file', []);

        setImportFinished(true);
        setTaskId('');

        Notification.success({
          title: `Import finished successfully (Added ${meta.imported} of ${meta.total})`,
        });

        setProgressText('');
      }
    }
  };

  useEffect(() => {
    if (!taskId || importFinished) {
      return;
    }

    const interval = setInterval(refetch, 5000);

    // TODO fix this rule
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [taskId, refetch, importFinished]);

  useEffect(() => {
    if (importedTagData) {
      validateImportedFile(importedTagData);
    }
  }, [importedTagData]);

  const handleClose = () => {
    navigate(PATHS.CONTACTS);
  };

  return (
    <FormProvider {...methods}>
      <StyledImportTagPanel
        title="Import tag"
        action={
          <StyledImportTagActions className="ImportTagActions">
            <IconButton
              label="Start import"
              iconName="SaveIcon"
              iconColor="action"
              onClick={handleSubmit(importTag)}
              disabled={saveDisabled}
            />
            <IconButton
              title="Close import tag"
              iconName="CloseIcon"
              iconColor="secondary"
              onClick={handleClose}
            />
          </StyledImportTagActions>
        }
      >
        <StyledImportTagForm className="ImportTagForm">
          <StyledImportTagFormLayout>
            <TagsSelect name="tagName" disabled={formDisabled} />
            <FormSelectContainer
              label="Search field"
              name="filterField"
              options={SEARCH_FIELD_OPTIONS}
              disabled={formDisabled}
            />
            <FormSelectContainer
              label="Import mode"
              name="importMode"
              options={IMPORT_MODE_OPTIONS}
              disabled={formDisabled}
            />
          </StyledImportTagFormLayout>

          <StyledImportTagParagraph className="ImportTagParagraph">
            Support only xlsx format file{' '}
          </StyledImportTagParagraph>
          <StyledImportTagParagraph className="ImportTagParagraph">
            File should contain 2 columns without column title: Application id
            or username | Tag value
          </StyledImportTagParagraph>

          {!taskId && (
            <FormFileUploadContainer
              name="file"
              accept={ACCEPTED_FORMATS}
              showFileSize={false}
              removable
              className="ImportTagFileUpload"
              disabled={formDisabled}
            />
          )}

          {/* todo file upload bug - fix for not clearing FileUpload state */}
          {taskId && (
            <FileUpload className="ImportTagFileUpload" loading={!!taskId} />
          )}

          {!hasPermissionToImportTag && (
            <StyledImportTagWarning className="ImportTagWarning">
              You don&apos;t have permission to import tags
            </StyledImportTagWarning>
          )}

          {!!progressText && (
            <StyledImportTagProgress className="ImportTagProgress">
              {!importFinished && <Loader size={16} />} {progressText}
            </StyledImportTagProgress>
          )}

          {errors.length > 0 && (
            <StyledImportTagErrors className="ImportTagErrors">
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </StyledImportTagErrors>
          )}
        </StyledImportTagForm>
      </StyledImportTagPanel>
    </FormProvider>
  );
};
