import { initApp } from './initApp';
import { APP_INSTANCE_WINDOW_CONFIG_NAME } from './utils/getCrmInstanceName';

window[APP_INSTANCE_WINDOW_CONFIG_NAME] = {
  ...(window[APP_INSTANCE_WINDOW_CONFIG_NAME] || {}),
  render: (node: Element) => initApp(node),
};
// is rendered not inside run-ui
if (!window.runUIhistoryContainer) {
  initApp();
}
