import { UploadStatus } from 'react-ui-kit-exante';

import { TFile, TRequest } from '~/api/requests/requests.types';
import { TMonitoringTags } from '~/api/tags/tags.types';
import { FieldTypes } from '~/constants/common';
import { RequestType } from '~/pages/RequestEntry/RequestEntry.constants';
import {
  TDynamicFields,
  TOptions,
  TOptionsForm,
  TStates,
} from '~/pages/RequestEntry/RequestEntry.types';

export const getRequestFields = ({
  optionsForm,
  isProcessing,
}: {
  optionsForm: TOptionsForm;
  isProcessing: boolean;
}): TDynamicFields[] => {
  return [
    {
      title: 'Application',
      name: 'username',
      editable: false,
    },
    {
      title: 'Request type',
      name: 'type',
      editable: false,
    },
    {
      title: 'Received from',
      name: 'received_from',
      editable: true,
      isMultiple: true,
      disabled: isProcessing,
      type: FieldTypes.Autocomplete,
      options: optionsForm?.receivedFrom,
    },
    {
      title: 'Assigned/Sent to',
      name: 'assigned_to',
      editable: true,
      isMultiple: true,
      disabled: isProcessing,
      type: FieldTypes.Autocomplete,
      options: optionsForm?.assigned,
    },
    {
      title: 'Legal Entities',
      name: 'legal_entities',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Select,
      options: optionsForm?.legalEntities,
    },
    {
      title: 'Deadline',
      name: 'deadline',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.DateField,
    },
    {
      title: 'State',
      name: 'state_obj_id',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Select,
      options: optionsForm?.stateOptions,
    },
    {
      title: 'Files',
      name: 'files',
      editable: true,
      type: FieldTypes.File,
      isLoading: isProcessing,
    },
    {
      title: 'Subject',
      name: 'subject',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Textarea,
    },
    {
      title: 'Summary',
      name: 'summary',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Textarea,
    },
  ];
};

export const getAdditionalInvestigationCaseFields = ({
  optionsForm,
  isProcessing,
}: {
  optionsForm: TOptionsForm;
  isProcessing: boolean;
}): TDynamicFields[] => {
  return [
    {
      title: 'Access level',
      name: 'access_level',
      editable: true,
      isMultiple: true,
      disabled: isProcessing,
      type: FieldTypes.Autocomplete,
      options: optionsForm?.receivedFrom,
    },
    {
      title: 'Date of opening',
      name: 'tag_monitoring / date of opening',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.DateField,
    },
    {
      title: 'Date of source',
      name: 'tag_monitoring / date of source',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.DateField,
    },
    {
      title: 'Priority',
      name: 'tag_monitoring / priority',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Select,
      options: optionsForm.priority,
    },
    {
      title: 'Related case',
      name: 'tag_monitoring / related case',
      editable: true,
      disabled: isProcessing,
    },
    {
      title: 'Source',
      name: 'tag_monitoring / source',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Select,
      options: optionsForm?.source,
    },
    {
      title: 'Source other',
      name: 'tag_monitoring / source other',
      editable: true,
      disabled: isProcessing,
    },
  ];
};

export const getAdditionalSARSTRFields = ({
  optionsForm,
  isProcessing,
}: {
  optionsForm: TOptionsForm;
  isProcessing: boolean;
}): TDynamicFields[] => {
  return [
    ...getAdditionalInvestigationCaseFields({ optionsForm, isProcessing }),
    {
      title: 'Local MLRO',
      name: 'tag_monitoring / local mlro',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Select,
      options: optionsForm.localMRLO,
    },
  ];
};

export const getCheckboxesFields = ({
  isProcessing,
}: {
  isProcessing: boolean;
}) => {
  return [
    {
      title: 'Non-compliance fee',
      name: 'non_compliance_fee',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Checkbox,
    },
    {
      title: '1-time extension',
      name: 'one_time_extension',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Checkbox,
    },
    {
      title: 'Close only mode',
      name: 'close_only_mode',
      editable: true,
      disabled: isProcessing,
      type: FieldTypes.Checkbox,
    },
  ];
};

export const getDefaultFormValues = (
  request: TRequest | undefined,
  fields: TDynamicFields[],
): Record<string, unknown> => {
  return fields.reduce((acc, curr) => {
    const key = curr.name;
    const type = curr.type;
    if (!curr.editable) {
      return { ...acc };
    }

    const value = request && request[key as keyof TRequest];

    if (key === 'type') {
      return {
        ...acc,
        [curr.name]: (value as { id: string })?.id,
      };
    }

    if (type === FieldTypes.Autocomplete) {
      return {
        ...acc,
        [curr.name]: (value as { id?: string }[])?.map(
          (item: { id?: string }) => item?.id,
        ),
      };
    }

    if (type === FieldTypes.DateField) {
      return {
        ...acc,
        [curr.name]: value ? new Date(value as string) : null,
      };
    }

    if (type === FieldTypes.File) {
      const filesInitial = (value as TFile[])?.map((file: TFile) => ({
        ...file,
        name: file.file_name,
        id: String(file.id),
        removable: true,
        size: 0,
        uploadStatus: UploadStatus.SUCCESS,
      }));

      return {
        ...acc,
        [curr.name]: filesInitial,
      };
    }

    return {
      ...acc,
      [curr.name]: value,
    };
  }, {});
};

export const prepareOptions = (data: Record<string, string>[] | undefined) =>
  data?.map((item) => ({
    value: item?.id,
    label: item?.text,
  })) || [];

export const priorityAndSourceOptions = (
  data: TMonitoringTags[] | undefined,
  name: string,
) =>
  data
    ?.filter((item) => item?.name === 'monitoring')[0]
    ?.tags?.filter((tag) => tag?.name === name)[0]
    ?.choices?.split(',')
    ?.map((choice) => ({ value: choice, label: choice })) || [];

export const prepareStatesOptions = (
  data: TStates[] | undefined,
  type: string | undefined,
) =>
  data
    ?.map((item) => {
      return item?.record_types
        ?.map((itemState) => {
          if (itemState?.id === type) {
            return { value: item?.id, label: item?.visible_title };
          }
          return null;
        })
        .filter(Boolean);
    })
    .filter((item) => item?.length)
    .flat() || [];

export const prepareMultiselectField = (
  data: TOptions[],
  optionsValue: string[],
) =>
  data
    ?.map((item) => {
      if (optionsValue?.includes(item?.value)) {
        return { id: item?.value, text: item?.label };
      }
      return null;
    })
    .filter(Boolean);

export const prepareFields = (
  requestTypeId: string | undefined,
  optionsForm: TOptionsForm,
  stateOptions: TOptions[] | undefined,
  isProcessing: boolean,
) => {
  if (requestTypeId === RequestType.SarSTR) {
    return [
      ...getRequestFields({
        optionsForm: { ...optionsForm, stateOptions },
        isProcessing,
      }),
      ...getAdditionalSARSTRFields({ optionsForm, isProcessing }),
    ];
  }
  if (requestTypeId === RequestType.InvestigativeCase) {
    return [
      ...getRequestFields({
        optionsForm: { ...optionsForm, stateOptions },
        isProcessing,
      }),
      ...getAdditionalInvestigationCaseFields({ optionsForm, isProcessing }),
    ];
  }
  if (requestTypeId === RequestType.RequestToClient) {
    return [
      ...getRequestFields({
        optionsForm: { ...optionsForm, stateOptions },
        isProcessing,
      }),
      ...getCheckboxesFields({ isProcessing }),
    ];
  }

  return getRequestFields({
    optionsForm: { ...optionsForm, stateOptions },
    isProcessing,
  });
};
