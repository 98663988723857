import { combineReducers } from '@reduxjs/toolkit';

import {
  depositsApi,
  permissionsApi,
  personalSettingsApi,
  bookmarksApi,
  requestsApi,
  dataChangeRequestsApi,
  withdrawalsApi,
  commentsApi,
  globalContextApi,
  contactsApi,
  tagsApi,
  userManagementApi,
  adminChoicesApi,
  applicationLegalEntitiesApi,
  reportsApi,
  notificationsApi,
  applicationsApi,
  auditLogsApi,
  accountsApi,
  assetsApi,
  notificationsNodeBackApi,
  symbolsApi,
  usersApi,
  tradesApi,
  typesApi,
  accountPermissionsApi,
  accessRightsApi,
  computedUsersApi,
  transactionsApi,
  bookmarksBOApi,
  generalUsersApi,
  managersApi,
} from '~/api';

export const rootReducer = combineReducers({
  [depositsApi.reducerPath]: depositsApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [personalSettingsApi.reducerPath]: personalSettingsApi.reducer,
  [bookmarksApi.reducerPath]: bookmarksApi.reducer,
  [requestsApi.reducerPath]: requestsApi.reducer,
  [dataChangeRequestsApi.reducerPath]: dataChangeRequestsApi.reducer,
  [withdrawalsApi.reducerPath]: withdrawalsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [globalContextApi.reducerPath]: globalContextApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [userManagementApi.reducerPath]: userManagementApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [applicationsApi.reducerPath]: applicationsApi.reducer,
  [auditLogsApi.reducerPath]: auditLogsApi.reducer,
  [adminChoicesApi.reducerPath]: adminChoicesApi.reducer,
  [applicationLegalEntitiesApi.reducerPath]:
    applicationLegalEntitiesApi.reducer,
  [accountsApi.reducerPath]: accountsApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [notificationsNodeBackApi.reducerPath]: notificationsNodeBackApi.reducer,
  [symbolsApi.reducerPath]: symbolsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [tradesApi.reducerPath]: tradesApi.reducer,
  [typesApi.reducerPath]: typesApi.reducer,
  [accountPermissionsApi.reducerPath]: accountPermissionsApi.reducer,
  [accessRightsApi.reducerPath]: accessRightsApi.reducer,
  [computedUsersApi.reducerPath]: computedUsersApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [bookmarksBOApi.reducerPath]: bookmarksBOApi.reducer,
  [generalUsersApi.reducerPath]: generalUsersApi.reducer,
  [managersApi.reducerPath]: managersApi.reducer,
});
