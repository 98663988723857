import { FC, MouseEvent, useState } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { useCopyToClipboard } from 'hooks';

import { StyledCopyButtonWrapper } from './CopyButton.styled';
import { TCopyButtonProps } from './CopyButton.types';

export const CopyButton: FC<TCopyButtonProps> = ({ text, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const [, copy] = useCopyToClipboard();

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await copy(typeof text === 'string' ? text : text());
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <StyledCopyButtonWrapper className="CopyButtonWrapper">
      <Tooltip
        // this one is needed to prevent tooltip from closing
        // when focus is changed programmatically by calling `copy()`
        disableFocusListener
        onClose={handleTooltipClose}
        title="Copied"
        open={open}
      >
        <IconButton
          {...buttonProps}
          onClick={handleClick}
          aria-label="Click to copy"
          iconName="CopyIcon"
          iconSize={24}
          iconColor="secondary"
        />
      </Tooltip>
    </StyledCopyButtonWrapper>
  );
};
