import { Menu } from '@mui/material';
import { styled } from 'react-ui-kit-exante';

export const StyledConfirmationWithSelectWrapper = styled(Menu)`
  .MuiMenu-paper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${({ theme }) => theme?.color?.modal.bg};
    box-shadow: ${({ theme }) => theme.effect.controls.drop};
  }
`;

export const StyledConfirmationWithSelectTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledConfirmationWithSelectDescription = styled('div')`
  width: 330px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.typo.promo};
  font-family: ${({ theme }) => theme.font.main};
`;

export const StyledConfirmationWithSelectActions = styled('div')`
  display: flex;
  gap: 24px;
  margin: 16px 0 0;
`;
