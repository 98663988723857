import { TBookmarkAPI } from '~/api/bookmarks/bookmarks.types';
import { TFilter } from '~/api/personalSettings/personalSettings.types';

import { TBookmarks } from '../../types';

import { TBookmarkInitialProps } from './types';

export const getDefaultBookmark = (
  pageName: string,
): TBookmarkInitialProps => ({
  id: null,
  name: `All ${pageName}`,
  table: '',
  filters: {},
  columns: [],
});

export function bookmarkMapper(bookmark: TBookmarkAPI): TBookmarks {
  return {
    id: bookmark.innerId,
    name: bookmark.text,
    table: bookmark.bookmarkTab,
    columns: bookmark.columns
      .filter(({ checked }) => checked)
      .map((col) => col.id),
    filters: bookmark.filters.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value,
      }),
      {},
    ),
  };
}

const excludedFilters = new Set(['bookmark']);

export const transformFilters = (
  filters: Record<string, unknown>,
): TFilter[] => {
  return Object.entries(filters).flatMap(([name, value]) => {
    if (excludedFilters.has(name)) {
      return [];
    }

    return {
      name,
      value,
    };
  });
};
