import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TPersonalSettingsResponse,
  TPersonalSettingsUpdateParams,
} from './personalSettings.types';

export const personalSettingsApi = createApi({
  reducerPath: 'personalSettingsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['PersonalSettings'],
  endpoints: (builder) => ({
    getPersonalSettings: builder.query<TPersonalSettingsResponse, void>({
      query: () => ({
        url: '/rest/personal-settings/me/',
      }),
      providesTags: ['PersonalSettings'],
    }),

    updatePersonalSettings: builder.mutation<
      TPersonalSettingsResponse,
      TPersonalSettingsUpdateParams
    >({
      query: ({ id, personal_settings }) => ({
        url: `/rest/personal-settings/${id}/`,
        method: 'PUT',
        data: {
          id,
          personal_settings,
        },
      }),
      invalidatesTags: ['PersonalSettings'],
    }),
  }),
});

export const {
  useGetPersonalSettingsQuery,
  useUpdatePersonalSettingsMutation,
} = personalSettingsApi;
