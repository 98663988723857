import { styled } from 'react-ui-kit-exante';

export const StyledClientFormField = styled('div')`
  min-width: 270px;

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;
