import { FC, useEffect } from 'react';

import { useLazyGetApplicationTagsQuery } from '~/api';
import { TSection } from '~/api/applications/applications.types';

import { DynamicSectionKey } from '../../../ApplicationEntry.constants';
import { SectionBody } from '../SectionBody';

import { StyledSection, StyledTitle } from './Section.styled';

export type TSectionProps = {
  section: TSection;
};

export const Section: FC<TSectionProps> = ({ section }) => {
  const [getApplicationTagsQuery, state] = useLazyGetApplicationTagsQuery();

  const getTags = (name?: string) => {
    getApplicationTagsQuery({
      objectId: 729757, // todo get from context
      sectionName: name || '',
      // le: '' // todo get from context
    });
  };

  useEffect(() => {
    const sectionData = section?.children?.[0];
    if (sectionData?.resourcetype === DynamicSectionKey) {
      getTags(sectionData?.name);
    }
  }, [section]);

  return (
    <StyledSection className="Section">
      <StyledTitle className="StyledTitle">{section.title}</StyledTitle>
      {state.data && <SectionBody data={state.data} />}
    </StyledSection>
  );
};
