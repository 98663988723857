import { createContext, FC, useMemo, useState } from 'react';

import { TChildren } from 'types/TChildren';

import { TUserSettingsContext } from './UserSettingsContext.types';

const initialState: TUserSettingsContext = {
  isDirtyTab: false,
  setIsDirtyTab: () => {},
};

export const UserSettingsContext =
  createContext<TUserSettingsContext>(initialState);

export const UserSettingsProvider: FC<TChildren> = ({ children }) => {
  const [isDirtyTab, setIsDirtyTab] = useState(false);

  const value = useMemo<TUserSettingsContext>(
    () => ({
      isDirtyTab,
      setIsDirtyTab,
    }),
    [isDirtyTab],
  );

  return (
    <UserSettingsContext.Provider value={value}>
      {children}
    </UserSettingsContext.Provider>
  );
};
