import { isArray } from 'lodash';

const invalidDateTimeFormatLength = 4;

export const formatDateRange = <T>(dateRange: T): T | [string, string] => {
  const isInvalidFormat =
    isArray(dateRange) && dateRange.length === invalidDateTimeFormatLength;

  if (isInvalidFormat) {
    const [beginDate, beginTime, endDate, endTime] = dateRange;

    return [`${beginDate}, ${beginTime}`, `${endDate}, ${endTime}`];
  }

  return dateRange;
};
