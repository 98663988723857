import { ApplicationEntry } from './ApplicationEntry';
import { ApplicationProvider } from './contexts/ApplicationContext';

export const ApplicationEntryContainer = () => {
  return (
    <ApplicationProvider>
      <ApplicationEntry />
    </ApplicationProvider>
  );
};
