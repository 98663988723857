import { createApi } from '@reduxjs/toolkit/query/react';

import { TDefaultParams } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../endpoints';

import { TNotificationsResponse } from './notifications.types';

export const notificationsNodeBackApi = createApi({
  reducerPath: 'notificationsNodeBackApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getNotificationsNodeBack: builder.query<
      TNotificationsResponse,
      TDefaultParams
    >({
      query: (params) => {
        return {
          url: `${NODE_BACK_URL}/api/notifications`,
          params,
        };
      },
    }),
  }),
});

export const { useLazyGetNotificationsNodeBackQuery } =
  notificationsNodeBackApi;
