import cn from 'classnames';
import { FC } from 'react';

import { TChildren } from '~/types/TChildren';

import { StyledEntryPageContainer } from './EntryPage.styled';
import { TEntryPageProps } from './EntryPage.types';

export const EntryPage: FC<TEntryPageProps & TChildren> = ({
  columnsCount = 2,
  children,
  className,
}) => {
  return (
    <StyledEntryPageContainer
      className={cn(['EntryPageContainer', className])}
      columnsCount={columnsCount}
    >
      {children}
    </StyledEntryPageContainer>
  );
};
