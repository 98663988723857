import { ISelectOption } from 'react-ui-kit-exante';
import { ValidationError, number, object, string } from 'yup';

import { EContactType } from './AddContact.types';

export const ADD_CONTACT_FORM_VALIDATION_SCHEMA = object()
  .shape({
    first_name: string().required('First name is a required field'),
    last_name: string().required('Last name is a required field'),
    email: string().email('Invalid email format').notRequired(),
    phone: string().notRequired(),
    type: number().nullable(true).notRequired(),
    company_name: string().notRequired(),
  })
  .test('email_or_phone', 'Either Email or Phone must be provided', (obj) => {
    const { email, phone } = obj;
    if ((email && email.length > 0) || (phone && phone.length > 0)) {
      return true;
    }
    return new ValidationError(
      'Either Email or Phone must be provided',
      undefined,
      'email_or_phone',
    );
  });

export const contactTypeOptions: ISelectOption[] = [
  { label: EContactType.individual, value: 1 },
  { label: EContactType.corporate, value: 0 },
];

export const ADD_CONTACT_FORM_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  type: null,
  company_name: '',
};
