import { useState } from 'react';
import { IconButton, Loader, Notification } from 'react-ui-kit-exante';

import { apiRequest } from '~/utils/apiRequest';

import { statuses } from './DownloadButton.constants';
import { StyledDownloadButton } from './DownloadButton.styled';
import { TDownloadButton } from './DownloadButton.types';

export const DownloadButton = ({ link, title, fileName }: TDownloadButton) => {
  const [status, setStatus] = useState(statuses.pending);

  const download = async () => {
    setStatus(statuses.loading);

    try {
      const { data: blob } = await apiRequest<Blob>(
        {
          url: link,
        },
        { responseType: 'blob' },
      );

      const linkElement = document.createElement('a');
      linkElement.href = window.URL.createObjectURL(blob);
      linkElement.setAttribute('download', fileName);
      document.body.appendChild(linkElement);
      linkElement.click();
      document.body.removeChild(linkElement);
      setStatus(statuses.completed);
    } catch (err) {
      Notification.error({ title: String(err) });
      setStatus(statuses.failed);
    }
  };

  return (
    <StyledDownloadButton className="DownloadButton">
      {status === statuses.loading ? (
        <Loader />
      ) : (
        <IconButton title={title} onClick={download} iconName="DownloadIcon" />
      )}
    </StyledDownloadButton>
  );
};
