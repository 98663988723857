import { FC, useContext } from 'react';

import { TSection } from '~/api/applications/applications.types';

import {
  DynamicSectionKey,
  StaticSectionKey,
} from '../../ApplicationEntry.constants';
import { Section } from '../../components/EnrtyScreenStructure/Section';
import { ApplicationFormProvider } from '../../contexts/FormContext';
import { TabsContext } from '../../contexts/TabsContext';
import { StyledColumn, StyledColumns } from '../TabEntry/TabEntry.styled';

export const QualifiedLeadTab: FC = () => {
  const { currentTab } = useContext(TabsContext);

  const tab = currentTab?.tab;

  if (!tab) {
    return null;
  }

  const positionSections = tab.children?.filter((item) => item.position);

  const columns = [positionSections?.[0], positionSections?.[1]].filter(
    Boolean,
  );

  const getSections = (children: TSection[] = []) => {
    return (
      children?.filter((i) =>
        [StaticSectionKey, DynamicSectionKey].includes(i.resourcetype),
      ) || []
    );
  };

  return (
    <ApplicationFormProvider>
      {columns.length > 0 && (
        <StyledColumns className="Columns">
          {columns.map((i, idx) => {
            return (
              <StyledColumn className="Column" key={idx}>
                {getSections(i?.children).map((section) => {
                  return <Section key={section.name} section={section} />;
                })}
              </StyledColumn>
            );
          })}
        </StyledColumns>
      )}

      {!columns.length &&
        getSections(tab?.children).map((section) => {
          return <Section key={section.name} section={section} />;
        })}
    </ApplicationFormProvider>
  );
};
