import cn from 'classnames';
import { FC } from 'react';

import { TChildren } from '~/types/TChildren';

import {
  StyledEntrySectionContent,
  StyledEntrySectionContainer,
  StyledEntrySectionHeader,
  StyledEntrySectionTitle,
} from './EntrySection.styled';
import { TEntrySectionProps } from './EntrySection.types';

export const EntrySection: FC<TEntrySectionProps & TChildren> = ({
  title = '',
  action,
  children,
  className,
  gap = '24px',
}) => {
  return (
    <StyledEntrySectionContainer
      className={cn(['EntrySectionContainer', className])}
    >
      {(title || action) && (
        <StyledEntrySectionHeader className="EntrySectionHeader">
          <StyledEntrySectionTitle className="EntrySectionTitle">
            {title}
          </StyledEntrySectionTitle>
          {action}
        </StyledEntrySectionHeader>
      )}
      <StyledEntrySectionContent className="EntrySectionContent" gap={gap}>
        {children}
      </StyledEntrySectionContent>
    </StyledEntrySectionContainer>
  );
};
