import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  Notification,
  TFileUploadProps,
  TSetFileErrorFn,
  TSetFileDeleteStatusFn,
  DeleteStatus,
  Loader,
} from 'react-ui-kit-exante';

import {
  useGetImportedContactsQuery,
  useGetPermissionsQuery,
  useImportContactsMutation,
} from '~/api';
import { TImportedContactsResponse } from '~/api/contacts/contacts.types';
import { PATHS } from '~/router';

import { ACCEPTED_FORMATS } from './ContactsImportEntry.constants';
import {
  StyledImportContactsErrors,
  StyledImportContactsFileUpload,
  StyledImportContactsPanel,
  StyledImportContactsParagraph,
  StyledImportContactsProgress,
  StyledImportContactsTitle,
  StyledImportContactsWarning,
} from './ContactsImportEntry.styled';
import { EImportedContactsState } from './ContactsImportEntry.types';

export const ContactsImportEntry: FC = () => {
  const navigate = useNavigate();

  const [taskId, setTaskId] = useState('');
  const [fileName, setFileName] = useState('');
  const [importErrorFn, setImportErrorFn] = useState<TSetFileErrorFn>(() => {});
  const [progressText, setProgressText] = useState('');
  const [importFinished, setImportFinished] = useState(false);
  const [errors, setErrors] = useState<ReactNode[]>([]);

  const [onImportContacts] = useImportContactsMutation();

  const { data: importedContactsData, refetch } = useGetImportedContactsQuery(
    taskId,
    {
      skip: !taskId,
    },
  );

  const { data: permissions } = useGetPermissionsQuery();

  const handleFileDelete = (
    payload: string,
    setDeleted: TSetFileDeleteStatusFn,
  ) => {
    setErrors([]);
    setProgressText('');
    setDeleted({ id: payload, status: DeleteStatus.SUCCESS });
  };

  const handleUpload: TFileUploadProps['onUpload'] = async (
    payload: File[],
    setFileSuccess,
    setFileError,
  ) => {
    setErrors([]);
    setProgressText('');
    setImportFinished(false);

    setImportErrorFn(() => setFileError);

    const file = payload[0];

    const uploaded = await onImportContacts({ file });

    const uploadedFileName = file.name;

    setFileName(uploadedFileName);

    if (!('error' in uploaded)) {
      setFileSuccess({
        name: uploadedFileName,
        id: uploadedFileName,
        removable: true,
      });

      const importTaskId = uploaded.data.task_id;

      if (importTaskId) {
        setTaskId(importTaskId);

        setProgressText('Importing...');
      }
    }

    return null;
  };

  const validateImportedFile = (data: TImportedContactsResponse) => {
    const { state, meta } = data;

    if (state === EImportedContactsState.progress) {
      setProgressText(`Importing: ${meta.current_item}/${meta.total}...`);
    } else if (
      state === EImportedContactsState.failure ||
      state === EImportedContactsState.revoked
    ) {
      Notification.error({
        title: `Import failed: ${meta || 'Unknown error'}`,
      });

      setProgressText('');

      setImportFinished(true);
    } else if (state === EImportedContactsState.success) {
      const validationErrors = meta.errors;

      if (validationErrors && Object.keys(validationErrors).length > 0) {
        const errorMessages = Object.entries(validationErrors).map(
          ([row, rowErrors]) => (
            <span key={row}>
              <strong>Row {row}:</strong> {(rowErrors as string[]).join(', ')}
            </span>
          ),
        );

        importErrorFn({ name: fileName, error: new Error('Validation error') });

        setErrors(errorMessages);

        setImportFinished(true);

        Notification.error({
          title: 'Import finished with errors',
        });

        setProgressText(
          `Imported ${meta.imported} of ${meta.total}. Import finished with the following errors:`,
        );
      } else {
        setImportFinished(true);

        Notification.success({
          title: 'Import finished successfully',
        });

        setProgressText('');
      }
    }
  };

  useEffect(() => {
    if (!taskId || importFinished) {
      return;
    }

    const interval = setInterval(refetch, 5000);

    // TODO fix this rule
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [taskId, refetch, importFinished]);

  useEffect(() => {
    if (importedContactsData) {
      validateImportedFile(importedContactsData);
    }
  }, [importedContactsData]);

  const hasPermissionToAdd = permissions?.clientsarea?.add_contact;

  const handleClose = () => {
    navigate(PATHS.CONTACTS);
  };

  return (
    <StyledImportContactsPanel
      title="Import contacts"
      action={
        <IconButton
          title="Close import contacts"
          iconName="CloseIcon"
          iconColor="secondary"
          onClick={handleClose}
        />
      }
    >
      <StyledImportContactsTitle className="ImportContactsTitle">
        Please use the following format:
      </StyledImportContactsTitle>

      <StyledImportContactsParagraph className="ImportContactsDesc">
        First name, Last name, Email, Phone, <sup>*</sup>Corporate / Individual,{' '}
        <sup>*</sup>Advert tag, <sup>*</sup>Event tag, <sup>*</sup>Channel tag,
        Manager, <sup>*</sup>Partner email, <sup>*</sup>KPI share manager,{' '}
        <sup>*</sup>
        Company name
      </StyledImportContactsParagraph>

      <StyledImportContactsParagraph className="ImportContactsParagraph">
        <sup>*</sup>Optional fields
      </StyledImportContactsParagraph>

      <StyledImportContactsParagraph className="ImportContactsParagraph">
        Upload maximum 1 file
      </StyledImportContactsParagraph>

      <StyledImportContactsFileUpload
        accept={ACCEPTED_FORMATS}
        showFileSize={false}
        onUpload={handleUpload}
        onDelete={handleFileDelete}
        className="ImportContactsFileUpload"
        disabled={!hasPermissionToAdd}
        isMultiple={false}
      />

      {!hasPermissionToAdd && (
        <StyledImportContactsWarning className="ImportContactsWarning">
          You don&apos;t have permisson to import contacts
        </StyledImportContactsWarning>
      )}

      {!!progressText && (
        <StyledImportContactsProgress className="ImportContactsProgress">
          {!importFinished && <Loader size={16} />} {progressText}
        </StyledImportContactsProgress>
      )}

      {errors.length > 0 && (
        <StyledImportContactsErrors className="ImportContactsErrors">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </StyledImportContactsErrors>
      )}
    </StyledImportContactsPanel>
  );
};
