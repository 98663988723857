import { array, object, string, boolean } from 'yup';

export const REQUEST_FORM_VALIDATION_SCHEMA = object({
  assignedTo: array().notRequired(),
  legalEntities: array().notRequired(),
  deadline: string().nullable().notRequired(),
  state_obj_id: string().required(),
  files: array().notRequired(),
  subject: string().required(),
  summary: string().notRequired(),
  accessLevel: array().notRequired(),
  nonComplianceFee: boolean().notRequired(),
  oneTimeExtension: boolean().notRequired(),
  closeOnlyMode: boolean().notRequired(),
});

export enum RequestType {
  SarSTR = 'SAR/ STR',
  InvestigativeCase = 'Investigative case',
  RequestToClient = 'Request to client',
}

export const STATE_SOLVED = 2;
