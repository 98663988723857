import { useContext, useState, useEffect } from 'react';
import { IconButton, Notification } from 'react-ui-kit-exante';

import {
  useGetApplicationLegalDocsQuery,
  useGetApplicationOtherLegalDocsQuery,
  useLazyGetApplicationLegalDocUrlQuery,
  useLazyGetApplicationLegalDocPDFUrlQuery,
  useLazyGetApplicationLegalDocPDFQuery,
} from 'api';
import { TApplicationLegalDocs } from '~/api/applications/applications.types';
import { SquareCrossIcon, SquareTickIcon } from '~/assets/icons';
import { Accordion } from '~/components/Accordion';
import { EntrySection } from '~/components/EntryScreen';
import { OnboardingSettingsContext } from '~/pages/ApplicationEntry/components/OnboardingSettings/context';

import {
  StyledValidationAccordionOptions,
  StyledValidationBlock,
  StyledValidationCategorizationSubTitle,
  StyledValidationGhost,
  StyledValidationLegalEntity,
  StyledValidationName,
  StyledValidationPasswordSubTitle,
  StyledValidationRow,
  StyledValidationRowWrapper,
  StyledValidationSubTitle,
} from './ValidationAndCategorization.styled';

export const ValidationAndCategorization = () => {
  const { application } = useContext(OnboardingSettingsContext);

  const applicationId = application?.application?.id;
  const password = application?.user?.password;
  const isPasswordIsSet = password && password[0] !== '!';

  const [urlPdf, setUrlPdf] = useState('');
  const [isLoadingForPdf, setIsLoadingForPdf] = useState(false);
  const [currentDoc, setCurrentDoc] = useState<{
    docId: number;
    title: string;
  } | null>(null);

  const { data } = useGetApplicationLegalDocsQuery({
    id: applicationId,
  });
  const { data: otherLegalDocs } = useGetApplicationOtherLegalDocsQuery({
    id: applicationId,
  });
  const [fetchApplicationLegalDocUrl] = useLazyGetApplicationLegalDocUrlQuery();
  const [fetchApplicationLegalDocPDFUrl, statePdfUrl] =
    useLazyGetApplicationLegalDocPDFUrlQuery({
      pollingInterval: urlPdf ? 0 : 5000,
    });
  const [fetchApplicationLegalDocPDF] = useLazyGetApplicationLegalDocPDFQuery();

  const dataWithDocs =
    data?.filter(({ signed_legal_docs }) => signed_legal_docs) || [];

  const dataWithoutDocs = data?.filter(
    ({ signed_legal_docs }) => !signed_legal_docs,
  );

  const pdfUrl = statePdfUrl.data?.url;

  const preparedDataWithoutDocs =
    dataWithoutDocs?.reduce((acc: TApplicationLegalDocs[][], curr) => {
      const last = acc[acc.length - 1] || [{}];

      if (last[0].legal_entity === curr.legal_entity) {
        last.push(curr);
      } else {
        acc.push([curr]);
      }
      return acc;
    }, []) || [];

  const downloadPdf = async (
    docId: number,
    legalEntity: string,
    title: string,
  ) => {
    setUrlPdf('');
    setIsLoadingForPdf(true);
    setCurrentDoc({ docId, title });

    const { data: dataDocUrl } = await fetchApplicationLegalDocUrl({
      docId,
      legalEntity,
    });
    const urlWithId = dataDocUrl?.url;

    if (urlWithId) {
      fetchApplicationLegalDocPDFUrl({ urlWithId });
    }
  };

  const getPdf = async () => {
    if (pdfUrl) {
      setUrlPdf(pdfUrl);

      try {
        const { data: blob } = await fetchApplicationLegalDocPDF({
          url: pdfUrl,
        });

        if (blob) {
          const linkElement = document.createElement('a');

          linkElement.href = blob && window.URL.createObjectURL(blob);
          linkElement.setAttribute('download', `${currentDoc?.title}.pdf`);
          document.body.appendChild(linkElement);
          linkElement.click();
          document.body.removeChild(linkElement);
        }
      } catch (err) {
        Notification.error({ title: String(err) });
      } finally {
        setCurrentDoc(null);
        setIsLoadingForPdf(false);
      }
    }
  };

  useEffect(() => {
    if (pdfUrl) {
      getPdf();
      setUrlPdf(pdfUrl);
    }
  }, [statePdfUrl.data]);

  return (
    <EntrySection gap="0px" title="Validation and categorization">
      <StyledValidationBlock className="ValidationBlock">
        <StyledValidationPasswordSubTitle className="ValidationPasswordSubTitle">
          Password
        </StyledValidationPasswordSubTitle>
        <StyledValidationRow className="ValidationRow">
          {isPasswordIsSet ? <SquareTickIcon /> : <SquareCrossIcon />}
          <StyledValidationName className="ValidationName">
            {isPasswordIsSet ? 'Password is set' : "Password don't set"}
          </StyledValidationName>
        </StyledValidationRow>
      </StyledValidationBlock>

      {dataWithDocs?.length > 0 && (
        <StyledValidationBlock className="ValidationBlock">
          <StyledValidationSubTitle className="ValidationSubTitle">
            Legal Documents
          </StyledValidationSubTitle>
          {dataWithDocs.map((item, index) => {
            const {
              title,
              legal_entity: legalEntity,
              signed_legal_docs: signedLegalDocs,
            } = item;

            return (
              <div key={index}>
                {dataWithDocs[index - 1]?.legal_entity !== legalEntity && (
                  <StyledValidationLegalEntity className="ValidationLegalEntity">
                    {legalEntity}
                  </StyledValidationLegalEntity>
                )}
                <StyledValidationRowWrapper className="ValidationRowWrapper">
                  <div>
                    <StyledValidationRow className="ValidationRow">
                      <SquareTickIcon />
                      <StyledValidationName className="ValidationName">
                        {`[${legalEntity}]`}
                      </StyledValidationName>
                      <StyledValidationGhost className="ValidationGhost">
                        {signedLegalDocs?.sign_date}
                      </StyledValidationGhost>
                      <StyledValidationName className="ValidationName">
                        {title}
                      </StyledValidationName>
                    </StyledValidationRow>
                  </div>
                  <IconButton
                    title="Download PDF"
                    onClick={() =>
                      downloadPdf(signedLegalDocs.pk, legalEntity, title)
                    }
                    iconName="DownloadIcon"
                    iconSize={22}
                    iconColor="secondary"
                    disabled={
                      isLoadingForPdf &&
                      currentDoc?.docId === signedLegalDocs?.pk
                    }
                  />
                </StyledValidationRowWrapper>
              </div>
            );
          })}
        </StyledValidationBlock>
      )}

      {preparedDataWithoutDocs?.length > 0 && (
        <StyledValidationBlock className="ValidationBlock">
          {preparedDataWithoutDocs.map((item, index) => {
            return (
              <Accordion
                key={index}
                title={
                  <StyledValidationGhost className="ValidationGhost">
                    {` Show not signed docs [${item[0].legal_entity}]`}
                  </StyledValidationGhost>
                }
              >
                <>
                  <StyledValidationLegalEntity className="ValidationLegalEntity">
                    {item[0].legal_entity}
                  </StyledValidationLegalEntity>
                  <StyledValidationAccordionOptions className="ValidationAccordionOptions">
                    {item.map((item1) => {
                      const { title, legal_entity: legalEntity } = item1;
                      return (
                        <StyledValidationRow
                          key={index}
                          className="ValidationRow"
                        >
                          <SquareCrossIcon />
                          <StyledValidationName className="ValidationName">
                            {`[${legalEntity}]`}
                          </StyledValidationName>
                          <StyledValidationName className="ValidationName">
                            {title}
                          </StyledValidationName>
                        </StyledValidationRow>
                      );
                    })}
                  </StyledValidationAccordionOptions>
                </>
              </Accordion>
            );
          })}
        </StyledValidationBlock>
      )}

      {otherLegalDocs && otherLegalDocs.length > 0 && (
        <>
          <StyledValidationSubTitle className="ValidationSubTitle">
            Other Legal Documents
          </StyledValidationSubTitle>
          {otherLegalDocs.map((item, index) => {
            const {
              title,
              legal_entity: legalEntity,
              signed_legal_docs: signedLegalDocs,
            } = item;

            return (
              <div key={index}>
                {otherLegalDocs[index - 1]?.legal_entity !== legalEntity && (
                  <StyledValidationLegalEntity className="ValidationLegalEntity">
                    {legalEntity}
                  </StyledValidationLegalEntity>
                )}
                <StyledValidationRow className="ValidationRow">
                  <SquareTickIcon />
                  <StyledValidationName className="ValidationName">
                    {`[${legalEntity}]`}
                  </StyledValidationName>
                  <StyledValidationGhost className="ValidationGhost">
                    {signedLegalDocs?.sign_date}
                  </StyledValidationGhost>
                  <StyledValidationName className="ValidationName">
                    {title}
                  </StyledValidationName>
                </StyledValidationRow>
              </div>
            );
          })}
        </>
      )}

      {application?.corp_categorization &&
        application.corp_categorization.length > 0 && (
          <>
            <StyledValidationCategorizationSubTitle className="ValidationCategorizationSubTitle">
              Categorization
            </StyledValidationCategorizationSubTitle>
            {application?.corp_categorization
              ?.filter(({ bool }) => bool)
              ?.map((item, index) => {
                const { title, choices } = item;

                return (
                  <div key={index}>
                    <StyledValidationRow className="ValidationRow">
                      <SquareTickIcon />
                      <StyledValidationName className="ValidationName">
                        {`${title}: ${choices}`}
                      </StyledValidationName>
                    </StyledValidationRow>
                  </div>
                );
              })}
          </>
        )}
    </EntrySection>
  );
};
