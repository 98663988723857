import { createApi } from '@reduxjs/toolkit/query/react';

import {
  TDefaultPaginationResponse,
  TFiltersParams,
  TPaginationParams,
} from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import { TReportLinkResponse, TReports } from './reports.types';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Reports'],
  endpoints: (builder) => ({
    getReports: builder.query<
      TDefaultPaginationResponse<TReports>,
      {
        paginationParams: TPaginationParams;
        filterParams: TFiltersParams;
      }
    >({
      query: ({ paginationParams, filterParams }) => ({
        url: '/clientsarea/crm/reports/',
        method: 'POST',
        data: {
          ...paginationParams,
          ...filterParams,
        },
      }),
      providesTags: ['Reports'],
    }),

    getReport: builder.query<string, string>({
      query: (id) => {
        return {
          url: `/clientsarea/crm/reports/${id}/`,
        };
      },
    }),

    getReportLink: builder.query<TReportLinkResponse, string>({
      query: (id) => {
        return {
          url: `/clientsarea/crm/reports/${id}/file/`,
        };
      },
    }),
  }),
});
export const {
  useLazyGetReportsQuery,
  useLazyGetReportQuery,
  useLazyGetReportLinkQuery,
} = reportsApi;
