import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

import { TBookmarks } from './types';

export const usePrevious = (values: TBookmarks) => {
  const ref = useRef<TBookmarks>();

  useEffect(() => {
    if (!isEqual(values, ref.current)) {
      ref.current = values;
    }
  }, [values]);

  return ref.current;
};

export const getDefaultBookmarkForStorybook = (): TBookmarks => ({
  id: 0,
  name: 'All Clients',
  table: 'clientsTable',
  filters: {
    clientType: 'RETAIL',
  },
  columns: [
    'id',
    'clientType',
    'documentsPending',
    'legalEntity',
    'name',
    'owner',
  ],
});
