import { Panel, styled } from 'react-ui-kit-exante';

export const StyledEntryScreenWrapper = styled(Panel)`
  position: relative;
  padding: 0;
  min-height: 200px;
  height: 100%;

  .loader {
    & > div {
      position: relative;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
