import { styled } from 'react-ui-kit-exante';

export const StyledConfirmTitleWrapper = styled('div')`
  display: flex;
`;

export const StyledConfirmTitle = styled('div')`
  font-size: 15px;
  font-family: ${({ theme }) => theme?.font?.main};
  color: ${({ theme }) => theme.color.typo.secondary};
`;

export const StyledConfirmListWrapper = styled('ul')`
  list-style: none;
  padding: 0;
  margin: 5px 0 0 24px;
`;

export const StyledConfirmList = styled('li')`
  display: flex;
  align-items: center;
  margin: 0 0 8px 0;
`;

export const StyledConfirmSubTitle = styled('div')`
  font-size: 13px;
  margin: 0 0 0 8px;
  color: ${({ theme }) => theme.color.typo.primary};
`;
