import cn from 'classnames';
import { createElement, FC } from 'react';

import { mapIcons } from '~/constants/statusesInfoMap';

import {
  StyledHead,
  StyledResponsible,
  StyledStatus,
  StyledWrapper,
  StyledWrapperStatus,
} from './StatusInfo.styled';
import { TStyledStatusInfo } from './StatusInfo.types';

export const StatusInfo: FC<TStyledStatusInfo> = ({
  entityKey,
  status,
  responsibleTitle,
  className,
}) => {
  const icon = mapIcons?.[entityKey]?.[status]
    ? createElement(mapIcons[entityKey][status])
    : '';

  return (
    <StyledWrapper className={cn(['StatusInfo', className])}>
      <StyledWrapperStatus className="WrapperStatus">
        <StyledStatus className="Status" status={status} entityKey={entityKey}>
          {icon}
        </StyledStatus>
        <div>
          <StyledHead className="Head">Status</StyledHead>
          <StyledStatus
            className="Status"
            status={status}
            entityKey={entityKey}
          >
            {status}
          </StyledStatus>
        </div>
      </StyledWrapperStatus>
      <div>
        <StyledHead className="Head">Responsible</StyledHead>
        <StyledResponsible className="Status">
          {responsibleTitle}
        </StyledResponsible>
      </div>
    </StyledWrapper>
  );
};
