import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import { DictionariesService } from '~/services/dictionaries';
import { TFilterOption, TTableFilter, TTableFilters } from '~/types/table';

// we should request only unique filters url with params
const onlyUnique = (
  currentReqFilter: TTableFilter,
  index: number,
  array: TTableFilter[],
) => {
  if (!currentReqFilter.choice_url_relative) {
    return false;
  }

  const findedReq = array.find((filter) => {
    return (
      filter.choice_url_relative === currentReqFilter.choice_url_relative &&
      isEqual(filter.choice_query_params, currentReqFilter.choice_query_params)
    );
  });

  if (findedReq) {
    return array.indexOf(findedReq) === index;
  }

  return true;
};

export const useGetTableFilterOptions = (filters: TTableFilters = []) => {
  const [filterOptions, setFilterOptions] = useState<
    Record<string, TFilterOption[]>
  >({});

  const calcFilterOptions = () => {
    const filtersForRequest = filters.filter(onlyUnique);

    filtersForRequest.forEach(async (filter) => {
      const choiceUrl = filter?.choice_url_relative;
      if (choiceUrl) {
        const res = await DictionariesService.fetchFilterDictionary(
          choiceUrl || '',
          filter.choice_query_params,
        );

        setFilterOptions((prevState) => ({
          ...prevState,
          [filter.type]: res,
        }));
      }
    });
  };

  useEffect(() => {
    calcFilterOptions();
  }, [filters]);

  return filterOptions;
};
