export const DEFAULT_EMPTY_PLACEHOLDER = '–';
export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const MIN_BIRTH_DATE = new Date('1900-01-01T00:00:00');
export const MAX_BIRTH_DATE = new Date();
export const DEFAULT_CURRENCY = 'EUR';

export enum FieldTypes {
  Select = 'select',
  Autocomplete = 'autocomplete',
  Checkbox = 'checkbox',
  DateField = 'date',
  DateTimeField = 'datetime',
  NumberField = 'number',
  Input = 'input',
  Textarea = 'textarea',
  File = 'file',
}

export const mapTagTypeToFieldType: Record<string, FieldTypes> = {
  choice: FieldTypes.Select,
  flag: FieldTypes.Checkbox,
  text: FieldTypes.Input, // todo ???? maybe choices
  input: FieldTypes.Input,
  textarea: FieldTypes.Textarea,
  money: FieldTypes.NumberField,
  date: FieldTypes.DateField,
};

export const CHECK_ONLY_NUMBER = /^\d+$/;

export const EMPTY_ARRAY: never[] = [];

export const ALL_LEGAL_ENTITY = 'ALL';
