import { TTagsChoices } from '~/api/globalContext/globalContext.types';
import { FieldTypes } from '~/constants/common';
import { transformVariantsToSelectOptions } from '~/utils/transformVariantsToSelectOptions';

export const getClientFormFields = (options?: TTagsChoices) => {
  return [
    {
      name: 'withdrawal_restriction',
      title: 'Withdrawal restriction',
      type: FieldTypes.Checkbox,
      editable: true,
    },
    {
      name: 'documents_pending',
      title: 'Documents pending',
      type: FieldTypes.Checkbox,
      editable: true,
    },
    {
      name: 'legal_entity',
      title: 'Legal entity',
      type: FieldTypes.Select,
      editable: true,
      options: transformVariantsToSelectOptions(options?.legalEntities),
    },
    {
      name: 'client_type',
      title: 'Client type',
      editable: true,
      type: FieldTypes.Select,
      options: transformVariantsToSelectOptions(options?.clientTypes),
    },
    {
      name: 'approved_at',
      title: 'Approved date',
      editable: true,
      type: FieldTypes.DateField,
    },
    {
      name: 'last_client_review_date',
      title: 'Last client review date',
      editable: true,
      type: FieldTypes.DateField,
    },
    {
      name: 'closed_account',
      title: 'Closed',
      editable: false,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'available_number_of_accounts',
      title: 'Available number of accounts',
      editable: false,
      type: FieldTypes.NumberField,
    },
    {
      name: 'funds_received',
      title: 'Funds received',
      editable: false,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'funds_received_date',
      title: 'Funds received date',
      editable: false,
      type: FieldTypes.DateField,
    },
    {
      name: 'had_min_deposit',
      title: 'NAV > Minimum deposit',
      editable: true,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'min_deposit_date',
      title: 'Minimum deposit date',
      editable: true,
      type: FieldTypes.DateField,
    },
    {
      name: 'total_nav',
      title: 'Total NAV',
      editable: true,
      type: FieldTypes.NumberField,
    },
    {
      name: 'liquid_nav',
      title: `Liquid NAV`,
      editable: true,
      type: FieldTypes.NumberField,
    },
    {
      name: 'non_liquid_nav',
      title: 'Non-liquid NAV',
      editable: true,
      type: FieldTypes.NumberField,
    },
    {
      name: 'non_compliance_fee',
      title: 'Non-compliance fee',
      editable: true,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'non_compliance_fee_date',
      title: 'Non-compliance fee date',
      editable: true,
      type: FieldTypes.DateField,
    },
    {
      name: 'non_compliance_fee_amount',
      title: 'Non-compliance fee amount',
      editable: true,
      type: FieldTypes.NumberField,
    },
  ];
};
