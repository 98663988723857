import { styled } from '~/theme';

export const StyledActionsRow = styled('div')`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledFormField = styled('div')`
  min-width: 270px;
`;
