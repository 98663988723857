import { DEFAULT_EMPTY_PLACEHOLDER } from '../constants/common';

export const formatStringToColumn = (value?: string[]) => {
  if (!value) {
    return DEFAULT_EMPTY_PLACEHOLDER;
  }
  return (
    <div title={value.join(', ')}>
      {value?.map((item) => {
        return <div key={item}>{item || DEFAULT_EMPTY_PLACEHOLDER}</div>;
      })}
    </div>
  );
};
