import { Menu } from '@mui/material';
import { styled } from 'react-ui-kit-exante';

export const StyledAddUserManagementForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;

export const StyledAddUserManagementFormActions = styled('div')`
  display: flex;
  gap: 24px;
`;

export const StyledAddUserManagementFormPopUp = styled(Menu)`
  .MuiMenu-paper {
    background-color: ${({ theme }) => theme?.color?.modal.bg};
    box-shadow: ${({ theme }) => theme.effect.controls.drop};
  }
`;
