import { createApi } from '@reduxjs/toolkit/query/react';

import { TDefaultParams, TNodeDefaultPaginationResponse } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../nodeBackApi/endpoints';

import { TUser, TUsersTableFiltersResponse } from './computedUsers.types';

export const computedUsersApi = createApi({
  reducerPath: 'computedUsersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['ComputedUsers', 'ComputedUsersFilters'],
  endpoints: (builder) => ({
    getComputedUsers: builder.query<
      TNodeDefaultPaginationResponse<TUser>,
      TDefaultParams
    >({
      query: (params) => ({
        url: `${NODE_BACK_URL}/api/users/computed`,
        params,
        ignoreForbiddenError: true,
      }),
      providesTags: ['ComputedUsers'],
    }),
    getComputedUsersTableFilters: builder.query<
      TUsersTableFiltersResponse,
      void
    >({
      query: () => ({
        url: `${NODE_BACK_URL}/api/users/computed/metadata`,
        ignoreForbiddenError: true,
      }),
      providesTags: ['ComputedUsersFilters'],
    }),
  }),
});

export const {
  useLazyGetComputedUsersQuery,
  useGetComputedUsersTableFiltersQuery,
} = computedUsersApi;
