import { FC } from 'react';

import { TTagsResponse } from '~/api/applications/applications.types';
import { ValueRow } from '~/components/ValueRow';
import { mapTagTypeToFieldType } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { StyledSectionBody, StyledFormField } from './SectionBody.styled';

export type TSectionBodyProps = {
  data: TTagsResponse[];
};

export const SectionBody: FC<TSectionBodyProps> = ({ data }) => {
  const tags = data[0].tags || [];

  return (
    <StyledSectionBody className="SectionBody">
      {tags?.length > 0 &&
        tags.map((tag) => {
          // const tagValues = tag?.tagvalue_set
          //   ?.map((value) =>
          //     typeof value.t_value === 'string'
          //       ? value.t_value
          //       : value.t_value?.text,
          //   )
          //   .join(', ');

          return (
            <div key={tag.id}>
              <ValueRow
                key={tag.id}
                label={tag.title || tag.name}
                valueNode={
                  <StyledFormField className="FormField">
                    {getFormField({
                      type: mapTagTypeToFieldType[tag.type],
                      name: tag.name,
                      // isLoading: optionsConfig?.isLoading,
                      options: tag?.choices?.split(',').map((i) => ({
                        value: i,
                        label: i,
                      })),
                    })}
                  </StyledFormField>
                }
              />
            </div>
          );
        })}
    </StyledSectionBody>
  );
};
