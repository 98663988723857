import { FC, MouseEvent, useState } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { TChildren } from '~/types/TChildren';

import {
  StyledActionListPopover,
  StyledActionListWrapper,
} from './ActionList.styled';
import { TActionListProps } from './ActionList.types';

const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
} as const;

const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
} as const;

export const ActionList: FC<TActionListProps & TChildren> = ({
  children,
  dataTestId = 'action-list',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <StyledActionListWrapper className="ActionList">
      <IconButton
        onClick={handleOpen}
        iconSize={24}
        iconName="MoreIcon"
        iconColor="secondary"
        title="More actions"
        data-test-id={`${dataTestId}--toggle-button`}
        className="ActionListToggleButton"
      />
      <StyledActionListPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        className="ActionListPopover"
        data-test-id={`${dataTestId}--popover`}
      >
        {children}
      </StyledActionListPopover>
    </StyledActionListWrapper>
  );
};
