import { TErrorFallbackProps } from './ErrorFallback.types';

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: TErrorFallbackProps) => {
  console.error(error);

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};
