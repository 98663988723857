import { createApi } from '@reduxjs/toolkit/query/react';
import { get, has } from 'lodash';

import { baseQueryHandler } from '~/utils/apiRequest';
import { getArrayDifference } from '~/utils/arrayDifference';

import { ACCOUNT_PERMISSIONS_FIELDS_BY_USERNAME } from './accountPermissions.constants';
import {
  ACCOUNT_PERMISSIONS_URL,
  getUpdateAccountPermissionEndpoint,
  getUpdateAtpPermissionsEndpoint,
} from './accountPermissions.endpoints';
import {
  atpPermissionsMapper,
  buildDataWithExtractedAutocompleteValue,
  getBackInjectedPermissions,
} from './accountPermissions.helpers';
import {
  TAccountPermissionByUsername,
  TAccountPermissionData,
  TAtpPermissionsResponse,
  TDefaultAccountAccess,
  TPrevPermissionsData,
} from './accountPermissions.types';

export const accountPermissionsApi = createApi({
  reducerPath: 'accountPermissionsApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getAccountPermissionData: builder.query<
      TAccountPermissionData,
      { userId: string }
    >({
      queryFn: async ({ userId }, _, __, fetchWitBaseQuery) => {
        const requestPermissionsByUsername = fetchWitBaseQuery({
          url: ACCOUNT_PERMISSIONS_URL,
          ignoreForbiddenError: true,
          params: {
            userId,
            fields: ACCOUNT_PERMISSIONS_FIELDS_BY_USERNAME,
          },
        });

        const requestAtpPermissions = fetchWitBaseQuery({
          url: getUpdateAtpPermissionsEndpoint(userId),
          ignoreForbiddenError: true,
        });

        const [accountPermissions, atpPermissionsData] = await Promise.all([
          requestPermissionsByUsername,
          requestAtpPermissions,
        ]);

        if ('error' in accountPermissions || 'error' in atpPermissionsData) {
          let resultError = ``;

          if (accountPermissions.error) {
            resultError += `Request permissions by username: ${accountPermissions.error}\n`;
          }
          if (atpPermissionsData.error) {
            resultError += `Request ATP permissions: ${atpPermissionsData.error}`;
          }

          return {
            error: resultError,
          };
        }

        const preparedAtpPermissions = atpPermissionsMapper(
          atpPermissionsData.data || {},
        );

        const result: {
          accountPermissions: typeof accountPermissions.data;
          defaultAccountAccess: typeof preparedAtpPermissions.defaultAccountAccess;
          atpPermissions: typeof preparedAtpPermissions.atpPermissions;
        } = {
          accountPermissions: accountPermissions.data,
          defaultAccountAccess: preparedAtpPermissions.defaultAccountAccess,
          atpPermissions: preparedAtpPermissions.atpPermissions,
        };

        return {
          data: result,
        };
      },
    }),
    updatePermissions: builder.mutation<
      TAccountPermissionByUsername[],
      {
        userId: string;
        permissions: TAccountPermissionByUsername[];
        previousPermissions: TAccountPermissionByUsername[];
        setPreviousPermissions: TPrevPermissionsData['setPreviousPermissions'];
      }
    >({
      queryFn: async (
        { userId, permissions, previousPermissions, setPreviousPermissions },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const permissionsWithExtractedAutocompleteValue =
          buildDataWithExtractedAutocompleteValue<TAccountPermissionByUsername>(
            permissions,
            ['accountId'],
          );

        const { createdElements, editedElements, deletedElements } =
          getArrayDifference(
            previousPermissions,
            permissionsWithExtractedAutocompleteValue,
            'accountId',
          );

        const preparedData = createdElements.map((item) => ({
          ...item,
          userId,
        }));

        const createPermissionsPromise = fetchWithBaseQuery({
          url: ACCOUNT_PERMISSIONS_URL,
          data: preparedData,
          method: 'POST',
        });

        const updatePermissionsPromise = Promise.all(
          editedElements.map((item) => {
            return fetchWithBaseQuery({
              url: getUpdateAccountPermissionEndpoint(item.id),
              data: item,
              method: 'POST',
            });
          }),
        );

        const deletePermissionsPromise = Promise.all(
          deletedElements.map((item) =>
            fetchWithBaseQuery({
              url: getUpdateAccountPermissionEndpoint(item.id),
              method: 'DELETE',
            }),
          ),
        );

        const [createResponse, updateResponse, deleteResponse] =
          await Promise.all([
            createPermissionsPromise,
            updatePermissionsPromise,
            deletePermissionsPromise,
          ]);

        const backInjectedPermissions = getBackInjectedPermissions(
          permissionsWithExtractedAutocompleteValue,
          createResponse.data ? createResponse.data : [],
        );

        setPreviousPermissions(backInjectedPermissions);

        if (
          [createResponse, updateResponse, deleteResponse].some((res) =>
            has(res, 'error'),
          )
        ) {
          let resultError = ``;

          if (createResponse.error) {
            resultError += `Create permissions: ${createResponse.error}\n`;
          }
          if ('error' in updateResponse) {
            resultError += `Update permissions: ${get(
              updateResponse,
              'error',
            )}\n`;
          }
          if ('error' in deleteResponse) {
            resultError += `Delete permissions: ${get(
              deleteResponse,
              'error',
            )}`;
          }

          return {
            error: resultError,
          };
        }

        return {
          data: backInjectedPermissions,
        };
      },
    }),
    updateAtpPermissions: builder.mutation<
      TDefaultAccountAccess,
      {
        userId: string;
        atpPermissions: TAtpPermissionsResponse;
      }
    >({
      query: ({ userId, atpPermissions }) => ({
        method: 'POST',
        url: getUpdateAtpPermissionsEndpoint(userId),
        data: {
          ...atpPermissions,
          superuser:
            atpPermissions.superuser === 'blocked'
              ? null
              : atpPermissions.superuser,
        },
      }),
    }),
  }),
});

export const {
  useGetAccountPermissionDataQuery,
  useUpdatePermissionsMutation,
  useUpdateAtpPermissionsMutation,
} = accountPermissionsApi;
