import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { CURRENT_USER_ACCESS_RIGHTS_ENDPOINT } from './accessRights.endpoints';
import { TCurrentUserAccessRights } from './accessRights.types';

export const accessRightsApi = createApi({
  reducerPath: 'accessRightsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['AccessRightsCurrentUser'],
  endpoints: (builder) => ({
    getCurrentUserAccessRights: builder.query<TCurrentUserAccessRights, void>({
      query: () => ({
        url: CURRENT_USER_ACCESS_RIGHTS_ENDPOINT,
      }),
      transformErrorResponse: () => {
        return {
          error: `Unexpected error while getting access rights for user`,
        };
      },
      providesTags: ['AccessRightsCurrentUser'],
    }),
  }),
});

export const { useGetCurrentUserAccessRightsQuery } = accessRightsApi;
