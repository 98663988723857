import { KitTheme, blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { EDataChangeRequestStatuses } from '~/api/dataChangeRequests/dataChangeRequests.types';
import { EntryPage } from '~/components/EntryScreen';

import { TStyledStatus } from './DataChangeRequestsEntry.types';

export const colorsMap = (
  theme: KitTheme,
): Record<EDataChangeRequestStatuses, string> => ({
  [EDataChangeRequestStatuses.Approved]: theme?.color?.typo?.action,
  [EDataChangeRequestStatuses.Rejected]: theme?.color?.typo?.radical,
  [EDataChangeRequestStatuses.New]: theme?.color?.typo?.primary,
});

export const StyledDCRequestStatus = styled('div')`
  padding: 0 24px 28px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledDCRequestStatusText = styled('div')`
  p {
    margin: 0;
    font-size: 13px;
    line-height: 16px;
  }
`;

export const StyledDCRequestTextStatus = styled('div', {
  shouldForwardProp: blockNonNativeProps('status'),
})<TStyledStatus>`
  color: ${({ status, theme }) =>
    status
      ? colorsMap(theme)[status as EDataChangeRequestStatuses]
      : theme?.color?.typo?.primary};
`;

export const StyledDCRequestIconStatus = styled('div', {
  shouldForwardProp: blockNonNativeProps('status'),
})<TStyledStatus>`
  color: ${({ status, theme }) =>
    status
      ? colorsMap(theme)[status as EDataChangeRequestStatuses]
      : theme?.color?.typo?.primary};
`;

export const StyledDCRequestEntryPage = styled(EntryPage)`
  grid-template-columns: 30% 70%;
`;

export const StyledDCRequestsEntryActions = styled('div')`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-inline-end: 16px;
`;

export const StyledDCRequestDetails = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDCRequestEntryRejectConfirm = styled('div')`
  h3 {
    margin: 16px 0 8px;
    font-size: 15px;
    font-family: ${({ theme }) => theme?.font?.header};
  }

  p {
    margin: 0 0 8px;
  }

  .DCRequestEntryRejectConfirmField {
    margin-bottom: 8px;
  }
`;

export const StyledDCRequestEmailWithLink = styled('a')`
  color: ${({ theme }) => theme?.color.typo.action};

  &:hover,
  &:visited {
    color: ${({ theme }) => theme?.color.typo.promo};
  }
`;
