import { TMapper, TParams } from '~/types/api';

type TTransformerArguments = {
  // can't use any interfaces when unknown instead any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any>;
  mapper: TMapper;
};

export function paramsTransformer({
  params,
  mapper,
}: TTransformerArguments): TParams {
  const paramsWithoutDate = Object.keys(params).filter(
    (item) => item !== 'reportDate',
  );

  return paramsWithoutDate.reduce((acc, curr) => {
    const mapperItem = mapper[curr];

    if (mapperItem && mapperItem.parser) {
      const parsedValues = mapperItem?.parser(params[curr]);

      return { ...acc, ...parsedValues };
    }
    return { ...acc, [curr]: params[curr] };
  }, {});
}
