import { object } from 'yup';

import { TSecurity } from '~/api/withdrawals/withdrawals.types';
import { DEFAULT_CURRENCY, FieldTypes } from '~/constants/common';
import { formatNumber } from '~/utils/formatters/formatNumber';
import { mapTypeToValidationFn } from '~/utils/forms/mapTypeToValidationFn';

export const getWithdrawalSecurityFields = (
  isCanChange?: boolean,
  withdrawalSecurity?: TSecurity | null,
) => {
  const isFieldEditable = isCanChange;

  const currency = withdrawalSecurity?.currency;

  return [
    {
      name: 'security_type',
      title: 'Security Type',
      editable: isFieldEditable,
    },
    {
      name: 'security_name',
      title: 'Security Name',
      editable: isFieldEditable,
    },
    {
      name: 'isin_code',
      title: 'ISIN Code',
      editable: isFieldEditable,
    },
    {
      name: 'exchange',
      title: 'Exchange',
      editable: isFieldEditable,
    },
    {
      name: 'quantity',
      title: 'Quantity',
      editable: isFieldEditable,
      valueType: 'number',
      formatter: formatNumber,
    },
    {
      name: 'price',
      title: 'Price',
      editable: isFieldEditable,
      valueType: 'number',
      formatter: formatNumber,
      additionalLabel: currency,
    },
    {
      name: 'commission',
      title: 'Commission',
      editable: isFieldEditable,
      valueType: 'number',
      formatter: formatNumber,
      additionalLabel: DEFAULT_CURRENCY,
    },
    {
      name: 'settlement_date',
      title: 'Settlement Date',
      editable: isFieldEditable,
      type: FieldTypes.DateField,
    },
    {
      name: 'trade_date',
      title: 'Trade Date',
      editable: isFieldEditable,
      type: FieldTypes.DateField,
    },
    {
      name: 'custodian',
      title: 'Custodian',
      editable: isFieldEditable,
    },
  ];
};

export const getDefaultFormValues = (
  withdrawalSecurity: TSecurity,
  isCanChange: boolean,
): Record<string, any> => {
  return getWithdrawalSecurityFields(isCanChange, withdrawalSecurity).reduce(
    (acc, curr) => {
      const key = curr.name as keyof TSecurity;
      if (!curr.editable) {
        return { ...acc };
      }

      if (curr.type === FieldTypes.DateField) {
        return {
          ...acc,
          [curr.name]: withdrawalSecurity[key]
            ? new Date(String(withdrawalSecurity[key]))
            : null,
        };
      }

      const value = withdrawalSecurity[key];

      return {
        ...acc,
        [curr.name]: value,
      };
    },
    {},
  );
};

export const getWithdrawalSecurityFormValidationScheme = () => {
  const scheme = getWithdrawalSecurityFields().reduce((acc, curr) => {
    const valueType = curr.valueType;
    if (valueType) {
      return {
        ...acc,
        [curr.name]: mapTypeToValidationFn(valueType)
          .transform((val, orig) =>
            orig === '' || orig === null ? undefined : val,
          )
          .typeError(valueType ? `${curr.title} must be a '${valueType}'` : ''),
      };
    }

    return {
      ...acc,
    };
  }, {});
  return object(scheme);
};
