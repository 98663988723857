import { isEqual } from 'lodash';

import { createHashMapFromArray, isKey } from './helpers';

export function findEditedElements<Data extends Record<keyof Data, unknown>>(
  previous: Data[],
  next: Data[],
  comparedField: keyof Data,
) {
  const previousHashMap = createHashMapFromArray(previous, comparedField);

  return next.filter((initialItem) => {
    const currentValue = initialItem[comparedField];

    if (isKey(currentValue)) {
      if (!previousHashMap[currentValue]) {
        return false;
      }

      return !isEqual(initialItem, previousHashMap[currentValue]);
    }

    return false;
  });
}
