import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  useCreateBookmarkBOMutation,
  useCreateBookmarkMutation,
  useDeleteBookmarkBOMutation,
  useDeleteBookmarkMutation,
  useUpdateBookmarkBOMutation,
  useUpdateBookmarkMutation,
} from '~/api';
import { TUpdateBookmarkPayload } from '~/api/bookmarks/bookmarks.types';
import { customEvents } from '~/utils/customEvents';
import { removeEmptyStrings } from '~/utils/removeEmptyStrings';
import { getTableColumnsFromLs } from '~/utils/table';

import { clearTableFilters } from '../../components/WithBookmarks/helpers';

import { transformFilters } from './helpers';
import { TBookmarkProps, TBookmarkResponseProps } from './types';
import { useBookmarkData } from './useBookmarkData';

export const useBookmark = ({
  pageName,
  setSearchParams,
  tableId,
}: TBookmarkProps): TBookmarkResponseProps => {
  const dispatch = useDispatch();
  const navigator = window.navigator;
  const currentPath = window.location.href;
  const { pathname } = useLocation();
  const [, pathName] = pathname.slice(1).split('/');
  const { search } = useLocation();

  const isBOBookmarks = tableId === 'users';

  const [onDeleteBookmark] = useDeleteBookmarkMutation();
  const [onCreateBookmark] = useCreateBookmarkMutation();
  const [onUpdateBookmark, stateUpdateBookmark] = useUpdateBookmarkMutation();

  const [onDeleteBookmarkBO] = useDeleteBookmarkBOMutation();
  const [onCreateBookmarkBO] = useCreateBookmarkBOMutation();
  const [onUpdateBookmarkBO, stateUpdateBookmarkBO] =
    useUpdateBookmarkBOMutation();

  const { isLoading, selectedBookmark } = useBookmarkData({
    pageName,
    tableId,
    isBOBookmarks,
  });
  const { id } = selectedBookmark || {};

  const addBookmarkEvents = useCallback(() => {
    const bookmarkEvents = [
      'onCreateBookmark',
      'onDeleteBookmark',
      'onUpdateBookmark',
    ];

    bookmarkEvents.forEach((eventName) => {
      customEvents.add(eventName, {
        detail: {
          serviceName: 'crm',
        },
      });
    });
  }, []);

  useEffect(() => {
    addBookmarkEvents();
  }, [addBookmarkEvents]);

  const handleSaveBookmark = useCallback(
    async (name: string, filters: Record<string, unknown>) => {
      const createPayload = {
        name,
        filters: transformFilters(removeEmptyStrings(filters)),
        columns: getTableColumnsFromLs(tableId),
      };

      if (!id) {
        const response = isBOBookmarks
          ? await onCreateBookmarkBO({
              data: { ...createPayload, table: tableId, bookmarkTab: tableId },
            })
          : await onCreateBookmark({
              data: { ...createPayload, table_name: tableId },
            });

        if (response !== null) {
          customEvents.dispatch('onCreateBookmark');
        }
      } else {
        const updatePayload: TUpdateBookmarkPayload = createPayload;

        const updateResponse = isBOBookmarks
          ? await onUpdateBookmarkBO({
              id,
              data: updatePayload,
            })
          : await onUpdateBookmark({
              id,
              data: updatePayload,
            });
        if (updateResponse !== null) {
          customEvents.dispatch('onUpdateBookmark');
        }
      }
    },
    [dispatch, id, pathName, tableId],
  );

  const handleSaveAsNewBookmark = useCallback(
    async (name: string, filters: Record<string, unknown>) => {
      const createPayload = {
        name,
        filters: transformFilters(removeEmptyStrings(filters)),
        columns: getTableColumnsFromLs(tableId),
      };

      const response = isBOBookmarks
        ? await onCreateBookmarkBO({
            data: { ...createPayload, table: tableId, bookmarkTab: tableId },
          })
        : await onCreateBookmark({
            data: { ...createPayload, table_name: tableId },
          });

      if (response !== null) {
        customEvents.dispatch('onCreateBookmark');
      }
    },
    [dispatch, pathName, tableId],
  );

  const handleShareBookmark = useCallback(() => {
    navigator.clipboard.writeText(currentPath);
    Notification.success({
      title: 'Bookmark url was copied',
    });
  }, [currentPath, navigator]);

  const handleDeleteBookmark = useCallback(async () => {
    if (!id) {
      return;
    }

    const deleted = isBOBookmarks
      ? await onDeleteBookmarkBO({ id })
      : await onDeleteBookmark({ id });
    const searchParams = new URLSearchParams(search);

    searchParams.delete('bookmark');
    setSearchParams(searchParams);
    clearTableFilters(setSearchParams);
    if (deleted) {
      customEvents.dispatch('onDeleteBookmark');
    }
  }, [id, search, setSearchParams]);

  return {
    isLoading:
      isLoading ||
      (isBOBookmarks
        ? stateUpdateBookmarkBO.isLoading
        : stateUpdateBookmark?.isLoading),
    selectedBookmark,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
  };
};
