import { FilterValue } from 'react-ui-kit-exante';

export type TGetAdditionalFilters = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
}: TGetAdditionalFilters) => [
  {
    Header: 'Search',
    accessor: 'search',
    onFilter,
    onRemove,
  },
];

export const getDefaultFilters = () => ({ search: '' });
