import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ReactNode } from 'react';

import { flagIcons } from './phoneWithFlag.constants';
import { StyledPhoneWithFlag } from './phoneWithFlag.styled';

export const formatPhoneNumberWithFlag = (phoneNumber: string): ReactNode => {
  const phoneNumberObject = parsePhoneNumberFromString(phoneNumber);
  if (!phoneNumberObject) {
    return phoneNumber;
  }

  const countryCode = phoneNumberObject.country || 'US';
  const flagIcon = flagIcons[countryCode];

  if (!flagIcon) {
    return phoneNumberObject.formatInternational();
  }

  return (
    <StyledPhoneWithFlag className="PhoneWithFlag">
      {flagIcon} {phoneNumberObject.formatInternational()}
    </StyledPhoneWithFlag>
  );
};
