import { createApi } from '@reduxjs/toolkit/query/react';
import { isUndefined, omitBy } from 'lodash';

import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../endpoints';

import { accountsMapper } from './accounts.helpers';
import {
  TAccount,
  TAccountsParams,
  TAccountsResponse,
  TAccountsState,
  TPaginationResponse,
  TSummary,
} from './accounts.types';

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Accounts'],
  endpoints: (builder) => ({
    searchByAccounts: builder.query<TAccountsResponse, TAccountsParams>({
      queryFn: async (
        { limit = 100, skip, search, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        if (skip === null) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
            },
          };
        }

        const queryFields = ['id'];
        const preparedParams = omitBy(
          { fields: queryFields, id: search, limit, skip, ...restParams },
          isUndefined,
        );

        const response = await fetchWithBaseQuery({
          url: `${NODE_BACK_URL}/api/accounts/list`,
          params: preparedParams,
          requestOptions: {},
        });

        const accounts = accountsMapper(response.data);

        return {
          data: {
            nextSkip: limit === accounts.length ? skip + limit : null,
            options: accounts.map(({ id }) => ({
              label: id,
              value: id,
            })),
          },
        };
      },
    }),

    getAccounts: builder.query<TAccountsState, Record<string, unknown>>({
      query: (params) => ({
        url: `${NODE_BACK_URL}/api/accounts/`,
        params,
      }),
      transformResponse: (response: {
        data: TAccount[];
        pagination: TPaginationResponse;
        summary?: TSummary;
      }) => {
        const { data, pagination, summary } = response;

        return {
          accounts: data,
          pagination,
          summary,
        };
      },
      providesTags: ['Accounts'],
    }),
  }),
});
export const { useLazySearchByAccountsQuery, useLazyGetAccountsQuery } =
  accountsApi;
