import Box from '@mui/material/Box';

import { styled } from '../../theme';

export const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',

  '& > div': {
    display: 'flex',
  },
}));
