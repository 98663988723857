import { useContext, useMemo } from 'react';
import { Table } from 'react-ui-kit-exante';

import { useGetApplicationLogsQuery } from 'api';
import { EntrySection } from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { getTableId } from '~/utils/table';

import { OnboardingSettingsContext } from '../../context';

import { COLUMNS } from './OnboardingLog.constants';

export const OnboardingLog = () => {
  const { application } = useContext(OnboardingSettingsContext);

  const applicationId = application?.application?.id;

  const { data: logs } = useGetApplicationLogsQuery({
    id: applicationId,
  });

  const tableId = getTableId('onboarding-log');

  const logsTableData =
    useMemo(
      () =>
        logs?.map(
          (log: (string | number)[]) => ({
            status: `${log[0]} > ${log[1]}`,
            days: log[2],
          }),
          [],
        ),
      [],
    ) || [];

  return (
    <>
      <EntrySection gap="8px" title="Onboarding log">
        <ValueRow label="Application ID" value={applicationId} />
        <ValueRow
          label="Approved by"
          value={application?.approved_by_full_name}
        />
        <ValueRow label="Approved date" value={application?.approved_date} />
      </EntrySection>
      <Table
        isFlexLayout
        tableId={tableId}
        columns={COLUMNS}
        data={logsTableData}
      />
    </>
  );
};
