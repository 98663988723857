import { has } from 'lodash';

class CustomEvents {
  events: Record<string, CustomEvent> = {};

  add(eventName: string, options: Record<string, unknown>) {
    if (has(this.events, eventName)) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(`Custom event ${eventName} already exists`);
      }
      return false;
    }
    this.events[eventName] = new CustomEvent(eventName, options);
    return true;
  }

  dispatch(eventName: string) {
    if (has(this.events, eventName)) {
      window.dispatchEvent(this.events[eventName]);
      return true;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.error(`Custom event ${eventName} does not exist`);
    }
    return false;
  }
}

const customEvents = new CustomEvents();

export { customEvents };
