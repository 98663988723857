import { useParams } from 'react-router-dom';
import { FileUpload, TFile, UploadStatus } from 'react-ui-kit-exante';

import {
  useGetWithdrawalDocumentsQuery,
  useLazyGetWithdrawalDownloadClientDocumentQuery,
} from '~/api';
import { EntrySection } from '~/components/EntryScreen';
import { TParams } from '~/router/router.types';

export const ClientDocuments = () => {
  const { id: withdrawalId } = useParams<TParams>();
  const { data } = useGetWithdrawalDocumentsQuery({
    id: withdrawalId as string,
  });
  const [downloadClientDocument] =
    useLazyGetWithdrawalDownloadClientDocumentQuery();

  const filesInitial: TFile[] =
    data?.map((file) => ({
      ...file,
      size: 0,
      id: String(file.id),
      removable: true,
      name: file.file_basename,
      uploadStatus: UploadStatus.SUCCESS,
    })) || [];

  const getDocument = async (id: string) => {
    const { data: dataUrl } = await downloadClientDocument({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  if (filesInitial.length === 0) {
    return null;
  }

  return (
    <EntrySection title="Client Documents">
      <FileUpload
        viewMode
        showFileSize={false}
        filesInitial={filesInitial}
        onDownload={getDocument}
      />
    </EntrySection>
  );
};
