import { createContext, FC, useMemo, useRef } from 'react';

import { TChildren } from 'types/TChildren';

export type TPendingReportsContext = {
  pendingReports?: Set<number>;
  updatePendingReports: (id?: number) => void;
  clearPendingReports: VoidFunction;
};

const initialState: TPendingReportsContext = {
  pendingReports: new Set(),
  updatePendingReports: () => {},
  clearPendingReports: () => {},
};

export const PendingReportsContext =
  createContext<TPendingReportsContext>(initialState);

export const PendingReportsProvider: FC<TChildren> = ({ children }) => {
  const pendingReports = useRef(initialState.pendingReports);

  const updatePendingReports = (id?: number) => {
    if (id) {
      pendingReports.current?.add(id);
    }
  };

  const clearPendingReports = () => {
    pendingReports.current?.clear();
  };

  const value = useMemo(
    (): TPendingReportsContext => ({
      pendingReports: pendingReports.current,
      updatePendingReports,
      clearPendingReports,
    }),
    [pendingReports.current, updatePendingReports, clearPendingReports],
  );

  return (
    <PendingReportsContext.Provider value={value}>
      {children}
    </PendingReportsContext.Provider>
  );
};
