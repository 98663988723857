import { EntryColumn } from '~/components/EntryScreen';

import {
  StyledEntryPage,
  StyledOnboardingSettingsWrapper,
  StyledSaveIcon,
} from './OnboardingSettings.styled';
import {
  ClientForm,
  OnboardingLog,
  ValidationAndCategorization,
} from './components';

export const OnboardingSettings = () => {
  return (
    <StyledOnboardingSettingsWrapper className="OnboardingSettingsWrapper">
      <StyledSaveIcon
        className="SaveIcon"
        title="Save"
        label="Save"
        iconColor="action"
        iconName="SaveIcon"
        onClick={() => {}}
      />
      <StyledEntryPage>
        <EntryColumn>
          <ClientForm />
        </EntryColumn>
        <EntryColumn>
          <OnboardingLog />
          <ValidationAndCategorization />
        </EntryColumn>
      </StyledEntryPage>
    </StyledOnboardingSettingsWrapper>
  );
};
