import { createHashMapFromArray, isKey } from './helpers';

export function findCreatedElements<Data extends Record<keyof Data, unknown>>(
  previous: Data[],
  next: Data[],
  comparedField: keyof Data,
) {
  const previousHashMap = createHashMapFromArray(previous, comparedField);

  return next.filter((initialItem) => {
    const currentValue = initialItem[comparedField];

    if (isKey(currentValue)) {
      return !previousHashMap[currentValue];
    }

    // If the unique key in the new item isn't generated
    return currentValue === undefined;
  });
}
