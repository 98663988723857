import { FC, useEffect, useState } from 'react';
import {
  FileUpload,
  TFile,
  TSetFileSuccessFn,
  UploadStatus,
} from 'react-ui-kit-exante';

import {
  useUploadFilesMutation,
  useDeleteFileMutation,
  useLazyGetWithdrawalDownloadUploadedFileQuery,
} from '~/api';
import { ACCEPT_FILE_LIST } from '~/constants/files';

import { TUploader } from './Uploader.types';

export const FilesUploader: FC<TUploader> = ({
  withdrawal,
  isLoading: isLoadingFiles,
}) => {
  const [onDeleteFile, stateDelete] = useDeleteFileMutation();
  const [onUploadFiles, stateUpload] = useUploadFilesMutation();
  const [downloadUploadedFile] =
    useLazyGetWithdrawalDownloadUploadedFileQuery();

  const [isUpdateUploader, setIsUpdateUploader] = useState(false);

  const isUploadLoading = stateUpload.isLoading;
  const isDeleteLoading = stateDelete.isLoading;
  const isShowLoading = isLoadingFiles && isUpdateUploader;

  const filesInitial: TFile[] = withdrawal.uploads.map((file) => ({
    ...file,
    id: String(file.id),
    removable: true,
    size: 0,
    uploadStatus: UploadStatus.SUCCESS,
  }));

  useEffect(() => {
    if (isUploadLoading || isDeleteLoading) {
      setIsUpdateUploader(true);
    }
  }, [isUploadLoading, isDeleteLoading]);

  useEffect(() => {
    if (!isLoadingFiles) {
      setIsUpdateUploader(false);
    }
  }, [isLoadingFiles]);

  const handleFileAppend = (payload: File[], onSuccess: TSetFileSuccessFn) =>
    Promise.all(
      payload.map(async (file) => {
        const uploaded = await onUploadFiles({ id: withdrawal.id, file });
        if (!('error' in uploaded)) {
          const { name, size } = file;

          onSuccess({ name, id: String(size), removable: true });

          return uploaded.data.id;
        }
        return null;
      }),
    );

  const handleDelete = async (id: string) => {
    await onDeleteFile({ withdrawalId: withdrawal.id, fileId: id });
  };

  const getFile = async (id: string) => {
    const { data: dataUrl } = await downloadUploadedFile({ id });

    const url = dataUrl?.url;

    if (url) {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      {isShowLoading && <FileUpload loading />}
      {!isShowLoading && (
        <FileUpload
          showFileSize={false}
          accept={ACCEPT_FILE_LIST}
          onUpload={handleFileAppend}
          onDelete={handleDelete}
          onDownload={getFile}
          filesInitial={filesInitial}
          withDeleteConfirmation
        />
      )}
    </>
  );
};
