import { styled } from 'react-ui-kit-exante';

export const StyledValueRowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledValueRowValue = styled('div')`
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: break-all;
`;

export const StyledValueRowLabel = styled('div')``;
