import { styled } from 'react-ui-kit-exante';

export const StyledAddContactForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 280px;
`;

export const StyledAddContactFormError = styled('p')`
  font-size: 11px;
  margin: 0;
  color: ${({ theme }) => theme.color.typo.radical};
`;
