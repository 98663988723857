import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

export type TStyledSubSectionContent = {
  gap: string;
};

export const StyledEntrySubSectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: ${({ theme }) => `1px solid ${theme?.color?.dropdown?.border}`};
  padding: 24px 0;
`;

export const StyledSubSectionTitle = styled('h5')`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0 0 24px;
`;

export const StyledSubSectionContent = styled('div', {
  shouldForwardProp: blockNonNativeProps('gap'),
})<TStyledSubSectionContent>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;
