import { ISortBy } from 'react-ui-kit-exante';

export const DISPLAYED_COLUMN_KEYS = [
  'id',
  'accountId',
  'timestamp',
  'operationType',
  'sum',
  'asset',
  'symbolId',
  'symbolType',
  'who',
  'comment',
  'clientType',
  'internalCounterparty',
  'valueDate',
  'exchangeCommission',
  'executionCounterparty',
  'category',
  'extraData',
  'blockedQtty',
  'actions',
];

export const DEFAULT_SORTING_TS = [{ id: 'timestamp', desc: true }];

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'timestamp', desc: true }];
}
