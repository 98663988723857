import {
  Afghanistan,
  AlandIslands,
  Albania,
  Algeria,
  AmericanSamoa,
  Andorra,
  Angola,
  Anguilla,
  AntiguaAndBarbuda,
  Argentina,
  Armenia,
  Aruba,
  Australia,
  Austria,
  Azerbaijan,
  Bahamas,
  Bahrain,
  Bangladesh,
  Barbados,
  Belarus,
  Belgium,
  Belize,
  Benin,
  Bermuda,
  Bhutan,
  Bolivia,
  Bonaire,
  BosniaAndHerzegovina,
  Botswana,
  Brazil,
  BritishIndianOceanTerritory,
  BritishVirginIslands,
  Brunei,
  Bulgaria,
  BurkinaFaso,
  Burundi,
  Cambodia,
  Cameroon,
  Canada,
  CapeVerde,
  CaymanIslands,
  CentralAfricanRepublic,
  Chad,
  Chile,
  China,
  CocosIsland,
  Colombia,
  Comoros,
  CookIslands,
  Costarica,
  Croatia,
  Cuba,
  Curacao,
  Cyprus,
  CzechRepublic,
  DemocraticRepublicOfCongo,
  Denmark,
  Djibouti,
  Dominica,
  DominicanRepublic,
  EastTimor,
  Ecuador,
  Egypt,
  ElSalvador,
  EquatorialGuinea,
  Eritrea,
  Estonia,
  Ethiopia,
  FalklandIslands,
  FaroeIslands,
  Fiji,
  Finland,
  France,
  FrenchPolynesia,
  Gabon,
  Gambia,
  Georgia,
  Germany,
  Ghana,
  Gibraltar,
  Greece,
  Greenland,
  Grenada,
  Guam,
  Guatemala,
  Guernsey,
  Guinea,
  GuineaBissau,
  Guyana,
  Haiti,
  Honduras,
  HongKong,
  Hungary,
  Iceland,
  India,
  Indonesia,
  Iran,
  Iraq,
  Ireland,
  IsleOfMan,
  Israel,
  Italy,
  IvoryCoast,
  Jamaica,
  Japan,
  Jersey,
  Jordan,
  Kazakhstan,
  Kenya,
  Kiribati,
  Kosovo,
  Kuwait,
  Kyrgyzstan,
  Laos,
  Latvia,
  Lebanon,
  Lesotho,
  Liberia,
  Libya,
  Liechtenstein,
  Lithuania,
  Luxembourg,
  Macao,
  Madagascar,
  Malawi,
  Malaysia,
  Maldives,
  Mali,
  Malta,
  MarshallIsland,
  Martinique,
  Mauritania,
  Mauritius,
  Mexico,
  Micronesia,
  Moldova,
  Monaco,
  Mongolia,
  Montenegro,
  Montserrat,
  Morocco,
  Mozambique,
  Myanmar,
  Namibia,
  Nauru,
  Nepal,
  Netherlands,
  NewZealand,
  Nicaragua,
  Niger,
  Nigeria,
  Niue,
  NorfolkIsland,
  NorthKorea,
  Norway,
  Oman,
  Pakistan,
  Palau,
  Palestine,
  Panama,
  PapuaNewGuinea,
  Paraguay,
  Peru,
  Philippines,
  PitcairnIslands,
  Poland,
  Portugal,
  PuertoRico,
  Qatar,
  RepublicOfMacedonia,
  RepublicOfTheCongo,
  Romania,
  Russia,
  Rwanda,
  Samoa,
  SanMarino,
  SaoTomeAndPrince,
  SaudiArabia,
  Senegal,
  Serbia,
  Seychelles,
  SierraLeone,
  Singapore,
  SintMaarten,
  Slovakia,
  Slovenia,
  SolomonIslands,
  Somalia,
  SouthAfrica,
  SouthKorea,
  SouthSudan,
  Spain,
  SriLanka,
  StBarts,
  StLucia,
  StVincentAndTheGrenadines,
  Sudan,
  Suriname,
  Swaziland,
  Sweden,
  Switzerland,
  Syria,
  Taiwan,
  Tajikistan,
  Tanzania,
  Thailand,
  Togo,
  Tokelau,
  Tonga,
  TrinidadAndTobago,
  Tunisia,
  Turkey,
  Turkmenistan,
  TurksAndCaicos,
  Tuvalu,
  Uganda,
  Ukraine,
  UnitedArabEmirates,
  UnitedKingdom,
  UnitedStates,
  Uruguay,
  Uzbekistan,
  Vanuatu,
  VaticanCity,
  Venezuela,
  Vietnam,
  VirginIslands,
  Yemen,
  Zambia,
  Zimbabwe,
} from 'react-ui-kit-exante';

import { TFlagIcons } from './phoneWithFlag.types';

export const flagIcons: TFlagIcons = {
  AF: <Afghanistan width={16} height={16} />,
  AL: <Albania width={16} height={16} />,
  AS: <AmericanSamoa width={16} height={16} />,
  AI: <Anguilla width={16} height={16} />,
  AW: <Aruba width={16} height={16} />,
  AX: <AlandIslands width={16} height={16} />,
  DZ: <Algeria width={16} height={16} />,
  AD: <Andorra width={16} height={16} />,
  AO: <Angola width={16} height={16} />,
  AG: <AntiguaAndBarbuda width={16} height={16} />,
  AR: <Argentina width={16} height={16} />,
  AM: <Armenia width={16} height={16} />,
  AU: <Australia width={16} height={16} />,
  AT: <Austria width={16} height={16} />,
  AZ: <Azerbaijan width={16} height={16} />,
  BS: <Bahamas width={16} height={16} />,
  BH: <Bahrain width={16} height={16} />,
  BD: <Bangladesh width={16} height={16} />,
  BB: <Barbados width={16} height={16} />,
  BL: <StBarts width={16} height={16} />,
  BM: <Bermuda width={16} height={16} />,
  BQ: <Bonaire width={16} height={16} />,
  BY: <Belarus width={16} height={16} />,
  BE: <Belgium width={16} height={16} />,
  BN: <Brunei width={16} height={16} />,
  BZ: <Belize width={16} height={16} />,
  BJ: <Benin width={16} height={16} />,
  BT: <Bhutan width={16} height={16} />,
  BO: <Bolivia width={16} height={16} />,
  BA: <BosniaAndHerzegovina width={16} height={16} />,
  BW: <Botswana width={16} height={16} />,
  BR: <Brazil width={16} height={16} />,
  BG: <Bulgaria width={16} height={16} />,
  BF: <BurkinaFaso width={16} height={16} />,
  BI: <Burundi width={16} height={16} />,
  KH: <Cambodia width={16} height={16} />,
  CM: <Cameroon width={16} height={16} />,
  CA: <Canada width={16} height={16} />,
  CK: <CookIslands width={16} height={16} />,
  CV: <CapeVerde width={16} height={16} />,
  CF: <CentralAfricanRepublic width={16} height={16} />,
  CG: <RepublicOfTheCongo width={16} height={16} />,
  CC: <CocosIsland width={16} height={16} />,
  CW: <Curacao width={16} height={16} />,
  TD: <Chad width={16} height={16} />,
  CL: <Chile width={16} height={16} />,
  CN: <China width={16} height={16} />,
  CO: <Colombia width={16} height={16} />,
  KM: <Comoros width={16} height={16} />,
  CD: <DemocraticRepublicOfCongo width={16} height={16} />,
  CR: <Costarica width={16} height={16} />,
  HR: <Croatia width={16} height={16} />,
  CU: <Cuba width={16} height={16} />,
  CY: <Cyprus width={16} height={16} />,
  CZ: <CzechRepublic width={16} height={16} />,
  DK: <Denmark width={16} height={16} />,
  DJ: <Djibouti width={16} height={16} />,
  DM: <Dominica width={16} height={16} />,
  DO: <DominicanRepublic width={16} height={16} />,
  EC: <Ecuador width={16} height={16} />,
  EG: <Egypt width={16} height={16} />,
  SV: <ElSalvador width={16} height={16} />,
  GQ: <EquatorialGuinea width={16} height={16} />,
  ER: <Eritrea width={16} height={16} />,
  EE: <Estonia width={16} height={16} />,
  ET: <Ethiopia width={16} height={16} />,
  FJ: <Fiji width={16} height={16} />,
  FI: <Finland width={16} height={16} />,
  FR: <France width={16} height={16} />,
  GA: <Gabon width={16} height={16} />,
  GM: <Gambia width={16} height={16} />,
  GE: <Georgia width={16} height={16} />,
  DE: <Germany width={16} height={16} />,
  GH: <Ghana width={16} height={16} />,
  GR: <Greece width={16} height={16} />,
  GD: <Grenada width={16} height={16} />,
  GT: <Guatemala width={16} height={16} />,
  GN: <Guinea width={16} height={16} />,
  GW: <GuineaBissau width={16} height={16} />,
  GY: <Guyana width={16} height={16} />,
  HT: <Haiti width={16} height={16} />,
  HN: <Honduras width={16} height={16} />,
  HU: <Hungary width={16} height={16} />,
  IS: <Iceland width={16} height={16} />,
  IN: <India width={16} height={16} />,
  ID: <Indonesia width={16} height={16} />,
  IO: <BritishIndianOceanTerritory width={16} height={16} />,
  IR: <Iran width={16} height={16} />,
  IQ: <Iraq width={16} height={16} />,
  IE: <Ireland width={16} height={16} />,
  IL: <Israel width={16} height={16} />,
  IT: <Italy width={16} height={16} />,
  JM: <Jamaica width={16} height={16} />,
  JP: <Japan width={16} height={16} />,
  JO: <Jordan width={16} height={16} />,
  KZ: <Kazakhstan width={16} height={16} />,
  KE: <Kenya width={16} height={16} />,
  KI: <Kiribati width={16} height={16} />,
  KY: <CaymanIslands width={16} height={16} />,
  KW: <Kuwait width={16} height={16} />,
  KG: <Kyrgyzstan width={16} height={16} />,
  LA: <Laos width={16} height={16} />,
  LV: <Latvia width={16} height={16} />,
  LB: <Lebanon width={16} height={16} />,
  LS: <Lesotho width={16} height={16} />,
  LR: <Liberia width={16} height={16} />,
  LY: <Libya width={16} height={16} />,
  LT: <Lithuania width={16} height={16} />,
  LU: <Luxembourg width={16} height={16} />,
  MG: <Madagascar width={16} height={16} />,
  MW: <Malawi width={16} height={16} />,
  MY: <Malaysia width={16} height={16} />,
  MV: <Maldives width={16} height={16} />,
  MK: <RepublicOfMacedonia width={16} height={16} />,
  ML: <Mali width={16} height={16} />,
  MT: <Malta width={16} height={16} />,
  MR: <Mauritania width={16} height={16} />,
  MU: <Mauritius width={16} height={16} />,
  MX: <Mexico width={16} height={16} />,
  MD: <Moldova width={16} height={16} />,
  MC: <Monaco width={16} height={16} />,
  MN: <Mongolia width={16} height={16} />,
  ME: <Montenegro width={16} height={16} />,
  MA: <Morocco width={16} height={16} />,
  MZ: <Mozambique width={16} height={16} />,
  MM: <Myanmar width={16} height={16} />,
  NA: <Namibia width={16} height={16} />,
  NR: <Nauru width={16} height={16} />,
  NP: <Nepal width={16} height={16} />,
  NL: <Netherlands width={16} height={16} />,
  NZ: <NewZealand width={16} height={16} />,
  NI: <Nicaragua width={16} height={16} />,
  NE: <Niger width={16} height={16} />,
  NG: <Nigeria width={16} height={16} />,
  KP: <NorthKorea width={16} height={16} />,
  NO: <Norway width={16} height={16} />,
  OM: <Oman width={16} height={16} />,
  PK: <Pakistan width={16} height={16} />,
  PW: <Palau width={16} height={16} />,
  PA: <Panama width={16} height={16} />,
  PG: <PapuaNewGuinea width={16} height={16} />,
  PY: <Paraguay width={16} height={16} />,
  PE: <Peru width={16} height={16} />,
  PH: <Philippines width={16} height={16} />,
  PL: <Poland width={16} height={16} />,
  PR: <PuertoRico width={16} height={16} />,
  PT: <Portugal width={16} height={16} />,
  QA: <Qatar width={16} height={16} />,
  RO: <Romania width={16} height={16} />,
  RU: <Russia width={16} height={16} />,
  RW: <Rwanda width={16} height={16} />,
  LC: <StLucia width={16} height={16} />,
  VC: <StVincentAndTheGrenadines width={16} height={16} />,
  WS: <Samoa width={16} height={16} />,
  SM: <SanMarino width={16} height={16} />,
  ST: <SaoTomeAndPrince width={16} height={16} />,
  SA: <SaudiArabia width={16} height={16} />,
  SN: <Senegal width={16} height={16} />,
  RS: <Serbia width={16} height={16} />,
  SC: <Seychelles width={16} height={16} />,
  SL: <SierraLeone width={16} height={16} />,
  SG: <Singapore width={16} height={16} />,
  SK: <Slovakia width={16} height={16} />,
  SI: <Slovenia width={16} height={16} />,
  SB: <SolomonIslands width={16} height={16} />,
  SO: <Somalia width={16} height={16} />,
  SZ: <Swaziland width={16} height={16} />,
  ZA: <SouthAfrica width={16} height={16} />,
  KR: <SouthKorea width={16} height={16} />,
  SS: <SouthSudan width={16} height={16} />,
  SX: <SintMaarten width={16} height={16} />,
  ES: <Spain width={16} height={16} />,
  LK: <SriLanka width={16} height={16} />,
  SD: <Sudan width={16} height={16} />,
  SR: <Suriname width={16} height={16} />,
  SE: <Sweden width={16} height={16} />,
  CH: <Switzerland width={16} height={16} />,
  SY: <Syria width={16} height={16} />,
  TW: <Taiwan width={16} height={16} />,
  TC: <TurksAndCaicos width={16} height={16} />,
  TJ: <Tajikistan width={16} height={16} />,
  TK: <Tokelau width={16} height={16} />,
  TZ: <Tanzania width={16} height={16} />,
  TH: <Thailand width={16} height={16} />,
  TG: <Togo width={16} height={16} />,
  TO: <Tonga width={16} height={16} />,
  TT: <TrinidadAndTobago width={16} height={16} />,
  TN: <Tunisia width={16} height={16} />,
  TR: <Turkey width={16} height={16} />,
  TM: <Turkmenistan width={16} height={16} />,
  TV: <Tuvalu width={16} height={16} />,
  UG: <Uganda width={16} height={16} />,
  UA: <Ukraine width={16} height={16} />,
  AE: <UnitedArabEmirates width={16} height={16} />,
  GB: <UnitedKingdom width={16} height={16} />,
  US: <UnitedStates width={16} height={16} />,
  UY: <Uruguay width={16} height={16} />,
  UZ: <Uzbekistan width={16} height={16} />,
  VU: <Vanuatu width={16} height={16} />,
  VA: <VaticanCity width={16} height={16} />,
  VE: <Venezuela width={16} height={16} />,
  VG: <BritishVirginIslands width={16} height={16} />,
  VI: <VirginIslands width={16} height={16} />,
  VN: <Vietnam width={16} height={16} />,
  YE: <Yemen width={16} height={16} />,
  ZM: <Zambia width={16} height={16} />,
  ZW: <Zimbabwe width={16} height={16} />,
  TL: <EastTimor width={16} height={16} />,
  FK: <FalklandIslands width={16} height={16} />,
  FO: <FaroeIslands width={16} height={16} />,
  PF: <FrenchPolynesia width={16} height={16} />,
  GI: <Gibraltar width={16} height={16} />,
  GL: <Greenland width={16} height={16} />,
  GU: <Guam width={16} height={16} />,
  GG: <Guernsey width={16} height={16} />,
  HK: <HongKong width={16} height={16} />,
  IM: <IsleOfMan width={16} height={16} />,
  CI: <IvoryCoast width={16} height={16} />,
  JE: <Jersey width={16} height={16} />,
  XK: <Kosovo width={16} height={16} />,
  LI: <Liechtenstein width={16} height={16} />,
  MO: <Macao width={16} height={16} />,
  MH: <MarshallIsland width={16} height={16} />,
  MQ: <Martinique width={16} height={16} />,
  FM: <Micronesia width={16} height={16} />,
  MS: <Montserrat width={16} height={16} />,
  NU: <Niue width={16} height={16} />,
  NF: <NorfolkIsland width={16} height={16} />,
  PS: <Palestine width={16} height={16} />,
  PN: <PitcairnIslands width={16} height={16} />,
};
