import { styled } from 'react-ui-kit-exante';

export const StyledSection = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledTitle = styled('div')`
  display: flex;
  padding-bottom: 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
