import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { colorsMap } from '~/constants/statusesInfoMap';

import { TStyledStatusCellProps } from './StatusCell.types';

export const StyledStatusCell = styled('div', {
  shouldForwardProp: blockNonNativeProps('status'),
})<TStyledStatusCellProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ status, theme }) =>
    status ? colorsMap(theme).deposit[status] : theme?.color?.typo?.primary};
`;
