import { array, mixed, object, string } from 'yup';

export const IMPORT_TAG_FORM_DEFAULT_VALUES = {
  tagName: '',
  filterField: '',
  importMode: 'set',
  file: [],
};

export const ACCEPTED_FORMATS =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx';

export const IMPORT_TAG_FORM_VALIDATION_SCHEMA = object({
  tagName: string().required('Tag name is a required field'),
  filterField: string().required('Search field is a required field'),
  importMode: string().notRequired(),
  file: array()
    .of(
      mixed().test(
        'fileType',
        'Unsupported file format. Accept only .jpg or .png.',
        (value) => value && ACCEPTED_FORMATS.includes(value.type),
      ),
    )
    .min(1, 'You must upload at least one file.'),
});

export const SEARCH_FIELD_OPTIONS = [
  { label: 'Id', value: 'id' },
  { label: 'Username', value: 'username' },
];

export const IMPORT_MODE_OPTIONS = [
  { label: 'Set value', value: 'set' },
  { label: 'Append value', value: 'append' },
  { label: 'Remove value', value: 'remove' },
];
