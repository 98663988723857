import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  DeleteStatus,
  FileUpload,
  TSetFileSuccessFn,
  TSetFileDeleteStatusFn,
} from 'react-ui-kit-exante';

import { TFileWithId } from '~/api/requests/requests.types';
import { CHECK_ONLY_NUMBER } from '~/constants/common';

import { TFormFileUploadContainerProps } from './FormFileUploadContainer.types';

export const FormFileUploadContainer: FC<TFormFileUploadContainerProps> = ({
  name,
  removable,
  translator,
  accept,
  onDeleteFile,
  onDownloadFile,
  ...rest
}) => {
  const formContext = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formContext.control,
    name,
  });

  const isLoading = rest?.loading;

  const handleFileAppend = (payload: File[], onSuccess: TSetFileSuccessFn) => {
    onChange([...(value as File[]), ...payload]);
    payload.forEach((file) =>
      onSuccess({
        name: file.name,
        id: file.name,
        removable,
      }),
    );
  };

  const handleFileDelete = (id: string, setDeleted: TSetFileDeleteStatusFn) => {
    const isOnlyNumber = CHECK_ONLY_NUMBER.test(id);

    setDeleted({ id, status: DeleteStatus.SUCCESS });

    onChange(
      (value as TFileWithId[]).filter((file) => {
        const tokenId = isOnlyNumber ? file.id : file.name;

        return tokenId !== id;
      }),
    );

    if (onDeleteFile && isOnlyNumber) {
      onDeleteFile({ fileId: id });
    }
  };

  const handleFileDownload = (id: string) => {
    const isOnlyNumber = CHECK_ONLY_NUMBER.test(id);

    if (onDownloadFile && isOnlyNumber) {
      return onDownloadFile(id);
    }

    return undefined;
  };

  return (
    <>
      {isLoading && <FileUpload loading />}
      {!isLoading && (
        <FileUpload
          onDownload={handleFileDownload}
          onUpload={handleFileAppend}
          onDelete={handleFileDelete}
          error={Boolean(error)}
          accept={accept}
          message={error?.message}
          filesInitial={value}
          {...rest}
        />
      )}
    </>
  );
};
