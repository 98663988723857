import { createElement } from 'react';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { mapIcons } from '~/constants/statusesInfoMap';

import { StyledStatusCell } from './StatusCell.styled';

export const StatusCell = ({ value }: { value: string }) => {
  const icon = mapIcons.request[value]
    ? createElement(mapIcons.request[value])
    : '';

  return (
    <StyledStatusCell className="StatusCell" status={value}>
      {icon}
      {value || DEFAULT_EMPTY_PLACEHOLDER}
    </StyledStatusCell>
  );
};
