import { createHashMapFromArray, isKey } from './helpers';

export function findDeletedElements<Data extends Record<keyof Data, unknown>>(
  previous: Data[],
  next: Data[],
  comparedField: keyof Data,
) {
  const nextHashMap = createHashMapFromArray(next, comparedField);

  return previous.filter((previousItem) => {
    const currentValue = previousItem[comparedField];

    if (isKey(currentValue)) {
      return !nextHashMap[currentValue];
    }

    return false;
  });
}
