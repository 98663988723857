import { createElement } from 'react';

import { TooltipWrapper } from '~/components/TooltipWrapper';

export const getTooltipJsonFormat = () => ({
  tooltip: {
    type: 'custom',
    options: {
      Title(value: unknown) {
        return createElement(TooltipWrapper, { value });
      },
    },
  },
});
