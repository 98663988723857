import { createElement } from 'react';
import { ICellValue } from 'react-ui-kit-exante';

import { EDepositStatuses, TDepositInfo } from '~/api/deposits/deposits.types';
import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { mapIcons } from '~/constants/statusesInfoMap';

import { StyledStatusCell } from './StatusCell.styled';

export const StatusCell = ({ value }: ICellValue<TDepositInfo['status']>) => {
  const status = value?.text as EDepositStatuses;

  const icon = mapIcons.deposit[status]
    ? createElement(mapIcons.deposit[status])
    : '';

  return (
    <StyledStatusCell className="StatusCell" status={status}>
      {icon}
      {value?.text || DEFAULT_EMPTY_PLACEHOLDER}
    </StyledStatusCell>
  );
};
