import { useEffect } from 'react';

export const useSubscribeOnEvent = (
  eventName: string,
  handler: (...args: unknown[]) => unknown,
) => {
  useEffect(() => {
    window.addEventListener(eventName, handler);

    return () => {
      window.removeEventListener(eventName, handler);
    };
  }, [eventName, handler]);

  const unsubscribe = () => {
    window.removeEventListener(eventName, handler);
  };

  return {
    unsubscribe,
  };
};
