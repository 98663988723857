import { IRow, KitTheme } from 'react-ui-kit-exante';

import { TWithdrawalsData } from '~/api/withdrawals/withdrawals.types';
import { colorsMap } from '~/constants/statusesInfoMap';

export function getRowProps(row: IRow<TWithdrawalsData>, theme: KitTheme) {
  const status = row.original.status;
  return {
    style: {
      backgroundColor:
        (status && colorsMap(theme).withdrawalTable[status]) || '',
    },
  };
}
