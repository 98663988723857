import { TGetAuditLogsParams } from '~/api/auditLogs/auditLogs.types';
import { convertDateToISO } from '~/utils/dates/convertDateFieldsToISOFormat';

import { PARAMS_FOR_EQUAL, PARAMS_FOR_REQUEST } from './AuditLogs.constants';
import { TFetchParams } from './AuditLogs.types';

const prepareQuery = (
  id: string,
  accounts: string[],
  filters: Record<string, unknown>,
): string[] => {
  const queryArray = [];

  if (Array.isArray(accounts) && accounts.length) {
    queryArray.push(
      accounts.map((account) => `"account"="${account}"`).join(' or '),
    );
  } else {
    queryArray.push(`"user"="${id}"`);
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in filters) {
    if (!PARAMS_FOR_REQUEST.has(key)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (key === 'time' && Array.isArray(filters.time) && filters.time.length) {
      queryArray.push(`"time">='${convertDateToISO(filters.time[0])}'`);
      queryArray.push(`"time"<='${convertDateToISO(filters.time[1])}'`);
    } else if (PARAMS_FOR_EQUAL.has(key)) {
      queryArray.push(`"${key}"="${filters[key]}"`);
    } else {
      queryArray.push(`"${key}"~"${filters[key]}"`);
    }
  }

  return queryArray;
};

export const prepareAuditLogParams = (
  id: string,
  accountsList: string[],
  params: TFetchParams,
): TGetAuditLogsParams => {
  const { page, limit, sorting, ...rest } = params;

  let order: TGetAuditLogsParams['order'];
  let orderBy: TGetAuditLogsParams['orderBy'];

  if (Array.isArray(sorting) && sorting.length) {
    order = sorting[0].desc ? 'desc' : 'asc';
    orderBy = sorting[0].id;
  }

  const query = prepareQuery(id, accountsList, rest);

  return {
    limit,
    offset: page * limit,
    order,
    orderBy,
    query: query.join(' and '),
  };
};
