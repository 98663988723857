import { createApi } from '@reduxjs/toolkit/query/react';
import { isUndefined, omitBy } from 'lodash';

import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../endpoints';

import { TSymbolsParams, TSymbolsResponse } from './symbols.types';

export const symbolsApi = createApi({
  reducerPath: 'symbolsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [],
  endpoints: (builder) => ({
    searchBySymbols: builder.query<TSymbolsResponse, TSymbolsParams>({
      queryFn: async (
        { skip, search, limit = 100, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        if (skip === null) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
            },
          };
        }

        const response = await fetchWithBaseQuery({
          url: `${NODE_BACK_URL}/api/v2/symbols/list`,
          params: omitBy({ limit, skip, search, ...restParams }, isUndefined),
          requestOptions: {},
        });

        if ('error' in response) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
              canceled: true,
            },
          };
        }

        const { pagination } = response.data;

        return {
          data: {
            total: pagination.total,
            nextSkip: skip + limit < pagination.total ? skip + limit : null,
            options: response.data.data.map(({ id }: { id: string }) => ({
              label: id,
              value: id,
            })),
          },
        };
      },
    }),
  }),
});
export const { useLazySearchBySymbolsQuery } = symbolsApi;
