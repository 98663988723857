import { isEqual } from 'lodash';
import { ISelectOption } from 'react-ui-kit-exante';

const optionKeys: Array<keyof ISelectOption> = ['label', 'value'];

function isOptionValue(item: unknown): item is ISelectOption {
  return item instanceof Object && isEqual(optionKeys, Object.keys(item));
}

// The value can be of any type; it doesn't matter
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueFromOption(item: any) {
  return isOptionValue(item) ? item.value : item;
}
