import { FC, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
  IconButton,
} from 'react-ui-kit-exante';

import {
  useContactsExportCsvMutation,
  useGetContactsTableFiltersQuery,
  useGetPermissionsQuery,
  useLazyGetContactsQuery,
} from '~/api';
import { TContacts } from '~/api/contacts/contacts.types';
import { ActionList } from '~/components/ActionList';
import { RefreshButton } from '~/components/RefreshButton';
import { contextSearchFilter, defaultSortByGlobal } from '~/constants/table';
import { useGetTableFilterOptions } from '~/hooks';
import { WithBookmarks } from '~/modules/bookmarks/components/WithBookmarks';
import { TBookmarkResponseProps } from '~/modules/bookmarks/hooks/useBookmark/types';
import { OLD_CRM_ROOT_PATH, PATHS } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import { prepareSelectedColumns } from '~/utils/prepareSelectedColumns';
import {
  getAdditionalFilters,
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
  getTableColumnsFromLs,
} from '~/utils/table';
import { getSortingParams } from '~/utils/table/sorting';

import {
  CONTACTS_DATE_FIELDS,
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
  PAGE_SIZE,
  PAGE_SIZES,
} from './Contacts.constants';
import { EReportTypes, TContactsProps } from './Contacts.types';
import { AddContact } from './components';

export const Contacts: FC<TContactsProps & TBookmarkResponseProps> = ({
  tableId,
  pageName,
  selectedBookmark,
  handleDeleteBookmark,
  handleSaveAsNewBookmark,
  handleSaveBookmark,
  handleShareBookmark,
}) => {
  const navigate = useNavigate();

  const { data: contactsTableFilters } = useGetContactsTableFiltersQuery();
  const { data: permissions } = useGetPermissionsQuery();

  const [fetchContacts] = useLazyGetContactsQuery();
  const [triggerExportCsv] = useContactsExportCsvMutation();

  const additionalOptions = useGetTableFilterOptions(contactsTableFilters);

  const selectedColumnsRef = useRef(
    prepareSelectedColumns(getTableColumnsFromLs(tableId)),
  );

  const getContacts = useCallback(async (params: IOnFetchArguments) => {
    const paginationParams = getPaginationParams(params);
    const filterParams = prepareFilterParams(
      getFilterParams(params, CONTACTS_DATE_FIELDS),
    );
    const sortingParams = getSortingParams(params);

    const response = await fetchContacts({
      params: {
        ...paginationParams,
        ...filterParams,
        ...sortingParams,
      },
      selectedColumns: selectedColumnsRef.current,
    });

    if ('error' in response) {
      return {
        iTotalDisplayRecords: 0,
        iTotalRecords: 0,
        application_list: [],
      };
    }

    return response?.data;
  }, []);

  const hasPermissionToImportTag = permissions?.tags.tags_can_import_tags;

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getContacts },
      pagination: {
        getDefaultPagination,
      },
      filters: {
        getDefaultFilters: () => {
          return contextSearchFilter;
        },
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getContacts, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
    setSorting,
  } = useTableData(tableDataArgs);

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: contactsTableFilters,
        additionalOptions,
        withContextSearch: true,
      }),
    [
      removeFilter,
      setFilter,
      contactsTableFilters,
      Object.keys(additionalOptions),
    ],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const total = data?.iTotalDisplayRecords || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const columns = getColumns();

  const bookmarkComponent = useMemo(() => {
    if (!selectedBookmark) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark?.columns.length
        ? selectedBookmark?.columns
        : DISPLAYED_COLUMNS_KEYS,
    [selectedBookmark?.columns],
  );

  const handleExportCsv = async (reportType: string) => {
    const selectedColumns = prepareSelectedColumns(
      getTableColumnsFromLs(tableId),
    );
    const preparedFilters = prepareFilterParams(filters);

    await triggerExportCsv({
      preparedFilters,
      selectedColumns,
      reportType,
    });
  };

  const openContactsImport = () => {
    navigate(PATHS.CONTACTS_IMPORT_CONTACT);
  };

  const openImportTag = () => {
    navigate(PATHS.IMPORT_TAG);
  };

  // todo add params as other tables (see Deposits) after change on new entry screen page
  const handleRowClick = ({ id }: TContacts) => {
    const contactUrl = `${OLD_CRM_ROOT_PATH}/contact-${id}`;

    linkAndClick(contactUrl);
  };

  const additionalActions = [
    {
      component: (
        <>
          <RefreshButton
            onRefresh={fetchData}
            disabled={isLoading}
            iconColor="secondary"
            title="Refresh table data"
          />
          <AddContact />
          <IconButton
            title="Import contacts"
            iconName="UploadIcon"
            iconColor="secondary"
            onClick={openContactsImport}
          />
          <ActionList dataTestId={tableId}>
            <IconButton
              label="Export applications to CSV"
              iconName="FileCsvIcon"
              iconSize={16}
              iconColor="secondary"
              onClick={() => handleExportCsv(EReportTypes.applications)}
            />
            <IconButton
              label="Export Compliance and Risk info"
              iconName="RiskIcon"
              iconSize={16}
              iconColor="secondary"
              onClick={() => handleExportCsv(EReportTypes.clientsCompliance)}
            />
            <IconButton
              label="Export comments to CSV"
              iconName="SMSIcon"
              iconSize={16}
              iconColor="secondary"
              onClick={() => handleExportCsv(EReportTypes.commentsReport)}
            />
            {hasPermissionToImportTag && (
              <IconButton
                label="Import tag"
                iconName="TagIcon"
                iconSize={16}
                iconColor="secondary"
                onClick={openImportTag}
              />
            )}
          </ActionList>
        </>
      ),
    },
  ];

  const onVisibleColumnsChange = (keys: string[]) => {
    selectedColumnsRef.current = keys.join(',');
    fetchData();
  };

  return (
    <Table<TContacts>
      className="ContactsTable"
      title={pageName}
      filtersRightPanelComponent={bookmarkComponent}
      columns={columns}
      isLoading={isLoading}
      displayedColumnKeys={displayedColumnKeys}
      filtersExpanded
      isFlexLayout
      defaultSortBy={defaultSortByGlobal}
      onSort={setSorting}
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.application_list || []}
      tableId={tableId}
      hasPagination
      showTableInfo
      serverPaginationProps={serverPaginationProps}
      saveColumnOrder
      hasNegativeFilters
      saveViewParamsAfterLeave
      isNotSaveVisibleColumns={!!selectedBookmark?.id}
      additionalActions={additionalActions}
      handleRowClick={handleRowClick}
      onVisibleColumnsChange={onVisibleColumnsChange}
      pageSizes={PAGE_SIZES}
      pageSize={PAGE_SIZE}
    />
  );
};

export const ContactsContainer = () => {
  return (
    <WithBookmarks
      component={Contacts}
      pageName="Contacts"
      tableId="contacts"
    />
  );
};
