import { find } from 'lodash';

import {
  TBanksToTransfer,
  TWithdrawalsData,
} from '~/api/withdrawals/withdrawals.types';
import { FieldTypes } from '~/constants/common';
import { formatDate } from '~/utils/dates/formatDate';

import {
  COUNTRIES,
  TRANSACTION_FEES_CHOICES,
} from '../WithdrawalForm.constants';

const getOptions = (options: string[]) => {
  return options.map((item) => ({ label: item, value: item }));
};

const BENEFICIARY_TYPE_OPTIONS = [
  {
    value: 'corporate',
    label: 'Corporate',
  },
  {
    value: 'individual',
    label: 'Individual',
  },
];

const getBankToTransferOptions = (
  banksToTransfer: TBanksToTransfer[],
  currency: string,
  applicationType: number,
) => {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const i in banksToTransfer) {
    if (
      banksToTransfer[i].currency === currency &&
      banksToTransfer[i].application_type === applicationType
    ) {
      result.push({
        label: banksToTransfer[i].bank_name,
        value: banksToTransfer[i].bank_name,
      });
    }
  }
  return result;
};

export const getWithdrawalFields = (
  withdrawal: TWithdrawalsData,
  isCanChangeAmount: boolean,
  isCanChange: boolean,
  isDisabledBankToTransfer?: boolean,
) => {
  const {
    accounts,
    opposite_whitelabel: oppositeWhitelabel,
    banks_to_transfer: banksToTransfer,
    currency,
    application,
    hidden_edit_fields: hiddenEditFields,
  } = withdrawal;

  const getBankToTransferDisabledState = () => {
    return Boolean(
      (isCanChangeAmount && !isCanChange) || isDisabledBankToTransfer,
    );
  };

  return [
    {
      name: 'commission_amount',
      title: 'Commission',
      type: FieldTypes.NumberField,
      editable: isCanChangeAmount,
    },
    {
      name: 'commission_currency',
      title: 'Commission currency',
      editable: isCanChangeAmount,
    },
    {
      name: 'transaction_fees',
      title: 'Transaction Fees',
      type: FieldTypes.Select,
      editable: isCanChange,
      optionsConfig: {
        options: TRANSACTION_FEES_CHOICES,
      },
    },
    {
      name: 'all_funds',
      title: 'All funds',
      editable: isCanChange,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'amount',
      title: 'Amount',
      editable: isCanChangeAmount,
      type: FieldTypes.NumberField,
    },
    {
      name: 'currency',
      title: 'Currency',
      editable: isCanChangeAmount,
    },
    {
      name: 'eur_amount_repr',
      title: 'Euro amount',
      editable: false,
      type: FieldTypes.NumberField,
    },
    {
      name: 'sepa_swift',
      title: 'SEPA/SWIFT',
      editable: false,
    },
    {
      name: 'country_of_residence',
      title: 'Country of Residence',
      editable: false,
    },
    {
      name: 'country_of_citizenship',
      title: 'Country of Citizenship',
      editable: false,
    },
    {
      name: 'account',
      title: 'Account from',
      editable: isCanChange,
      type: FieldTypes.Select,
      optionsConfig: {
        options: getOptions(accounts || []),
      },
    },

    {
      name: 'crypto_address',
      title: 'Cryptocurrency address',
      editable: isCanChange,
    },
    {
      name: 'crypto_transaction_id',
      title: 'TransactionID',
      editable: isCanChange,
    },
    {
      name: 'exante_account',
      title: `${oppositeWhitelabel} account to`,
      editable: isCanChange,
    },
    { name: 'name', title: 'Beneficiary Name', editable: isCanChange },
    {
      name: 'address',
      title: 'Beneficiary Address',
      editable: isCanChange,
    },
    {
      name: 'beneficiary_type',
      title: 'Beneficiary Type',
      editable: isCanChange,
      type: FieldTypes.Select,
      optionsConfig: {
        options: BENEFICIARY_TYPE_OPTIONS,
      },
    },
    { name: 'bank_name', title: 'Bank name', editable: isCanChange },
    {
      name: 'bank_address',
      title: 'Bank address',
      editable: isCanChange,
    },
    {
      name: 'bank_country',
      title: 'Bank country',
      editable: isCanChange,
      type: FieldTypes.Select,
      optionsConfig: {
        options: getOptions(COUNTRIES),
      },
    },

    { name: 'swift', title: 'SWIFT', editable: isCanChange },
    { name: 'iban', title: 'IBAN', editable: isCanChange },
    { name: 'account_number', title: 'Account Number', editable: isCanChange },
    {
      name: 'correspondent_swift',
      title: 'Correspondent Bank’s SWIFT',
      editable: isCanChange,
    },
    {
      name: 'beneficiary_country',
      title: 'Beneficiary Country',
      editable: isCanChange,
    },
    { name: 'city', title: 'City', editable: isCanChange },
    { name: 'state', title: 'State', editable: isCanChange },
    { name: 'postal_code', title: 'Postal code', editable: isCanChange },
    { name: 'short_address', title: 'Short address', editable: isCanChange },
    {
      name: 'correspondent_account',
      title: 'Correspondent Bank’s Account No',
      editable: isCanChange,
    },
    { name: 'inn', title: 'INN', editable: isCanChange },
    { name: 'bic', title: 'BIC', editable: isCanChange },
    { name: 'vo_code', title: 'VO Code', editable: isCanChange },
    {
      name: 'value_date',
      title: 'Value Date',
      editable: isCanChange,
      formatter: formatDate,
      type: FieldTypes.DateField,
    },
    {
      name: 'comment',
      title: 'Comment',
      editable: isCanChange,
    },

    {
      name: 'type_of_transaction',
      title: 'Type of transaction',
      editable: isCanChange,
    },
    { name: 'company_name', title: 'Name/Company Name', editable: isCanChange },
    { name: 'fi_name', title: 'Name of the FI', editable: isCanChange },
    {
      name: 'contact_person',
      title: 'Contact person and details',
      editable: isCanChange,
    },
    {
      name: 'fi_account_number',
      title: 'Account number at the FI',
      editable: isCanChange,
    },
    {
      name: 'account_holder_name',
      title: 'Account holder name',
      editable: isCanChange,
    },
    {
      name: 'transfer_reference',
      title: 'Transfer reference',
      editable: isCanChange,
    },
    {
      name: 'ssi',
      title: 'Standard Settlement Instruction',
      editable: isCanChange,
    },
    {
      name: 'transfer_details',
      title: 'Transfer details',
      editable: isCanChange,
    },
    {
      name: 'face_value',
      title: 'Face Value',
      editable: isCanChange,
      type: FieldTypes.NumberField,
    },
    { name: 'internal_account', title: 'Account to', editable: isCanChange },
    {
      name: 'internal_proxy_account1',
      title: 'Withdrawal account from',
      editable: isCanChange,
    },
    {
      name: 'internal_proxy_account2',
      title: 'Withdrawal account to',
      editable: isCanChange,
    },
    {
      name: 'third_party_email',
      title: '3rd party email',
      editable: isCanChange,
    },
    {
      name: 'third_party_phone',
      title: '3rd party phone',
      editable: isCanChange,
    },
    {
      name: 'third_party_name',
      title: '3rd party name',
      editable: isCanChange,
    },
    {
      name: 'transfer_using_personal_iban',
      title: 'Transfer using personal IBAN',
      editable: isCanChange,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'bank_to_transfer',
      title: 'Bank to transfer',
      type: FieldTypes.Select,
      width: '270px',
      optionsConfig: {
        options: getBankToTransferOptions(
          banksToTransfer || [],
          currency,
          application.type,
        ),
      },
      editable: isCanChange || isCanChangeAmount,
      disabled: getBankToTransferDisabledState(),
    },
    {
      name: 'account_to_transfer',
      title: 'Account to transfer',
      editable: isCanChange,
      disabled: true,
    },
    {
      name: 'comply_radar_id',
      title: 'ComplyRadar TransactionID',
      editable: false,
    },
    {
      name: 'comply_radar_customer_id',
      title: 'ComplyRadar CustomerID',
      editable: false,
    },
  ].filter((item) => !hiddenEditFields.includes(item.title));
};

export const getDefaultFormValues = (
  withdrawal: TWithdrawalsData,
  isCanChangeAmount: boolean,
  isCanChange: boolean,
): Record<string, any> => {
  return getWithdrawalFields(withdrawal, isCanChangeAmount, isCanChange).reduce(
    (acc, curr) => {
      const key = curr.name as keyof TWithdrawalsData;
      if (!curr.editable) {
        return { ...acc };
      }

      if (curr.type === FieldTypes.DateField) {
        return {
          ...acc,
          [curr.name]: withdrawal[key]
            ? new Date(String(withdrawal[key]))
            : null,
        };
      }

      const value = withdrawal[key];

      return {
        ...acc,
        [curr.name]: value,
      };
    },
    {},
  );
};

export const prepareWithdrawalFormData = (
  formData: Partial<Record<string, any>>,
) => {
  const valueDate = formData.value_date as Date;

  if (valueDate) {
    return { ...formData, value_date: formatDate(valueDate) };
  }

  return formData;
};

export type TGetAccountToTransferValue = {
  currency: string;
  banksToTransfer: TBanksToTransfer[];
  bankToTransfer: string;
};

export const getAccountToTransferValue = ({
  banksToTransfer,
  bankToTransfer,
  currency,
}: TGetAccountToTransferValue) =>
  find(
    banksToTransfer,
    {
      currency,
      bank_name: bankToTransfer,
    },
    undefined,
  )?.number;
