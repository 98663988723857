import { Input } from 'react-ui-kit-exante';

import { styled } from 'theme';

export const StyledFormInputContainer = styled(Input)`
  .MuiInputBase-multiline {
    textarea {
      padding: 0;
    }
  }
`;
