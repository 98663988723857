import { TReports, TTask } from '~/api/reports/reports.types';

import { EReportStatus } from './Reports.types';

export const makeStatus = (task: TTask, status: string): string => {
  const { result } = task;
  const computedStatus =
    status.slice(0, 1).toUpperCase() + status.slice(1).toLowerCase();

  if (!result || !result.progress) {
    return computedStatus;
  }

  return `${Math.round((result.progress / result.total) * 100)}%`;
};

export const findTasksInProcess = (reportsData: TReports[]): string[] => {
  return reportsData.reduce((acc: string[], row) => {
    const { task, status } = row;

    if (
      status !== EReportStatus.Done &&
      !['FAILURE', 'SUCCESS', 'DONE'].includes(task.state.toUpperCase())
    ) {
      acc.push(task.task_id);
    }

    return acc;
  }, []);
};
