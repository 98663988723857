import { IRow, KitTheme } from 'react-ui-kit-exante';

import { TDepositInfo } from '~/api/deposits/deposits.types';
import { colorsMap } from '~/constants/statusesInfoMap';

export function getRowProps(row: IRow<TDepositInfo>, theme: KitTheme) {
  const status = row.original.status?.text;
  return {
    style: {
      backgroundColor: colorsMap(theme).depositsTable[status] || '',
    },
  };
}
