import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { FC, Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { Button, IconButton } from 'react-ui-kit-exante';

import {
  StyledActionWithConfirmationButtons,
  StyledActionWithConfirmationContainer,
  StyledActionWithConfirmationContent,
  StyledActionWithConfirmationIcon,
  StyledActionWithConfirmationInner,
  StyledActionWithConfirmationTitle,
  StyledActionWithConfirmationTooltip,
} from './ActionWithConfirmation.styled';
import { TActionWithConfirmationProps } from './ActionWithConfirmation.types';

export const ActionWithConfirmation: FC<TActionWithConfirmationProps> = ({
  children,
  onConfirm,
  content,
  contentHtml,
  disabled,
  disabledConfirmBtn,
  icon,
  title,
  placement = 'top',
  size = 'default',
  externalOpened = false,
  openFromExternal = false,
  hideDefaultButtons = false,
  closeHandler,
  confirmButtonNameKey = 'Yes',
  cancelButtonNameKey = 'No',
  withCloseAfterConfirmation,
  isInvalid = false,
  withTopCloseButton = false,
  noDismiss,
  dismissHandler,
  className,
  TooltipContentWrapper,
}) => {
  const [open, setOpen] = useState(externalOpened);

  const handleTooltipClose = (e?: SyntheticEvent | Event) => {
    setOpen(false);
    closeHandler?.();
    e?.stopPropagation();
  };

  const handleTooltipDismiss = () => {
    if (!open) {
      return;
    }

    if (!noDismiss) {
      if (dismissHandler) {
        dismissHandler();
      } else {
        handleTooltipClose();
      }
    }
  };

  const handleTooltipOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    onConfirm?.();
    if (withCloseAfterConfirmation && !isInvalid) {
      handleTooltipClose();
    }
  };

  useEffect(() => {
    setOpen(externalOpened);
  }, [externalOpened]);

  const Wrapper = TooltipContentWrapper || Fragment;

  const tooltipContent = (
    <Wrapper>
      {icon && (
        <StyledActionWithConfirmationIcon className="ActionWithConfirmationIcon">
          {icon}
        </StyledActionWithConfirmationIcon>
      )}
      {title && (
        <StyledActionWithConfirmationTitle className="ActionWithConfirmationTitle">
          {title}
          {withTopCloseButton && (
            <IconButton
              iconName="CloseIcon"
              onClick={handleTooltipClose}
              aria-label="close"
            />
          )}
        </StyledActionWithConfirmationTitle>
      )}
      {contentHtml && (
        <StyledActionWithConfirmationContent
          className="ActionWithConfirmationContent"
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      )}
      {content && (
        <StyledActionWithConfirmationContent className="ActionWithConfirmationContent">
          {content}
        </StyledActionWithConfirmationContent>
      )}
      {!hideDefaultButtons && (
        <StyledActionWithConfirmationButtons className="ActionWithConfirmationButtons">
          {onConfirm ? (
            <>
              <Button
                onClick={handleConfirm}
                textColor="action"
                disabled={disabled || disabledConfirmBtn}
                color="transparent"
                aria-label="confirm"
              >
                {confirmButtonNameKey}
              </Button>
              <Button
                onClick={handleTooltipClose}
                textColor="secondary"
                color="transparent"
                aria-label="close"
              >
                {cancelButtonNameKey}
              </Button>
            </>
          ) : (
            <Button
              onClick={handleTooltipClose}
              textColor="action"
              color="transparent"
              aria-label="close"
            >
              OK
            </Button>
          )}
        </StyledActionWithConfirmationButtons>
      )}
    </Wrapper>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipDismiss}>
      <StyledActionWithConfirmationContainer
        className={cn('ActionWithConfirmationContainer', className)}
      >
        <StyledActionWithConfirmationTooltip
          arrow
          placement={placement}
          title={tooltipContent}
          open={open}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          className="ActionWithConfirmationTooltip"
          size={size}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <StyledActionWithConfirmationInner
            className="ActionWithConfirmationInner"
            onClick={
              !openFromExternal ? handleTooltipOpen : (e) => e.stopPropagation()
            }
          >
            {children}
          </StyledActionWithConfirmationInner>
        </StyledActionWithConfirmationTooltip>
      </StyledActionWithConfirmationContainer>
    </ClickAwayListener>
  );
};
