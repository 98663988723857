import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { colorsMap } from '~/constants/statusesInfoMap';

export const StyledStatusCell = styled('div', {
  shouldForwardProp: blockNonNativeProps('status'),
})<{ status: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ status, theme }) => {
    return status
      ? colorsMap(theme).withdrawal[status]
      : theme?.color?.typo?.primary;
  }};
`;
