import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'react-ui-kit-exante';

import { useGetPermissionsQuery } from '~/api';
import { ROOT_PATH } from '~/router';

import {
  StyledMenuContainer,
  StyledNavigation,
  StyledTitle,
} from './Navigation.styled';

export const Navigation: FC = () => {
  const { data: permissions } = useGetPermissionsQuery();

  const location = useLocation();

  const hiddenPages = () => {
    const pages = [];

    if (!permissions?.record?.view) {
      pages.push('monitoring');
    }

    return pages;
  };

  return (
    <StyledNavigation className="Navigation">
      <StyledMenuContainer className="MenuContainer">
        <Menu
          className="Menu"
          beforeList={<StyledTitle className="Title">CRM 2.0</StyledTitle>}
          items={[
            {
              id: 'data-change-requests',
              linkComponent: Link,
              url: `${ROOT_PATH}/data-change-requests/`,
              title: 'Data Change Requests',
              items: [],
            },
            {
              id: 'deposits-withdrawals',
              linkComponent: Link,
              url: `${ROOT_PATH}/withdrawals/`,
              title: 'Deposits & Withdrawals',
              items: [
                {
                  id: 'deposits',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/deposits/`,
                  title: 'Deposits',
                  items: [],
                },
                {
                  id: 'withdrawals',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/withdrawals/`,
                  title: 'Withdrawals',
                  items: [],
                },
              ],
            },
            {
              id: 'monitoring',
              linkComponent: Link,
              url: `${ROOT_PATH}/monitoring/`,
              title: 'Monitoring Requests',
              items: [],
            },
            {
              id: 'contacts',
              linkComponent: Link,
              url: `${ROOT_PATH}/contacts/`,
              title: 'Contacts',
              items: [],
            },
            {
              id: 'users',
              linkComponent: Link,
              url: `${ROOT_PATH}/users/`,
              title: 'Users',
              items: [],
            },
            {
              id: 'userManagement',
              linkComponent: Link,
              url: `${ROOT_PATH}/`,
              title: 'CRM User Management',
              items: [
                {
                  id: 'admin',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/admin/`,
                  title: 'Admin',
                  items: [],
                },
                {
                  id: 'support',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/support/`,
                  title: 'Support',
                  items: [],
                },
                {
                  id: 'onboarding',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/onboarding/`,
                  title: 'Onboarding',
                  items: [],
                },
                {
                  id: 'sales-qa',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/sales_qa/`,
                  title: 'Sales QA',
                  items: [],
                },
                {
                  id: 'backoffice',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/backoffice/`,
                  title: 'Backoffice',
                  items: [],
                },
                {
                  id: 'marketing',
                  linkComponent: Link,
                  url: `${ROOT_PATH}/marketing/`,
                  title: 'Marketing',
                  items: [],
                },
              ],
            },
            {
              id: 'reports',
              linkComponent: Link,
              url: `${ROOT_PATH}/reports/`,
              title: 'Reports',
              items: [],
            },
            {
              id: 'notifications',
              linkComponent: Link,
              url: `${ROOT_PATH}/notifications/`,
              title: 'Notifications',
              items: [],
            },
          ].filter((item) => !hiddenPages().includes(item?.id))}
          location={location}
        />
      </StyledMenuContainer>
    </StyledNavigation>
  );
};
