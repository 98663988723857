import { createElement } from 'react';

import { TPagination } from '~/api/nodeBackApi/notifications/notifications.types';
import { TooltipWrapper } from '~/components/TooltipWrapper';

import {
  TGetAdditionalFilters,
  TNotificationsColumnsProps,
} from './NotificationsTable.types';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'username',
  'channel',
  'type',
  'name',
  'subject',
  'content',
  'date',
  'dateOpened',
];

export const getColumns = ({
  onRemove,
  onFilter,
  channelOptions,
}: TNotificationsColumnsProps) => {
  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
      disableSortBy: true,
      onRemove,
      onFilter,
    },
    {
      Header: 'Channel',
      accessor: 'channel',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: channelOptions,
      onRemove,
      onFilter,
    },
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Content',
      accessor: 'content',
      disableSortBy: true,
      disableFilters: true,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value, tryToParse: true });
          },
        },
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: true,
      formatting: 'dateTime',
      type: 'dateTimeRange',
      onRemove,
      onFilter,
    },
    {
      Header: 'Date opened',
      accessor: 'dateOpened',
      disableSortBy: true,
      formatting: 'dateTime',
      type: 'dateTimeRange',
      disableFilters: true,
      onRemove,
    },
  ];

  return columns;
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
}: TGetAdditionalFilters) => [
  {
    Header: 'Is opened',
    accessor: 'isOpened',
    type: 'checkbox',
    onFilter,
    onRemove,
  },
  {
    Header: 'Search',
    accessor: 'search',
    onFilter,
    onRemove,
  },
];

export const channelOptions = [
  { value: 'EMAIL', label: 'EMAIL' },
  { value: 'SMS', label: 'SMS' },
  { value: 'PUSH', label: 'PUSH' },
  { value: 'INTERNAL_CA', label: 'INTERNAL_CA' },
];

export const DEFAULT_PAGINATION_RESPONSE: TPagination = { total: 0 };

export const UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE = ['sorting', 'page'];

export const EMPTY_NOTIFICATIONS = {
  data: [],
  pagination: DEFAULT_PAGINATION_RESPONSE,
};
