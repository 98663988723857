export const COLUMNS = [
  {
    accessor: 'name',
    Header: 'name',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'email',
    Header: 'email',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    accessor: 'date_joined',
    Header: 'created',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'dateUTC',
  },
  {
    accessor: 'last_login',
    Header: 'last login',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'dateUTC',
  },
  {
    accessor: 'le_approval_permissions',
    Header: 'le approval permissionsns',
    disableSortBy: true,
    disableFilters: true,
  },
];
