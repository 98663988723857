import { UserManagementRoles } from '../UserManagementPages.constants';
import { UserManagement } from '../components/UserManagement';

export const Backoffice = () => {
  return (
    <UserManagement
      title="Backoffice"
      typeOfRole={UserManagementRoles.Backoffice}
    />
  );
};
