import { useCallback, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { useLazyGetAccountsQuery, useLazyGetCoreUserClientsQuery } from '~/api';

import { DEFAULT_PAGINATION_RESPONSE } from './AuditLogs.constants';

export const defaultAccountState = {
  accounts: [],
  pagination: DEFAULT_PAGINATION_RESPONSE,
};

export const useAccountsByUser = (
  userName: string,
  isWithoutArchived?: boolean,
) => {
  const [fetchAccounts] = useLazyGetAccountsQuery();
  const [getCoreUserClients] = useLazyGetCoreUserClientsQuery();

  const onFetchAccountsList = useCallback(async () => {
    const userClients = await getCoreUserClients({ userName });

    if (!userClients.data || !userClients.data.length) {
      return [];
    }
    const accountsListRequests = userClients.data.map(async ({ clientId }) => {
      const response = await fetchAccounts({
        clientId,
      });

      if ('error' in response || !response.data) {
        return defaultAccountState;
      }

      return response.data;
    });

    const responses = await Promise.all(accountsListRequests);

    const preparedAccounts = responses
      .flatMap(({ accounts }) => accounts)
      .filter(({ archived }) => (isWithoutArchived ? !archived : true))
      .map(({ id }) => id);

    return preparedAccounts;
  }, [fetchAccounts, userName, getCoreUserClients]);

  const {
    data: accountsList,
    fetchData: fetchAccountsList,
    isLoading: accountListLoading,
    error: accountListError,
  } = useData({
    onFetch: onFetchAccountsList,
  });

  useEffect(() => {
    fetchAccountsList();
  }, [fetchAccountsList]);

  return {
    accountsList: accountsList || [],
    accountListLoading,
    accountListError,
  };
};
