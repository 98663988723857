import { UserManagementRoles } from '../UserManagementPages.constants';
import { UserManagement } from '../components/UserManagement';

export const Onboarding = () => {
  return (
    <UserManagement
      title="Onboarding"
      typeOfRole={UserManagementRoles.Onboarding}
    />
  );
};
