import { FC, useLayoutEffect, useState } from 'react';

import { StyledAppVersion } from './AppVersion.styled';

export const AppVersion: FC = () => {
  const [clientVersion, setClientVersion] = useState<string | null>(null);
  useLayoutEffect(() => {
    if (!window.runUIhistoryContainer) {
      setClientVersion(process?.env?.REACT_APP_VERSION || null);
    }
  }, []);
  return clientVersion ? (
    <StyledAppVersion className="AppVersion">
      Version {clientVersion}
    </StyledAppVersion>
  ) : null;
};
