import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TPermissions } from './permissions.types';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Permissions'],
  endpoints: (builder) => ({
    getPermissions: builder.query<TPermissions, void>({
      query: () => ({
        url: '/rest/permissions/',
      }),
      providesTags: ['Permissions'],
    }),
  }),
});

export const { useGetPermissionsQuery } = permissionsApi;
