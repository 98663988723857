import { TPermissions } from '../../../../api/permissions/permissions.types';

import { TConfirmsData } from './Confirms.types';

export const getConfirmationText = (
  type: TConfirmsData['type'],
  key: string,
  title: string,
) => {
  if (type === 'withdrawal') {
    if (key === 'backoffice_confirm') {
      return {
        title: `Are you sure you want to toggle ${title} ?`,
        text: "Please insure that transaction in BO is set: either manually from the backend or with buttons 'Withdraw from account' or 'Transfer funds'",
      };
    }
    return {
      title: '',
      text: `Are you sure you want to toggle ${title} ?`,
    };
  }

  if (type === 'deposit') {
    if (title === 'Booked in bo') {
      return {
        title: 'Are you sure you want to toggle Booked in BO?',
        text: `Please insure that transaction in BO is set: either manually from the backend or with buttons 'Book funds'`,
      };
    }

    return {
      title: '',
      text: `Do you want to complete "${title}" and pass the deposit to the next responsible?`,
    };
  }

  return {
    title: '',
    text: '',
  };
};

export const mapTypeToPermission: Record<string, keyof TPermissions> = {
  deposit: 'deposit_info',
  withdrawal: 'withdrawals',
};
