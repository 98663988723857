import { accountAccessTypes, TAccount } from './accounts.types';

export const DEFAULT_ACCOUNT_ACCESS: (typeof accountAccessTypes)[number] =
  'blocked';

export const expectedAccountFields: (keyof TAccount)[] = [
  'id',
  'accountOwner',
  'created',
  'marginTradingAllowed',
  'internalComment',
  'type',
  'subOwnerDescription',
  'status',
  'ownershipType',
  'iban',
  'comment',
  'clientId',
  'autoLiquidationAllowed',
  'archivedDate',
  'archived',
  'accountPurpose',
  'calcInterestsPositiveFlag',
  'calcInterestsNegativeFlag',
  'calcOvernightsFlag',
  'useFreemoneyPremium',
  'useExcessMarginFee',
  'availableCurrencies',
  'useIntradayMargin',
  'sumOrderMargin',
  'strictOrderMarginPolicy',
  'preorderMarginCheck',
  'preorderCommissionCheck',
  'navViolationCheck',
  'concentrationMargin',
  'useMarginSlippage',
  'marginThresholdOverride',
  'takeMinimumFee',
  'commissionGroupId',
  'calcCommissionRebate',
  'interestsSetId',
  'calcInterestRebate',
  'overnightsSetId',
  'interestUseNegativePnl',
  'interestUsePositivePnl',
  'takeCashConversionCommission',
  'cashConversionLimit',
  'convertCashInto',
  'permissionsSetId',
  'routingTags',
  'mirroringLevel',
  'autoDebit',
  'autoDebitAccountId',
  'excludeFromGlobalMetrics',
  'clientName',
  'clientCode',
  'totalOrderMargin',
  'totalFreeMoney',
  'totalCash',
  'totalBlockedMargin',
  'sharePrice',
  'requiredMargin',
  'netAssetValue',
  'marginUtilization',
  'originatorIds',
  'branding',
  'clientLegalEntity',
  'permissionGroupId',
];
