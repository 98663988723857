import { blockNonNativeProps, css, styled, Tooltip } from 'react-ui-kit-exante';

import { TStyledTooltip } from './ActionWithConfirmation.types';

export const StyledActionWithConfirmationContainer = styled('div')`
  display: flex;
  align-items: center;
  position: relative;

  .MuiTooltip-popper[data-popper-placement*='bottom'] {
    margin: 100px;
  }
`;

export const StyledActionWithConfirmationIcon = styled('div')`
  margin-bottom: 22px;
`;

export const StyledActionWithConfirmationTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;
`;

export const StyledActionWithConfirmationContent = styled('div')`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.typo.primary};

  a {
    color: ${({ theme }) => theme.color.typo.action};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.color.typo.promo};
      text-decoration: underline;
    }
  }
`;

export const StyledActionWithConfirmationButtons = styled('div')`
  margin-top: 16px;
  display: flex;
  gap: 16px;

  button {
    font-size: 15px;
  }
`;

export const StyledActionWithConfirmationTooltip = styled(Tooltip, {
  shouldForwardProp: blockNonNativeProps(['size']),
})<TStyledTooltip>`
  .MuiTooltip-tooltip {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    background-color: ${({ theme }) => theme?.color?.modal.bg};

    ${({ size }) => {
      if (size === 'big') {
        return css`
          max-width: 400px;
        `;
      }
      return '';
    }}
  }
`;

export const StyledActionWithConfirmationInner = styled('span')`
  display: block;
  line-height: 11px;
`;
