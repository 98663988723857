import { DEPOSIT_STATUSES } from '~/api';
import { EDepositStatuses } from '~/api/deposits/deposits.types';

export const POLLING_ENABLE_STATUSES = [
  DEPOSIT_STATUSES.ACTIVE,
  DEPOSIT_STATUSES.NEW,
];

export const DEPOSIT_STATUS_TITLES = {
  '-1': EDepositStatuses.New,
  1: EDepositStatuses.BookedInBO,
  2: EDepositStatuses.Active,
  6: EDepositStatuses.FundsRejected,
  10: EDepositStatuses.OnHold,
};
