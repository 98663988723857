import { convertDateToISO } from '~/utils/dates/convertDateFieldsToISOFormat';

function prepareDateRangeStringForAPI(
  values: [string, string],
): Record<string, string | null> {
  const [startDate, endDate] = values;
  return {
    startDate: convertDateToISO(startDate),
    endDate: convertDateToISO(endDate),
  };
}

export const prepareNotificationsParams = (
  params: Record<string, any>,
): Record<string, any> => {
  const { params: allParams } = params;

  const { date, ...otherParams } = allParams;

  let transformedParams = { ...otherParams };

  if (date) {
    const dateRange = prepareDateRangeStringForAPI(date);
    transformedParams = { ...transformedParams, ...dateRange };
  }

  return transformedParams;
};
