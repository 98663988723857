import { pick } from 'lodash';

import { expectedAccountFields } from './accounts.constants';
import { TAccount } from './accounts.types';

export function accountMapper(account: TAccount): TAccount {
  return pick(account, expectedAccountFields);
}

export function accountsMapper(accounts: TAccount[]): TAccount[] {
  return accounts.map((item) => accountMapper(item));
}
