import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { BASE_PROXY_API, NODE_BACK_URL } from '../endpoints';

import { TInfo, TUser, TUserAuthFlow } from './generalUsers.types';

const userEndpoint = (id: number | undefined) =>
  `${NODE_BACK_URL}${BASE_PROXY_API}auth-db/2.1/auth/user/${id}`;

export const generalUsersApi = createApi({
  reducerPath: 'generalUsersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Users', 'AuthFlow'],
  endpoints: (builder) => ({
    getUser: builder.query<TUser, { userId: number | undefined }>({
      query: ({ userId }) => ({
        url: userEndpoint(userId),
      }),
      providesTags: ['Users'],
    }),

    updateUser: builder.mutation<
      TUser,
      { userId: number | undefined; data: TInfo }
    >({
      query: ({ userId, data }) => ({
        url: userEndpoint(userId),
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Users'],
    }),

    addUserToken: builder.mutation<
      TUser,
      { userId: number | undefined; data: Record<string, string | undefined> }
    >({
      query: ({ userId, data }) => ({
        url: `${userEndpoint(userId)}/tokens`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users'],
    }),

    editUserToken: builder.mutation<
      TUser,
      {
        userId: number | undefined;
        tokenId: number | undefined;
        data: Record<string, string | undefined>;
      }
    >({
      query: ({ userId, tokenId, data }) => ({
        url: `${userEndpoint(userId)}/tokens/${tokenId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Users'],
    }),

    deleteUserToken: builder.mutation<
      TUser,
      { userId: number | undefined; tokenId: number | undefined }
    >({
      query: ({ userId, tokenId }) => ({
        url: `${userEndpoint(userId)}/tokens/${tokenId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

    getAuthFlow: builder.query<TUserAuthFlow, { userId: number | undefined }>({
      query: ({ userId }) => ({
        url: `${userEndpoint(userId)}/authflow`,
      }),
      providesTags: ['AuthFlow'],
    }),

    setAuthFlow: builder.mutation<
      TUser,
      { userId: number | undefined; data: TUserAuthFlow }
    >({
      query: ({ userId, data }) => ({
        url: `${userEndpoint(userId)}/authflow`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AuthFlow'],
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useAddUserTokenMutation,
  useEditUserTokenMutation,
  useDeleteUserTokenMutation,
  useLazyGetAuthFlowQuery,
  useSetAuthFlowMutation,
} = generalUsersApi;
