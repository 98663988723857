import { createElement } from 'react';
import { FilterValue, ICellValue, IColumn } from 'react-ui-kit-exante';

import {
  useGetLegalEntityTypesQuery,
  useGetCategoriesTypesQuery,
  useGetOperationTypesQuery,
  TTransaction,
} from '~/api';
import { TooltipWrapper } from '~/components/TooltipWrapper';
import { createEndDate } from '~/utils/dates/createEndDate';
import { createStartDate } from '~/utils/dates/createStartDate';
import { createStringDateUTCWithoutTimezone } from '~/utils/dates/createStringDateWithoutTimezone';
import { threeDaysAgo, today } from '~/utils/dates/variables';
import { transformVariantsToSelectOptions } from '~/utils/forms/transformVariantsToSelectOptions';

type TGetColumns = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
};

export const getDefaultFilters = () => {
  const start = createStartDate(threeDaysAgo, true);
  const end = createEndDate(today, true);

  const res = {
    fromTo: [
      createStringDateUTCWithoutTimezone(start),
      createStringDateUTCWithoutTimezone(end),
    ],
  };

  return res;
};

export const useColumns = ({
  onFilter,
  onRemove,
}: TGetColumns): IColumn<TTransaction>[] => {
  const { data: legalEntity } = useGetLegalEntityTypesQuery();
  const { data: categoryTypes } = useGetCategoriesTypesQuery();
  const { data: operationTypes } = useGetOperationTypesQuery();

  return [
    { Header: 'Id', accessor: 'id', disableFilters: true },
    {
      Header: 'Account id',
      accessor: 'accountId',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Timestamp',
      accessor: 'timestamp',
      disableFilters: true,
      sortDescFirst: true,
      formatting: 'dateTimeUTC',
    },
    {
      Header: 'Operation type',
      accessor: 'operationType',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(
        operationTypes?.values || [],
      ),
      onFilter,
      onRemove,
    },
    {
      Header: 'Sum',
      accessor: 'sum',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Sum in EUR',
      accessor: 'convertedSum',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Asset',
      accessor: 'asset',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol',
      accessor: 'symbolId',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Symbol type',
      accessor: 'symbolType',
      disableSortBy: true,
      disableFilters: true,
    },
    { Header: 'Who', accessor: 'who', disableSortBy: true, onFilter, onRemove },
    {
      Header: 'Comment',
      accessor: 'comment',
      disableSortBy: true,
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Internal comment',
      accessor: 'internalComment',
      disableSortBy: true,
      disableFilters: true,
      editParams: {
        editable: true,
      },
    },
    {
      Header: 'Client type',
      accessor: 'clientType',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Internal counterparty',
      accessor: 'internalCounterparty',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Value date',
      accessor: 'valueDate',
      type: 'dateRange',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'dateUTC',
    },
    {
      Header: 'Order id',
      accessor: 'orderId',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Order position',
      accessor: 'orderPos',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Exchange commission',
      accessor: 'exchangeCommission',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Category',
      accessor: 'category',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(
        categoryTypes?.values || [],
      ),
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Settlement currency movement',
      accessor: 'settlementCurrencyMovement',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'number',
    },
    {
      Header: 'Settlement counterparty',
      accessor: 'settlementCounterparty',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Client counterparty',
      accessor: 'clientCounterparty',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Base currency',
      accessor: 'baseCurrency',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Counterparty',
      accessor: 'executionCounterparty',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Transfer ID',
      accessor: 'transferId',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Uuid',
      accessor: 'uuid',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Parent uuid',
      accessor: 'parentUuid',
      disableSortBy: true,
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legalEntity',
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(
        legalEntity?.values || [],
      ),
      onFilter,
      onRemove,
    },
    {
      Header: 'Extra Data',
      accessor: 'extraData',
      disableSortBy: true,
      disableFilters: true,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'Locked Quantity',
      accessor: 'blockedQtty',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { values } }: ICellValue<TTransaction>) => {
        const blockedQtty = values?.blockedQtty;
        return blockedQtty ? Number(blockedQtty).toLocaleString() : '–';
      },
    },
    {
      Header: 'Settlement Currency',
      accessor: 'settlementCurrency',
      disableSortBy: true,
      disableFilters: true,
    },
  ];
};
