import { TDefaultPaginationResponse } from '~/types/api';

export type TDataChangeRequests = {
  id: number;
  user: string;
  type: string;
  legal_entity: string;
  ip: string;
  added: string;
  changed_datetime: string;
  changed_by: string;
  status: string;
};

export type TDataChangeRequestsPaginationResponse<T> = Omit<
  TDefaultPaginationResponse<T>,
  'results'
> & {
  data: T[] | null;
};

export type TExportCsvResponse = {
  success: boolean;
  report_id: number;
  message: string;
};

export type TExportCsvParams = Record<string, unknown>;

export type TDataChangeRequest = {
  status: EDataChangeRequestStatuses;
  ip: string;
  created_at: string;
  comment: string;
  data_list: (string | number | null | undefined)[][];
  data: TDataChangeRequestData;
  datetime_change: string;
  display_type: string;
  changed_by_full_name: string;
  changed_by_email: string;
  current_locale: string;
  application_first_name: string;
  application_last_name: string;
  application_full_name: string;
  application_email: string;
  application_link: string;
  ip_country: string;
  type: string;
};

export type TDataChangeRequestData = Record<string, string>;

export enum EDataChangeRequestStatuses {
  New = 'new',
  Rejected = 'rejected',
  Approved = 'approved',
}
