import { FC } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useDeleteRequestRecordMutation } from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { getTableId } from '~/utils/table';

import { REQUESTS_TABLE_NAME } from '../../Requests.constants';

import { TRequestsActionCellProps } from './RequestsActionCell.types';

export const RequestsActionCell: FC<TRequestsActionCellProps> = ({
  recordId,
  fetchData,
}) => {
  const tableId = getTableId(REQUESTS_TABLE_NAME);
  const [deleteRecord, status] = useDeleteRequestRecordMutation();

  const onDeleteRecord = async () => {
    const response = await deleteRecord(recordId);

    if (!('error' in response)) {
      fetchData();
    }
  };

  const buttonsIsDisabled = status.isLoading;

  return (
    <ActionWithConfirmation
      onConfirm={onDeleteRecord}
      title="Confirmation"
      content="Are you sure to delete this record?"
      placement="left"
      disabled={buttonsIsDisabled}
      confirmButtonNameKey="Confirm"
      cancelButtonNameKey="Cancel"
    >
      <IconButton
        iconSize={24}
        iconColor="secondary"
        iconName="DeleteIcon"
        data-test-id={`${tableId}__button--delete-record`}
        title="Delete record"
      />
    </ActionWithConfirmation>
  );
};
