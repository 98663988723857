import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

import { TEntrySectionContent } from '~/components/EntryScreen/EntrySection/EntrySection.types';

export const StyledEntrySectionContainer = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  padding: 24px;
  overflow: hidden;
`;

export const StyledEntrySectionHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px;
`;

export const StyledEntrySectionTitle = styled('h2')`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  width: 100%;
`;

export const StyledEntrySectionContent = styled('div', {
  shouldForwardProp: blockNonNativeProps('gap'),
})<TEntrySectionContent>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;
