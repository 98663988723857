export const COLUMNS = [
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
  {
    Header: 'Days',
    accessor: 'days',
    disableSortBy: true,
  },
];
