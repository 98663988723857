import { convertToFormattedString } from '../../utils/formatters/convertToFormattedString';

import { StyledTooltipWrapper } from './TooltipWrapper.styled';
import { TTooltipWrapperProps } from './TooltipWrapper.types';

export const TooltipWrapper = ({ value, tryToParse }: TTooltipWrapperProps) => {
  const preformattedValue = convertToFormattedString(value, tryToParse);

  return (
    <StyledTooltipWrapper
      className="TooltipWrapper"
      onClick={(e) => e.stopPropagation()}
    >
      {preformattedValue}
    </StyledTooltipWrapper>
  );
};
