import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TContacts } from '~/api/contacts/contacts.types';
import { DEFAULT_CURRENCY } from '~/constants/common';
import { formatCellToCheckbox } from '~/utils/table/cellFormatters';

import { ContactInfoCell } from './components';

export function getColumns(): IColumn<TContacts>[] {
  return [
    {
      id: 'name',
      Header: 'Info',
      Cell: ({ row: { values } }: ICellValue<TContacts>) => {
        const { name, email, phone } = values;
        return <ContactInfoCell name={name} email={email} phone={phone} />;
      },
    },
    {
      id: 'id',
      Header: 'Application ID',
    },
    {
      id: 'date_joined',
      Header: 'Created',
      formatting: 'dateUTC',
    },
    {
      id: 'docs_uploaded',
      Header: 'Docs',
      title: 'Doc Uploaded',
      bSortable: false,
      Cell: formatCellToCheckbox('value'),
    },
    {
      id: 'date_uploaded',
      Header: 'Doc Upload Date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_lead',
      Header: 'Lead',
    },
    {
      id: 'tag_potential aum',
      Header: `Potential AUM, ${DEFAULT_CURRENCY}`,
    },
    {
      id: 'tag_expected closing q',
      Header: 'Expected Close',
    },
    {
      id: 'client_id',
      Header: 'ClientID',
    },
    {
      id: 'demo_client_id',
      Header: 'Demo ClientID',
    },
    {
      id: 'accounts',
      Header: 'Accounts',
      disableSortBy: true,
    },
    {
      id: 'next_communication_date',
      Header: 'Next Contact',
    },
    {
      id: 'last_call',
      Header: 'LC',
      tooltip: 'Last call',
    },
    {
      id: 'last_meeting',
      Header: 'LM',
      tooltip: 'Last meeting',
    },
    {
      id: 'denormalized_total_nav',
      Header: 'Total NAV',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'denormalized_funds',
      Header: 'Total Funds',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'denormalized_wrs',
      Header: 'Total WRs',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'denormalized_deposits',
      Header: 'Total Deposits',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'approved_by',
      Header: 'Approved by',
    },
    {
      id: 'rejected_by',
      Header: 'Rejected by',
    },
    {
      id: 'state',
      Header: 'Onboarding State',
    },
    {
      id: 'send_to_review_date',
      Header: 'Send on Review',
    },
    {
      id: 'current_currency',
      Header: 'Currency',
    },
    {
      id: 'linked_applications',
      Header: 'Linked Applications',
    },
    {
      id: 'type',
      Header: 'Corp/Individual',
    },
    {
      id: 'have_meeting_notes',
      Header: 'Have Meeting Notes',
      disableSortBy: true,
    },
    {
      id: 'liquid_nav',
      Header: 'Liquid NAV',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'non_liquid_nav',
      Header: 'Non Liquid NAV',
      tooltip: DEFAULT_CURRENCY,
    },
    {
      id: 'clients_legal_entity',
      Header: 'Legal Entity',
    },
    {
      id: 'clients_client_type',
      Header: 'Client Type',
    },
    {
      id: 'clients_closed',
      Header: 'Client closed',
    },
    {
      id: 'auth_trader',
      Header: 'Auth Trader',
    },
    {
      id: 'first_name',
      Header: 'First Name',
    },
    {
      id: 'last_name',
      Header: 'Last Name',
    },
    {
      id: 'phone',
      Header: 'Phone',
    },
    {
      id: 'email',
      Header: 'Email',
    },
    {
      id: 'company_name',
      Header: 'Company Name',
    },
    {
      id: 'tag_account_description_report_disabled',
      Header: 'Account description in report disabled',
    },
    {
      id: 'tag_account_description_report_enabled',
      Header: 'Account description in report enabled',
    },
    {
      id: 'tag_accountexecutive',
      Header: 'Account Executive (AE)',
    },
    {
      id: 'tag_account segregation',
      Header: 'Account segregation',
    },
    {
      id: 'tag_accuity last verification date',
      Header: 'Accuity last verification date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_accuity match status',
      Header: 'Accuity match status',
    },
    {
      id: 'tag_accuity result',
      Header: 'Accuity result',
    },
    {
      id: 'tag_accuity unique id',
      Header: 'Accuity unique ID',
    },
    {
      id: 'tag_additional risk factors',
      Header: 'Additional risk factors',
    },
    {
      id: 'tag_address',
      Header: 'Full address',
    },
    {
      id: 'tag_administrator',
      Header: 'Administrator',
    },
    {
      id: 'tag_advert',
      Header: 'Advert',
    },
    {
      id: 'tag_advert comment',
      Header: 'Advert comment',
    },
    {
      id: 'tag_affidavit w-8 form',
      Header: 'Affidavit w-8 form',
    },
    {
      id: 'tag_affidavit w-8 form completed',
      Header: 'Affidavit w-8 form completed',
    },
    {
      id: 'tag_allow mrvp',
      Header: 'Allow MRVP',
    },
    {
      id: 'tag_allow wirecard deposit',
      Header: 'Allow wirecard deposit',
    },
    {
      id: 'tag_aml initiated date',
      Header: 'AML Initiated date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_aml result',
      Header: 'AML Result',
    },
    {
      id: 'tag_aml verification',
      Header: 'AML Verification',
    },
    {
      id: 'tag_aml verification date',
      Header: 'AML Verification date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_approved but not funded details',
      Header: 'Approved but not funded details',
    },
    {
      id: 'tag_approved but not funded reason',
      Header: 'Approved but not funded reason',
    },
    {
      id: 'tag_approved date',
      Header: 'Approved date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_asset_manager',
      Header: 'Asset manager',
    },
    {
      id: 'tag_assigned compliance',
      Header: 'Assigned Compliance',
    },
    {
      id: 'tag_assigned date',
      Header: 'Assignment date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_auditor',
      Header: 'Auditor',
    },
    {
      id: 'tag_authdb user id',
      Header: 'AuthDB user ID',
    },
    {
      id: 'tag_authorised users feature',
      Header: 'Authorised users feature',
    },
    {
      id: 'tag_auto approved',
      Header: 'Auto approved',
    },
    {
      id: 'tag_bad7cae67d5c4829b9bccee8c863b12b',
      Header: '9a30ca5a87cb4d0a9072a8d515303e32',
    },
    {
      id: 'tag_basic info filled',
      Header: 'Basic info filled',
    },
    {
      id: 'tag_bcs_signup',
      Header: 'BCS',
    },
    {
      id: 'tag_bi region',
      Header: 'BI Region',
    },
    {
      id: 'tag_blocked date',
      Header: 'Block date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_board client categorization',
      Header: 'Board Client Categorization',
    },
    {
      id: 'tag_branding alias',
      Header: 'Branding alias',
    },
    {
      id: 'tag_bs rights',
      Header: 'Rights to issue Bearer Shares (BS)',
    },
    {
      id: 'tag_business_address',
      Header: 'Address',
    },
    {
      id: 'tag_business_area',
      Header: 'business_area',
    },
    {
      id: 'tag_business_city',
      Header: 'City',
    },
    {
      id: 'tag_business_country',
      Header: 'Country',
    },
    {
      id: 'tag_business_email',
      Header: 'Business  email',
    },
    {
      id: 'tag_business_phone_number',
      Header: 'Business phone number',
    },
    {
      id: 'tag_business_postal_code',
      Header: 'Postal code',
    },
    {
      id: 'tag_calls',
      Header: 'Calls',
    },
    {
      id: 'tag_cc2_legal_person_categorization',
      Header: 'Legal Person categorization',
    },
    {
      id: 'tag_cc_chief_has_professional_experience',
      Header: 'Company Representative has professional experience',
    },
    {
      id: 'tag_cc_meets_2_criteria',
      Header: 'Elective professional',
    },
    {
      id: 'tag_cc_portfolio_size_exceeds_500k',
      Header: 'Portfolio size exceeds 500K Euro',
    },
    {
      id: 'tag_chance of closing',
      Header: 'Chance of closing %',
    },
    {
      id: 'tag_channel',
      Header: 'Channel',
    },
    {
      id: 'tag_channel risk',
      Header: 'Channel risk',
    },
    {
      id: 'tag_city',
      Header: 'City',
    },
    {
      id: 'tag_city by ip',
      Header: 'City by IP',
    },
    {
      id: 'tag_client type',
      Header: 'Registration client type',
    },
    {
      id: 'tag_closing responsible',
      Header: 'Closing responsible',
    },
    {
      id: 'tag_closure details',
      Header: 'Closure details',
    },
    {
      id: 'tag_closure reason',
      Header: 'Closure reason',
    },
    {
      id: 'tag_cme group trading access',
      Header: 'CME Group Trading access',
    },
    {
      id: 'tag_cme group trading access ids',
      Header: 'CME Group Trading access IDs',
    },
    {
      id: 'tag_co-manager assign date',
      Header: 'Co-manager assign date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_co-manager reassign reason',
      Header: 'Co-manager reassign reason',
    },
    {
      id: 'tag_commission set',
      Header: 'Commission set',
    },
    {
      id: 'tag_company classification',
      Header: 'Company classification',
    },
    {
      id: 'tag_company legal form',
      Header: 'Company legal form',
    },
    {
      id: 'tag_company name local',
      Header: 'Company name local',
    },
    {
      id: 'tag_company_role',
      Header: 'Role',
    },
    {
      id: 'tag_company structure',
      Header: 'Company ownership structure',
    },
    {
      id: 'tag_comply radar customer id',
      Header: 'Comply radar customerID',
    },
    {
      id: 'tag_confirmed sof',
      Header: 'Confirmed SOF',
    },
    {
      id: 'tag_contact type',
      Header: 'Contact type',
    },
    {
      id: 'tag_contest',
      Header: 'Contest',
    },
    {
      id: 'tag_contests',
      Header: 'Contests',
    },
    {
      id: 'tag_corp aml result',
      Header: 'Corp AML Result',
    },
    {
      id: 'tag_corp aml verification',
      Header: 'Corp AML Verification',
    },
    {
      id: 'tag_corp sumsub application id',
      Header: 'Corp SUMSUB Application id',
    },
    {
      id: 'tag_corp sumsub external id',
      Header: 'Corp SUMSUB External id',
    },
    {
      id: 'tag_correspondence_address',
      Header: 'Address',
    },
    {
      id: 'tag_correspondence_city',
      Header: 'City',
    },
    {
      id: 'tag_correspondence_country',
      Header: 'Country',
    },
    {
      id: 'tag_correspondence_email',
      Header: 'Correspondence email',
    },
    {
      id: 'tag_correspondence_phone_number',
      Header: 'Correspondence phone number',
    },
    {
      id: 'tag_correspondence_postal_code',
      Header: 'Postal code',
    },
    {
      id: 'tag_country by ip',
      Header: 'Country by IP',
    },
    {
      id: 'tag_country by ip_iso',
      Header: 'Country by ip iso code',
    },
    {
      id: 'tag_country of citizenship',
      Header: 'Country of citizenship',
    },
    {
      id: 'tag_country of citizenship_iso',
      Header: 'Country of citizenship iso code',
    },
    {
      id: 'tag_country of residence',
      Header: 'Country of residence',
    },
    {
      id: 'tag_country of residence_iso',
      Header: 'Country of residence iso code',
    },
    {
      id: 'tag_current_focus',
      Header: 'Current Focus',
    },
    {
      id: 'tag_customer representative',
      Header: 'Customer representative',
    },
    {
      id: 'tag_customer risk',
      Header: 'Customer risk (EXANTE)',
    },
    {
      id: 'tag_customer_risk_gbxp',
      Header: 'Customer risk (GBXP)',
    },
    {
      id: 'tag_customer\u2019s_business_activity',
      Header: 'Customer\u2019s business activity',
    },
    {
      id: 'tag_customer_segment',
      Header: 'Customer Segment',
    },
    {
      id: 'tag_cvi',
      Header: 'CVI Calculated',
    },
    {
      id: 'tag_cvi assigned',
      Header: 'CVI',
    },
    {
      id: 'tag_cvi type',
      Header: 'CVI type',
    },
    {
      id: 'tag_date assigned cvi changed',
      Header: 'Date assigned CVI changed',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_date of birth',
      Header: 'Date of birth',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_days since the last comment',
      Header: 'Days since the last comment',
    },
    {
      id: 'tag_dcbf81f8cdce4282bf27759a343e5900',
      Header: '77d0a57ae36d4670b81f15a686d43bed',
    },
    {
      id: 'tag_debt cover required',
      Header: 'Debt cover required',
    },
    {
      id: 'tag_deposit group',
      Header: 'Deposit group',
    },
    {
      id: 'tag_deposit swifts',
      Header: 'Deposit SWIFTs',
    },
    {
      id: 'tag_deposit verification',
      Header: 'Verification State',
    },
    {
      id: 'tag_documents pending',
      Header: 'Documents pending',
    },
    {
      id: 'tag_documents translator',
      Header: 'Documents translator',
    },
    {
      id: 'tag_domicile',
      Header: 'Domicile',
    },
    {
      id: 'tag_dont ask docs update',
      Header: "Don't ask docs update",
    },
    {
      id: 'tag_due diligence',
      Header: 'Due diligence',
    },
    {
      id: 'tag_early redemption',
      Header: 'Early redemption (penalties)',
    },
    {
      id: 'tag_email',
      Header: 'User Email',
    },
    {
      id: 'tag_emir report',
      Header: 'EMIR report',
    },
    {
      id: 'tag_end client product',
      Header: 'End client product',
    },
    {
      id: 'tag_event',
      Header: 'Event',
    },
    {
      id: 'tag_event attended',
      Header: 'Event attended',
    },
    {
      id: 'tag_ever had minimum nav date',
      Header: '',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_ever had min nav',
      Header: 'NAV > Minimum deposit',
    },
    {
      id: 'tag_ever had min nav date',
      Header: 'Minimum deposit date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_ever had positive nav',
      Header: 'Ever had NAV > 0',
    },
    {
      id: 'tag_ever have 500+500',
      Header: 'Ever have 500+500',
    },
    {
      id: 'tag_exante client id',
      Header: 'Exante Client ID',
    },
    {
      id: 'tag_exclude from maillists',
      Header: 'Exclude from mailing',
    },
    {
      id: 'tag_executive account',
      Header: 'Executive Account',
    },
    {
      id: 'tag_fatca indicia',
      Header: 'FATCA Indicia',
    },
    {
      id: 'tag_financial statements availability',
      Header: 'Legal entity legal form and financial statements availability',
    },
    {
      id: 'tag_first manager',
      Header: 'First manager',
    },
    {
      id: 'tag_first name local',
      Header: 'First name local',
    },
    {
      id: 'tag_first time trade',
      Header: 'First time trade',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_flower',
      Header: 'Favorite Flower',
    },
    {
      id: 'tag_funding source',
      Header: 'Funding source',
    },
    {
      id: 'tag_fund launch date',
      Header: 'Fund launch date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_funds received',
      Header: 'Funds received',
    },
    {
      id: 'tag_funds received date',
      Header: 'Funds received date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_fund type',
      Header: 'Fund type',
    },
    {
      id: 'tag_gender',
      Header: 'Gender',
    },
    {
      id: 'tag_gozo client id',
      Header: 'Gozo client id',
    },
    {
      id: 'tag_hide margin trading',
      Header: 'Hide margin trading',
    },
    {
      id: 'tag_hide partner landing tab',
      Header: 'Hide Partner Landing Tab',
    },
    {
      id: 'tag_honkong users 2023',
      Header: 'Honkong users 2023',
    },
    {
      id: 'tag_id document type',
      Header: 'ID document type',
    },
    {
      id: 'tag_id expiry date',
      Header: 'ID expiry date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_ifapplicationiswl',
      Header: 'ifApplicationIsWL',
    },
    {
      id: 'tag_individual lnav goals',
      Header: 'Individual LNAV goals',
    },
    {
      id: 'tag_industry risk',
      Header: 'Industry risk',
    },
    {
      id: 'tag_inflow_outflow_flag',
      Header: 'Expected deposits/ withdrawals',
    },
    {
      id: 'tag_instruments',
      Header: 'Instruments',
    },
    {
      id: 'tag_intercash cards',
      Header: 'Payment Card',
    },
    {
      id: 'tag_interests rebate',
      Header: 'Interests rebate',
    },
    {
      id: 'tag_investment manager',
      Header: 'Investment Manager',
    },
    {
      id: 'tag_investment program',
      Header: 'Investment program',
    },
    {
      id: 'tag_investor type',
      Header: 'Investor type',
    },
    {
      id: 'tag_is partner',
      Header: 'Partner',
    },
    {
      id: 'tag_issuing authority',
      Header: 'Issuing authority',
    },
    {
      id: 'tag_jc client id',
      Header: 'Join.Cash client id',
    },
    {
      id: 'tag_joint account',
      Header: 'Joint account',
    },
    {
      id: 'tag_jurisdiction',
      Header: 'Jurisdiction',
    },
    {
      id: 'tag_know-your-customer document status',
      Header: 'Know-Your-Customer document status',
    },
    {
      id: 'tag_sales manago tags',
      Header: 'Sales manago tags',
    },
    {
      id: 'tag_campaign url sources',
      Header: 'Campaign URL sources',
    },
    {
      id: 'tag_kyc update',
      Header: 'KYC update',
    },
    {
      id: 'tag_landing page',
      Header: 'Landing page',
    },
    {
      id: 'tag_landings',
      Header: 'landings',
    },
    {
      id: 'tag_last client review date',
      Header: 'Last client review date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_last comment',
      Header: 'Last comment',
    },
    {
      id: 'tag_last funds received date',
      Header: 'Last funds received date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_last kyc update date',
      Header: 'Last KYC update date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_last name local',
      Header: 'Last name local',
    },
    {
      id: 'tag_last withdrawal amount',
      Header: 'Last withdrawal amount',
    },
    {
      id: 'tag_last withdrawal date',
      Header: 'Last withdrawal date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_lead date',
      Header: 'Lead date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_legal address',
      Header: 'Legal address',
    },
    {
      id: 'tag_legal entity',
      Header: 'Registration legal entity',
    },
    {
      id: 'tag_legal entity identifier',
      Header: 'LEI',
    },
    {
      id: 'tag_legal notes',
      Header: 'Legal notes',
    },
    {
      id: 'tag_lei expiry date',
      Header: 'LEI expiry date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_management fee policy',
      Header: 'Management Fee Policy',
    },
    {
      id: 'tag_manual manager assign',
      Header: 'manual manager assign',
    },
    {
      id: 'tag_marketing_region',
      Header: 'marketing_region',
    },
    {
      id: 'tag_marketing source',
      Header: 'Marketing source',
    },
    {
      id: 'tag_media partner',
      Header: 'Media partner',
    },
    {
      id: 'tag_meeting date',
      Header: 'Meeting date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_meeting place',
      Header: 'Meeting place',
    },
    {
      id: 'tag_meeting type',
      Header: 'Meeting type',
    },
    {
      id: 'tag_metatrader',
      Header: 'MetaTrader',
    },
    {
      id: 'tag_min_deposit_date',
      Header: '',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_minimal investment amount',
      Header: 'Minimal investment amount',
    },
    {
      id: 'tag_minimum activities per week goals',
      Header: 'Minimum activities per week',
    },
    {
      id: 'tag_minimum nav',
      Header: 'Minimum NAV',
    },
    {
      id: 'tag_monitoring / date of opening',
      Header: 'Date of opening',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_monitoring / date of source',
      Header: 'Date of source',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_monitoring / local mlro',
      Header: 'Local MLRO',
    },
    {
      id: 'tag_monitoring / priority',
      Header: 'Priority',
    },
    {
      id: 'tag_monitoring / related case',
      Header: 'Related case',
    },
    {
      id: 'tag_monitoring / source',
      Header: 'Source',
    },
    {
      id: 'tag_monitoring / source other',
      Header: 'Source other',
    },
    {
      id: 'tag_month of birth',
      Header: 'Month of birth',
    },
    {
      id: 'tag_nationality',
      Header: 'Nationality',
    },
    {
      id: 'tag_nav positive date',
      Header: 'First Time Deposit',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_nav split',
      Header: 'NAV split',
    },
    {
      id: 'tag_nav split value',
      Header: 'NAV split value',
    },
    {
      id: 'tag_newtag',
      Header: 'SomeNewTag',
    },
    {
      id: 'tag_non standart setup',
      Header: 'Non standard setup',
    },
    {
      id: 'tag_notifications',
      Header: 'Notifications',
    },
    {
      id: 'tag_number of clients referred',
      Header: 'Number of Clients referred',
    },
    {
      id: 'tag_onemore',
      Header: 'OneMoreTag',
    },
    {
      id: 'tag_other brokers',
      Header: 'Other brokers',
    },
    {
      id: 'tag_other tax residence',
      Header: 'Other tax residence',
    },
    {
      id: 'tag_partner classification',
      Header: 'Partner Classification',
    },
    {
      id: 'tag_partner comment',
      Header: 'Partner comment',
    },
    {
      id: 'tag_partner confirmed',
      Header: 'Partner confirmed',
    },
    {
      id: 'tag_partner cvi',
      Header: 'Partner CVI',
    },
    {
      id: 'tag_partner id',
      Header: 'Partner ID',
    },
    {
      id: 'tag_partner_kyc_is_open',
      Header: 'Partner kyc is open',
    },
    {
      id: 'tag_partner_kyc_is_opened',
      Header: 'Partner kyc is opened',
    },
    {
      id: 'tag_partner type',
      Header: 'Partner type',
    },
    {
      id: 'tag_passport number',
      Header: 'Passport / ID',
    },
    {
      id: 'tag_payment entity',
      Header: 'Payment entity',
    },
    {
      id: 'tag_pc_pd_account_number',
      Header: 'Account number',
    },
    {
      id: 'tag_pending date',
      Header: 'Pending date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_pending reason',
      Header: 'Pending reason',
    },
    {
      id: 'tag_pep risk',
      Header: 'PEP risk',
    },
    {
      id: 'tag_pep risk description',
      Header: 'PEP risk description',
    },
    {
      id: 'tag_pep risk gbxp',
      Header: 'PEP risk GBXP',
    },
    {
      id: 'tag_percentage_holding',
      Header: 'Percentage holding',
    },
    {
      id: 'tag_percent ownership',
      Header: '% ownership',
    },
    {
      id: 'tag_performance fee policy',
      Header: 'Performance Fee Policy',
    },
    {
      id: 'tag_permission set',
      Header: 'Permission set',
    },
    {
      id: 'tag_persistent api token',
      Header: 'Persistent API token',
    },
    {
      id: 'tag_person id',
      Header: 'Person ID',
    },
    {
      id: 'tag_person inactive',
      Header: 'Inactive',
    },
    {
      id: 'tag_person_type',
      Header: 'Person type',
    },
    {
      id: 'tag_phone does not exist',
      Header: "Phone doesn't exist",
    },
    {
      id: 'tag_place of birth',
      Header: 'Place of birth',
    },
    {
      id: 'tag_platform fee policy',
      Header: 'Platform Fee Policy',
    },
    {
      id: 'tag_politically exposed person',
      Header: 'Politically exposed person',
    },
    {
      id: 'tag_pol xtrader report requested',
      Header: 'pol xtrader report requested',
    },
    {
      id: 'tag_pol xtrader report requested years',
      Header: 'pol xtrader report requested years',
    },
    {
      id: 'tag_pol xtrader terms accepted',
      Header: 'pol xtrader terms accepted',
    },
    {
      id: 'tag_pol xtrader wait accepted',
      Header: 'pol xtrader wait accepted',
    },
    {
      id: 'tag_position of contact person',
      Header: 'Position of contact person',
    },
    {
      id: 'tag_position_within_company',
      Header: 'Position within company',
    },
    {
      id: 'tag_postal code',
      Header: 'Postal code',
    },
    {
      id: 'tag_preapproved by',
      Header: 'Preapproved by',
    },
    {
      id: 'tag_product onboarding / clientsarea',
      Header: 'Clientsarea',
    },
    {
      id: 'tag_product onboarding / funds listing',
      Header: 'Funds listing',
    },
    {
      id: 'tag_product onboarding / partnership',
      Header: 'Partnership',
    },
    {
      id: 'tag_product onboarding / payment system',
      Header: 'Payment system',
    },
    {
      id: 'tag_product onboarding / residential program',
      Header: 'Residential program',
    },
    {
      id: 'tag_product onboarding / trading',
      Header: 'Trading',
    },
    {
      id: 'tag_products',
      Header: 'Products',
    },
    {
      id: 'tag_professional experience',
      Header: 'Professional Experience',
    },
    {
      id: 'tag_promo 500',
      Header: '500+500 bonus',
    },
    {
      id: 'tag_promo bonus received',
      Header: 'Promo bonus received',
    },
    {
      id: 'tag_proposal to hold down a client',
      Header: 'Proposal to hold down a client',
    },
    {
      id: 'tag_qualified lead',
      Header: 'Qualified lead',
    },
    {
      id: 'tag_readonly legal entity',
      Header: 'Forced LE',
    },
    {
      id: 'tag_reason assigned cvi changed',
      Header: 'Reason assigned CVI changed',
    },
    {
      id: 'tag_reasons for leaving',
      Header: 'Reasons for leaving',
    },
    {
      id: 'tag_reasons for leaving comment',
      Header: 'Reasons for leaving comment',
    },
    {
      id: 'tag_reassignment reason',
      Header: 'Reassignment reason',
    },
    {
      id: 'tag_rebate',
      Header: 'Rebate',
    },
    {
      id: 'tag_rebate account',
      Header: 'Rebate account',
    },
    {
      id: 'tag_rebate percent',
      Header: 'Rebate percent',
    },
    {
      id: 'tag_rebates only',
      Header: 'Rebates only',
    },
    {
      id: 'tag_receive monthly payments',
      Header: 'Receive monthly payments',
    },
    {
      id: 'tag_redemption conditions',
      Header: 'Redemption conditions',
    },
    {
      id: 'tag_redemption day',
      Header: 'Redemption day',
    },
    {
      id: 'tag_referral commission set',
      Header: 'Referral commission set',
    },
    {
      id: 'tag_region',
      Header: 'Region',
    },
    {
      id: 'tag_region lnav goals',
      Header: "Region's LNAV goals",
    },
    {
      id: 'tag_registered_address',
      Header: 'Address',
    },
    {
      id: 'tag_registered_city',
      Header: 'City',
    },
    {
      id: 'tag_registered_country',
      Header: 'Country',
    },
    {
      id: 'tag_registered_email',
      Header: 'Registered email',
    },
    {
      id: 'tag_registered_phone_number',
      Header: 'Registered phone number',
    },
    {
      id: 'tag_registered_postal_code',
      Header: 'Postal code',
    },
    {
      id: 'tag_registration number',
      Header: 'Registration number',
    },
    {
      id: 'tag_regulation',
      Header: 'Regulation',
    },
    {
      id: 'tag_reject date',
      Header: 'Reject date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_report correction',
      Header: 'Report correction',
    },
    {
      id: 'tag_reputation',
      Header: 'Reputation',
    },
    {
      id: 'tag_restriction reason',
      Header: 'Restriction reason',
    },
    {
      id: 'tag_revenue share manager',
      Header: 'KPI share manager',
    },
    {
      id: 'tag_risk associated to method or verification of ubo',
      Header: 'Risk associated to method or verification of UBO',
    },
    {
      id: 'tag_risk level',
      Header: 'Risk level',
    },
    {
      id: 'tag_risk level value',
      Header: 'Risk level value',
    },
    {
      id: 'tag_risk scoring / initial risk',
      Header: 'Risk scoring / initial risk',
    },
    {
      id: 'tag_risk scoring / ongoing risk',
      Header: 'Risk scoring / ongoing risk',
    },
    {
      id: 'tag_rus nguru price accepted',
      Header: 'rus nguru price accepted',
    },
    {
      id: 'tag_rus nguru report requested',
      Header: 'rus nguru report requested',
    },
    {
      id: 'tag_rus nguru report requested years',
      Header: 'rus nguru report requested years',
    },
    {
      id: 'tag_rus nguru terms accepted',
      Header: 'rus nguru terms accepted',
    },
    {
      id: 'tag_sales manago contact id',
      Header: 'Contact ID',
    },
    {
      id: 'tag_sanctioned activity',
      Header: 'Sanctioned activity',
    },
    {
      id: 'tag_sanction risk',
      Header: 'Sanction risk',
    },
    {
      id: 'tag_sanction risk description',
      Header: 'Sanction risk description',
    },
    {
      id: 'tag_second address',
      Header: 'Second address',
    },
    {
      id: 'tag_second tax residence',
      Header: 'Second tax residence',
    },
    {
      id: 'tag_second tin',
      Header: 'Second TIN',
    },
    {
      id: 'tag_segment',
      Header: 'Segment',
    },
    {
      id: 'tag_short address',
      Header: 'Short address',
    },
    {
      id: 'tag_signed in ip',
      Header: 'Login IP',
    },
    {
      id: 'tag_significant portfolio',
      Header: 'Significant Portfolio',
    },
    {
      id: 'tag_significant trades',
      Header: 'Significant Trades',
    },
    {
      id: 'tag_signin country by ip',
      Header: 'Login country by IP',
    },
    {
      id: 'tag_signin country by ip_iso',
      Header: 'Signin country by ip iso code',
    },
    {
      id: 'tag_signup comment tag',
      Header: 'Signup comment',
    },
    {
      id: 'tag_signup ip',
      Header: 'Signup ip',
    },
    {
      id: 'tag_signup purpose',
      Header: 'Purpose of registration',
    },
    {
      id: 'tag_sof description',
      Header: 'SOF description',
    },
    {
      id: 'tag_sof limit exceed',
      Header: 'Fundings limit exceeded',
    },
    {
      id: 'tag_sof verification',
      Header: 'SOF verification',
    },
    {
      id: 'tag_somenewtag',
      Header: '1',
    },
    {
      id: 'tag_sometag',
      Header: 'SomeTag',
    },
    {
      id: 'tag_source of wealth',
      Header: 'Source of wealth / funds',
    },
    {
      id: 'tag_standing authority 2023 signed',
      Header: 'Standing authority 2023 signed',
    },
    {
      id: 'tag_standing authority expiration date',
      Header: 'Standing Authority expiration date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_state',
      Header: 'State',
    },
    {
      id: 'tag_status',
      Header: 'Status',
    },
    {
      id: 'tag_status change date',
      Header: 'Status change date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_street',
      Header: 'Street',
    },
    {
      id: 'tag_sub partner',
      Header: 'Sub partner',
    },
    {
      id: 'tag_sub partner percent',
      Header: 'Sub partner %',
    },
    {
      id: 'tag_subscription day',
      Header: 'Subscription day',
    },
    {
      id: 'tag_subuser branding',
      Header: 'Subuser branding',
    },
    {
      id: 'tag_subuser withdrawals report',
      Header: 'Subuser withdrawals report',
    },
    {
      id: 'tag_sumsub applicant',
      Header: 'SumSub applicant',
    },
    {
      id: 'tag_sumsub application id',
      Header: 'SUMSUB Application id',
    },
    {
      id: 'tag_sumsub external id',
      Header: 'SUMSUB External id',
    },
    {
      id: 'tag_sumsub inspection id',
      Header: 'SUMSUB Inspection id',
    },
    {
      id: 'tag_sumsub liveness check date',
      Header: 'Check date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_sumsub liveness check result',
      Header: 'Match result',
    },
    {
      id: 'tag_sumsub liveness check score',
      Header: 'Match score',
    },
    {
      id: 'tag_sumsub success imported',
      Header: 'SUMSUB success imported',
    },
    {
      id: 'tag_sumsub user flow',
      Header: 'SumSub user flow',
    },
    {
      id: 'tag_supported by date',
      Header: 'Supported by date',
      formatting: 'dateUTC',
    },
    {
      id: 'tag_supported by reason',
      Header: 'Supported by reason',
    },
    {
      id: 'tag_tax identification number',
      Header: 'Tax Identification Number',
    },
    {
      id: 'tag_tax identification number missing',
      Header: 'No tax identification number',
    },
    {
      id: 'tag_tax missing tin reason',
      Header: 'Reason for missing the tax identification number',
    },
    {
      id: 'tag_tax missing tin reason other',
      Header: 'Reason for missing the tax identification number (other)',
    },
    {
      id: 'tag_tax residence',
      Header: 'Tax residence',
    },
    {
      id: 'tag_tax residence_iso',
      Header: 'Tax residence iso code',
    },
    {
      id: 'tag_terms_of_use_of _automated_trading_signed',
      Header: 'Terms of Use of Automated Trading signed',
    },
    {
      id: 'tag_terms of use of automated trading signed1',
      Header: 'Terms of Use of Automated_trading_signed1',
    },
    {
      id: 'tag_third party transfers allowed',
      Header: 'Third party transfers allowed',
    },
    {
      id: 'tag_tier',
      Header: 'Payment Tier',
    },
    {
      id: 'tag_timezone by ip',
      Header: 'Time zone by IP',
    },
    {
      id: 'tag_total amount of investors',
      Header: 'Total amount of investors',
    },
    {
      id: 'tag_trade commission set',
      Header: 'Trade commission set',
    },
    {
      id: 'tag_transfer auto approve',
      Header: 'Transfer auto approve',
    },
    {
      id: 'tag_transfer source',
      Header: 'Transfer source',
    },
    {
      id: 'tag_user from guest account',
      Header: 'User from guest account',
    },
    {
      id: 'tag_user management available',
      Header: 'User management available',
    },
    {
      id: 'tag_user transfer / gozo.pro',
      Header: 'Have Gozo account',
    },
    {
      id: 'tag_user transfer / stage.gozo.pro',
      Header: 'Have Gozo Account (stage) account',
    },
    {
      id: 'tag_valuation day',
      Header: 'Valuation day',
    },
    {
      id: 'tag_verification method',
      Header: 'Verification method',
    },
    {
      id: 'tag_verification method of authorized representative',
      Header: 'Verification method of authorized representative',
    },
    {
      id: 'tag_verification_process',
      Header: 'Verification process',
    },
    {
      id: 'tag_verification_process_gbxp',
      Header: 'Verification process (GBXP)',
    },
    {
      id: 'tag_verification_process_gbxp_confirmed',
      Header: 'Verification process (GBXP) / confirmed',
    },
    {
      id: 'tag_verification_process_lcg',
      Header: 'Verification process (LCG)',
    },
    {
      id: 'tag_verification_process_lcg_confirmed',
      Header: 'Verification process (LCG) / confirmed',
    },
    {
      id: 'tag_verification_process_online_doc',
      Header: 'Verification process / online doc read',
    },
    {
      id: 'tag_video call',
      Header: 'Video link',
    },
    {
      id: 'tag_w8ben',
      Header: 'W-8 BEN form',
    },
    {
      id: 'tag_w-8 ben form status',
      Header: 'W-8 BEN Form Status',
    },
    {
      id: 'tag_w8/w9 form',
      Header: 'W8/W9 Form',
    },
    {
      id: 'tag_withdrawal no 2fa',
      Header: 'Withdrawal no 2FA',
    },
    {
      id: 'tag_withdrawal restriction',
      Header: 'Withdrawal restriction',
    },
    {
      id: 'tag_wizard / ignore shown',
      Header: 'Ignore shown',
    },
    {
      id: 'tag_wl partner',
      Header: 'White Label end-clients',
    },
    {
      id: 'tag_wl product',
      Header: 'White Label Product',
    },
    {
      id: 'tag_wordcheck case id',
      Header: 'Wordcheck case ID',
    },
    {
      id: 'assigned_to',
      Header: 'Assigned to',
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
}

export const DISPLAYED_COLUMNS_KEYS = [
  'name',
  'date_joined',
  'next_communication_date',
  'last_call',
  'last_meeting',
  'assigned_to',
];

export const CONTACTS_DATE_FIELDS = ['field_date_joined'];

export const PAGE_SIZES = [10, 20, 50];
export const PAGE_SIZE = 20;
