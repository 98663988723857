import { findCreatedElements } from './findCreatedElements';
import { findDeletedElements } from './findDeletedElements';
import { findEditedElements } from './findEditedElements';

export function getArrayDifference<
  Data extends { [key in keyof Data]: unknown },
>(previous: Data[], next: Data[], comparedField: keyof Data) {
  const createdElements = findCreatedElements<Data>(
    previous,
    next,
    comparedField,
  );
  const deletedElements = findDeletedElements<Data>(
    previous,
    next,
    comparedField,
  );
  const editedElements = findEditedElements<Data>(
    previous,
    next,
    comparedField,
  );

  return { createdElements, deletedElements, editedElements };
}
