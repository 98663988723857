import { styled } from 'react-ui-kit-exante';

export const StyledNavigation = styled('div')`
  flex: 0 0 232px;
  width: 232px;
  transition: flex-basis 0.4s ease-out 0s, width 0.4s ease-out 0s;
  user-select: none;
  background-color: ${({ theme }) => theme?.color?.bg?.primary};
  position: relative;
`;

export const StyledTitle = styled('header')`
  padding: 8px 8px 8px 16px;
  font-size: 22px;
`;

export const StyledMenuContainer = styled('div')`
  position: fixed;
  width: 232px;
`;
