import { styled } from 'react-ui-kit-exante';

export const StyledEntryColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;
