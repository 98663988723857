import { createApi } from '@reduxjs/toolkit/query/react';

import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

import { TNotifications } from './notifications.types';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query<
      TDefaultPaginationResponse<TNotifications>,
      TDefaultParams
    >({
      query: (params) => ({
        url: '/rest/notifications/',
        params,
      }),
      providesTags: ['Notifications'],
    }),
  }),
});
export const { useLazyGetNotificationsQuery } = notificationsApi;
