import { styled } from 'react-ui-kit-exante';

export const StyledContactInfoCell = styled('div')`
  p {
    margin: 0 0 2px;
  }
`;

export const StyledContactInfoCellName = styled('p')`
  font-weight: 500;
`;

export const StyledContactInfoCellPhone = styled('p')`
  font-weight: 500;
`;
