export const DISPLAYED_COLUMN_KEYS = [
  'userId',
  'tradeTime',
  'symbolId',
  'symbolType',
  'accountId',
  'side',
  'price',
  'quantity',
  'commission',
  'currency',
  'executionCounterparty',
  'valueDate',
  'clientComment',
  'clientType',
  'tradeType',
  'isManual',
  'actions',
];

export const DEFAULT_SORTING_TS = [{ id: 'tradeTime', desc: true }];
