import { sortBy } from 'lodash';

import { EMPTY_ARRAY } from '~/constants/common';
import { capitalize } from '~/utils/capitalize';
import { sortAlphabetically } from '~/utils/sortAlphabetically';

type IOptions = {
  capitalized: boolean;
  shouldSortAlphabetically: boolean;
};

type TTransformDataToSelectOptions<T> = {
  valueKey: keyof T;
  labelKey: keyof T;
  sort: boolean;
};

export function transformVariantsToSelectOptions<T>(
  variants: T[] = [],
  options: Partial<IOptions> = {},
) {
  const { capitalized = false, shouldSortAlphabetically = false } = options;

  const items: string[] = shouldSortAlphabetically
    ? sortAlphabetically(variants.map((variant) => String(variant)))
    : variants.map((t) => String(t));

  return items.map((variant) => {
    return {
      label: capitalized ? capitalize(variant) : variant,
      value: variant,
    };
  });
}

export function transformDataToSelectOptions<T>(
  data: T[],
  { valueKey, labelKey, sort = false }: TTransformDataToSelectOptions<T>,
) {
  if (!data) {
    return EMPTY_ARRAY;
  }

  let result = data.map((item) => ({
    label: String(item[labelKey]),
    value: item[valueKey],
  }));

  if (sort) {
    result = sortBy(result, ['label']);
  }

  return result;
}
